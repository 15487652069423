import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { routeName } from "./routeName";
import { useNavigate } from "react-router-dom";
import { db, auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { addFirebaseUid } from "../Redux/Services/OtherServices";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { FIREBASE_KEY } from ".";
import { getData, storageKey } from "./Storage";

export const doubleEncodedData = (body) => {
  let data = JSON.stringify(body);
  let encodeData = btoa(data);
  let encoded = btoa(`sculp_${encodeData}`);
  return encoded;
};

// Custom hook to handle the message logic
export const useHandleMessage = () => {
  const { currentUser } = useContext(AuthContext);

  // console.log("currentUsercurrentUser-----", currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateUserStatus = async (userId, status) => {
    if (userId) {
      try {
        await setDoc(
          doc(db, "userStatus", userId),
          { status },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating status:", error.message);
      }
    }
  };

  const handleFirebaseLogin = async (
    password,
    email,
    uid,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, currentUser?.email, password);
      await createChatCollection(
        email,
        uid,
        displayName,
        setLoading,
        photoURL,
        id
      );
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFirebaseRegister = async (
    password,
    email,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    setLoading(true);
    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      await updateProfile(res.user, {
        displayName,
        photoURL: photoURL,
        user_id: id,
      });

      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: displayName,
        email,
        photoURL: photoURL,
        user_id: id,
      });

      //create empty user chats on firestore
      await setDoc(doc(db, "userChats", res.user.uid), {});

      let body = {
        user_id: id,
        chat_udi: res.user.uid,
      };

      let response = await dispatch(addFirebaseUid(body));

      if (response?.status === 200) {
        await updateUserStatus(res.user.uid, "offline");
        await signOut(auth);
        // await createChatCollection(email, res.user.uid, displayName, setLoading, photoURL);
        await handleFirebaseLogin(
          FIREBASE_KEY,
          email,
          res.user.uid,
          displayName,
          setLoading,
          photoURL,
          id
        );
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const createChatCollection = async (
    email,
    uid,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    const combinedId =
      currentUser.uid > uid ? currentUser.uid + uid : uid + currentUser.uid;
    console.log("combinedIdcombinedId---", combinedId);
    try {
      setLoading(true);
      const res = await getDoc(doc(db, "chats", combinedId));
      console.log("res------", res);
      const user_id = await getData(storageKey?.USER_ID);
      if (!res.exists()) {
        // Create a chat in the chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
        // Create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: uid,
            displayName: displayName,
            email: email,
            photoURL: photoURL,
            user_id: id,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            photoURL: currentUser.photoURL,
            user_id: user_id,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        setLoading(false);
        const queryParams = new URLSearchParams({
          displayName: displayName || "",
          uid: uid || "",
          email: email || "",
          photoURL: photoURL || "",
        });
        navigate(`${routeName.CHAT}?${queryParams.toString()}`);
      } else {
        setLoading(false);
        await updateDoc(doc(db, "userChats", currentUser?.uid), {
          [combinedId + ".userInfo"]: {
            uid: uid,
            displayName: displayName,
            email: email,
            photoURL: photoURL,
            user_id: id,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            photoURL: currentUser.photoURL,
            user_id: user_id,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        const queryParams = new URLSearchParams({
          displayName: displayName || "",
          uid: uid || "",
          email: email || "",
          photoURL: photoURL || "",
          user_id: id || "",
        });
        navigate(`${routeName.CHAT}?${queryParams.toString()}`);
      }
    } catch (err) {
      setLoading(false);
      console.error("Error handling message:", err);
    }
  };

  const handleMessage = async (
    email,
    uid,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    if (email) {
      console.log("cirrreerre-----", currentUser);

      if (!uid) {
        console.log("uid else----", uid);
        await handleFirebaseRegister(
          FIREBASE_KEY,
          email,
          displayName,
          setLoading,
          photoURL,
          id
        );
      } else if (uid) {
        await createChatCollection(
          email,
          uid,
          displayName,
          setLoading,
          photoURL,
          id
        );
        // await signOut(auth);
      }
    }
  };
  return handleMessage;
};
