import React, { useEffect, useState } from "react";
import { Colors } from "../../Constants";

import { useDispatch } from "react-redux";
import { productCheckout } from "../../Redux/Services/OtherServices";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";

export const PaymentStatus = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const successUrl = "success=true";
  const cancelUrl = "canceled=true";
  const params = new URLSearchParams(window.location.search);
  const session_id = params.get("session_id");
  const userId = params.get("userID");
  const mode = params.get("mode");
  const success = params.get("success");
  const cancel = params.get("canceled");
  const base64Data = params.get("data");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (window.location.href.includes(successUrl)) {
      setLoading(true);
      handleCheckout();
      // setTimeout(() => {
      //   if (mode === "extra_payment" && base64Data) {
      //     let decode = atob(base64Data);
      //     // console.log(JSON.parse(decode), decode?.proposal_id,"fkghjkdfhgkjfdhgkjfhd");
      //     navigation(
      //       `${
      //         routeName.EXTRA_PAY_LIST
      //       }?${new URLSearchParams({
      //         proposalId: JSON.parse(decode)?.proposal_id,
      //         id: JSON.parse(decode)?.post_id,
      //       }).toString()}`
      //     );
      //     // storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
      //     // navigation(routeName?.CLIENT_PROJECTS);
      //   } else if (mode == "subscription") {
      //     navigation(routeName?.HOME);
      //   } else if (mode == "payment") {
      //     storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
      //     navigation(routeName?.CLIENT_PROJECTS);
      //   }else{
      //     navigation(routeName?.HOME, {replace: true})
      //   }
      // }, 5000);
      // handleCheckout();
    } else if (window.location.href.includes(cancelUrl)) {
      setLoading(true);
      setTimeout(() => {
        if (mode == "subscription") {
          navigation(routeName?.PACKAGES);
        } else if (mode == "payment") {
          navigation(routeName?.CHECKOUT);
        } else if (mode === "extra_payment") {
          storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
          navigation(routeName?.CLIENT_PROJECTS);
        }
      }, 5000);
      setLoading(false);
    }
  }, [location]);

  const handleCheckout = async () => {
    setLoading(true);
    // let userId = await getData(storageKey?.USER_ID);
    var body = {
      //   action: "session",
      action: "session_react",
      sessions_id: session_id,
      user_id: userId,
    };
    // console.log("sessionId body-----", body);
    let res = await dispatch(productCheckout(body));
    console.log("productCheckout.productCheckout.state-------", res);
    if (res?.status == 200) {
      setTimeout(() => {
        if (mode == "extra_payment" && base64Data) {
          let decode = atob(base64Data);
          // console.log(JSON.parse(decode), decode?.proposal_id,"fkghjkdfhgkjfdhgkjfhd");
          navigation(
            `${routeName.EXTRA_PAY_LIST}?${new URLSearchParams({
              proposalId: JSON.parse(decode)?.proposal_id,
              id: JSON.parse(decode)?.post_id,
            }).toString()}`
          );
          // storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
          // navigation(routeName?.CLIENT_PROJECTS);
        } else if (mode == "subscription") {
          navigation(routeName?.PACKAGES);
        } else if (mode == "payment") {
          storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
          navigation(routeName?.CLIENT_PROJECTS);
        } else {
          navigation(routeName?.HOME, { replace: true });
        }
        setLoading(false);
      }, 5000);
    }
    // setLoading(false);
  };

  return (
    <>
      {/* <SidebarLayout> */}
      <Loader loading={loading} />
      <div class="searchtalentList dashboard_main">
        <div className="car-page">
          <div className="col-md-12">
            <div
              style={{
                textAlign: "center",
              }}
            >
              {
                // loading ? (
                //   <Loader loading={loading} />
                // ) :
                cancel == "true" ? (
                  <>
                    <i
                      class="fa fa-times-circle"
                      aria-hidden="true"
                      style={{
                        fontSize: 80,
                        textAlign: "center",
                        color: Colors?.red,
                      }}
                    ></i>
                    <h1>Payment Failed!</h1>
                    <p>Your payment has been failed.</p>
                    <p>Please try again!</p>
                  </>
                ) : success ? (
                  <>
                    <i
                      class="fa fa-check-circle"
                      aria-hidden="true"
                      style={{
                        fontSize: 80,
                        textAlign: "center",
                        color: Colors?.green,
                      }}
                    ></i>
                    <h1>Payment Successful!</h1>
                    <p>Your payment has been successfully processed.</p>
                    <p>Thank you for your purchase!</p>
                    <p>
                      <b>Note : </b>Please Do not refresh the page
                    </p>
                  </>
                ) : (
                  <>
                    <p>Please try again!</p>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {/* </SidebarLayout> */}
    </>
  );
};

// 28 feb 2025 code
// import React, { useEffect, useState } from "react";
// import { Colors } from "../../Constants";

// import { useDispatch } from "react-redux";
// import { productCheckout } from "../../Redux/Services/OtherServices";
// import { getData, storageKey, storeData } from "../../Utility/Storage";
// import { routeName } from "../../Utility";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Loader } from "../../Components";
// import SidebarLayout from "../../layout/SidebarLayout";

// export const PaymentStatus = () => {
//   const location = useLocation();
//   const navigation = useNavigate();
//   const dispatch = useDispatch();
//   const successUrl = "success=true";
//   const cancelUrl = "canceled=true";
//   const params = new URLSearchParams(window.location.search);
//   const session_id = params.get("session_id");
//   const userId = params.get("userID");
//   const mode = params.get("mode");
//   const success = params.get("success");
//   const cancel = params.get("canceled");
//   const base64Data = params.get("data")
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (window.location.href.includes(successUrl)) {
//       setLoading(false);
//       setTimeout(() => {
//         if (mode === "extra_payment" && base64Data) {
//           let decode = atob(base64Data);
//           // console.log(JSON.parse(decode), decode?.proposal_id,"fkghjkdfhgkjfdhgkjfhd");
//           navigation(
//             `${
//               routeName.EXTRA_PAY_LIST
//             }?${new URLSearchParams({
//               proposalId: JSON.parse(decode)?.proposal_id,
//               id: JSON.parse(decode)?.post_id,
//             }).toString()}`
//           );
//           // storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
//           // navigation(routeName?.CLIENT_PROJECTS);
//         } else if (mode == "subscription") {
//           navigation(routeName?.HOME);
//         } else if (mode == "payment") {
//           storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
//           navigation(routeName?.CLIENT_PROJECTS);
//         }else{
//           navigation(routeName?.HOME, {replace: true})
//         }
//       }, 5000);
//       // handleCheckout();
//     } else if (window.location.href.includes(cancelUrl)) {
//       setLoading(false);
//       setTimeout(() => {
//         if (mode == "subscription") {
//           navigation(routeName?.PACKAGES);
//         } else if (mode == "payment") {
//           navigation(routeName?.CHECKOUT);
//         } else if(mode === "extra_payment"){
//           storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
//           navigation(routeName?.CLIENT_PROJECTS);
//         }
//       }, 5000);
//     }
//   }, [location]);

//   const handleCheckout = async () => {
//     setLoading(true);
//     // let userId = await getData(storageKey?.USER_ID);
//     var body = {
//       //   action: "session",
//       action: "session_react",
//       sessions_id: session_id,
//       user_id: userId,
//     };
//     // console.log("sessionId body-----", body);
//     let res = await dispatch(productCheckout(body));
//     // console.log("productCheckout.productCheckout.state-------", res);
//     if (res?.status == 200) {
//       setLoading(false);
//       setTimeout(() => {
//         if (res?.results?.type === "extra_payment") {
//           storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
//           navigation(routeName?.CLIENT_PROJECTS);
//         } else if (mode == "subscription") {
//           navigation(routeName?.HOME);
//         } else if (mode == "payment") {
//           navigation(routeName?.CLIENT_PROJECTS);
//         }
//       }, 5000);
//     }
//   };

//   return (
//     <>
//       <SidebarLayout>
//         <div class="searchtalentList dashboard_main">
//           <div className="car-page">
//             <div className="col-md-12">
//               <div
//                 style={{
//                   textAlign: "center",
//                 }}
//               >
//                 {loading ? (
//                   <Loader isLoading={loading} />
//                 ) : cancel === "true" ? (
//                   <>
//                     <i
//                       class="fa fa-times-circle"
//                       aria-hidden="true"
//                       style={{
//                         fontSize: 80,
//                         textAlign: "center",
//                         color: Colors?.red,
//                       }}
//                     ></i>
//                     <h1>Payment Failed!</h1>
//                     <p>Your payment has been failed.</p>
//                     <p>Please try again!</p>
//                   </>
//                 ) : success ? (
//                   <>
//                     <i
//                       class="fa fa-check-circle"
//                       aria-hidden="true"
//                       style={{
//                         fontSize: 80,
//                         textAlign: "center",
//                         color: Colors?.green,
//                       }}
//                     ></i>
//                     <h1>Payment Successful!</h1>
//                     <p>Your payment has been successfully processed.</p>
//                     <p>Thank you for your purchase!</p>
//                   </>
//                 ) : (
//                   <>
//                     <p>Please try again!</p>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </SidebarLayout>
//     </>
//   );
// };
