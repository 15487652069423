import { createContext, useEffect, useState } from "react";
import { auth, db } from "../Utility/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  // Update user status in Firestore
  const updateUserStatus = async (status) => {
    if (!currentUser || !currentUser.uid) return; // Ensure user exists
    const userId = currentUser.uid;
    try {
      await setDoc(doc(db, "userStatus", userId), { status }, { merge: true });
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  // Logout function
  const handleFirebaseLogout = async () => {
    if (currentUser) {
      await updateUserStatus(currentUser.uid, "offline"); // Ensure user status is set to offline
    }
    await signOut(auth);
    setCurrentUser(null);
  };

  useEffect(() => {
    console.log("Initializing auth state...");

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        await updateUserStatus(user.uid, "online"); // Now called AFTER setting the user
      } else {
        console.log("loggging out------");
        setCurrentUser(null);
      }
      setLoading(false); // Authentication state is ready
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while Firebase initializes
  }

  return (
    <AuthContext.Provider value={{ currentUser, handleFirebaseLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// import { createContext, useEffect, useState } from "react";
// import { auth, db } from "../Utility/firebase";
// import { onAuthStateChanged, signOut } from "firebase/auth";
// import { doc, setDoc } from "firebase/firestore";

// export const AuthContext = createContext();

// export const AuthContextProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(null);

//   // Update user status
//   const updateUserStatus = async (status) => {
//     if (currentUser) {
//       const userId = currentUser.uid;
//       try {
//         await setDoc(
//           doc(db, "userStatus", userId),
//           { status },
//           { merge: true }
//         );
//       } catch (error) {
//         console.error("Error updating status:", error.message);
//       }
//     }
//   };

//   // Logout function
//   const handleFirebaseLogout = async () => {
//     await updateUserStatus("offline");
//     await signOut(auth);
//   };

//   useEffect(() => {
//     console.log("useruseruseruser------");

//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setCurrentUser(user);
//         updateUserStatus("online");
//       } else {
//         setCurrentUser(null);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   return (
//     <AuthContext.Provider value={{ currentUser, handleFirebaseLogout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
