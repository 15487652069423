import React, { useEffect, useState } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeletePost, Loader } from "../../../Components";
import SidebarLayout from "../../../layout/SidebarLayout";
import {
  cancelClientProposalApi,
  getModelProjects,
} from "../../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey, storeData } from "../../../Utility/Storage";
import { routeName, showToast } from "../../../Utility";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../Constants";
import { CAncelModal, RequestPayment } from "../../../Components/Popups";
import FeedbackModal from "../../../Components/Popups/Feedback";
import {
  getAdminJobsApi,
  hideShowReportedApi,
} from "../../../Redux/Services/AdminServices";

const AllAdminJobs = () => {
  let queryType = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [jobsList, setJobsList] = useState([]);
  const [tab, setTab] = useState(queryType || "posted");
  const [isWithDrawModal, setIsWithDrawModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [RatingModal, setRatingModal] = useState(false);
  const [rating, setRating] = useState([]);
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [isDirectConfirm, setIsDirectConfirm] = useState(false);
  const [isAskModel, setIsAskModel] = useState(false);
  const [description, setDescription] = useState("");
  const [hideShowModal, setHideShowModal] = useState(false);

  useEffect(() => {
    getQueryData();
  }, []);

  const getQueryData = async () => {
    queryType = await getData(storageKey.SELECTED_JOB_TYPE);
    getAllJobsList(queryType || "posted");
    setTab(queryType || "posted");
  };

  const handleTabs = async (type) => {
    storeData(storageKey.SELECTED_JOB_TYPE, type);
    setTab(type);
    setJobsList([]);
    getAllJobsList(type);
  };

  const getAllJobsList = async (type) => {
    var body = {
      job_status: type,
    };
    setLoading(true);
    let res = await dispatch(getAdminJobsApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setJobsList(res?.results || []);
    } else {
      setJobsList([]);
    }
  };

  const handleHideShow = async () => {
    try {
      let body = {
        super_id: loggedInUser?.user_data?.user_id,
        action:
          selectedItem?.post_meta_details?.hide_status === "show"
            ? "hide"
            : "show",
        post_id: selectedItem?.profile?.ID,
        action_to: "project",
      };
      setLoading(true);
      let res = await dispatch(hideShowReportedApi(body));
      setLoading(false);
      if (res?.status === 200) {
        setHideShowModal(false);
        getQueryData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const options = [
    {
      name: "Posted Jobs",
      type: "posted",
    },
    {
      name: "Ongoing Jobs",
      type: "ongoing",
    },
    {
      name: "Completed Jobs",
      type: "completed",
    },
    {
      name: "Direct Jobs",
      type: "direct",
    },
    {
      name: "Canceled Jobs",
      type: "cancelled",
    },
  ];
  console.log("jobsListjobsList-----", jobsList);
  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="jobs"
              activeKey={tab}
              onSelect={handleTabs}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    {jobsList?.length
                      ? jobsList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      {ele?.post_meta_details
                                        ?.project_status && (
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i
                                            style={{
                                              color:
                                                ele?.post_meta_details
                                                  ?.project_status ==
                                                "completed"
                                                  ? Colors?.green
                                                  : ele?.post_meta_details
                                                      ?.project_status ==
                                                    "cancelled"
                                                  ? Colors?.red
                                                  : Colors?.blue,
                                            }}
                                            class={
                                              ele?.post_meta_details
                                                ?.project_status == "completed"
                                                ? "fa fa-check-circle"
                                                : ele?.post_meta_details
                                                    ?.project_status ==
                                                  "cancelled"
                                                ? "fa fa-times-circle"
                                                : "fa fa-check-fa fa-spinner"
                                            }
                                          ></i>
                                          {"  "}
                                          {
                                            ele?.post_meta_details
                                              ?.project_status
                                          }
                                        </a>
                                      )}
                                      <h2
                                        onClick={() => {
                                          storeData(
                                            storageKey.SELECTED_JOB_TYPE,
                                            tab
                                          );
                                          const queryParams = new URLSearchParams(
                                            {
                                              id: ele?.profile?.ID,
                                              type:
                                                item?.type === "direct"
                                                  ? "notification"
                                                  : "",
                                            }
                                          );
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        {ele?.profile?.post_title}
                                      </h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span>
                                          <i class="far fa-flag"></i> Location :{" "}
                                          {ele?.post_meta_details?.country} |{" "}
                                          {ele?.post_meta_details?.city}
                                        </span>
                                      </li>
                                      <li>
                                        <a class="wt-clicksavefolder">
                                          <i class="far fa-folder"></i> Type :{" "}
                                          {ele?.post_meta_details
                                            ?.project_type == "fixed_project"
                                            ? "Fixed"
                                            : ele?.post_meta_details
                                                ?.project_type}
                                        </a>
                                      </li>
                                      {ele?.post_meta_details?.project_type ==
                                        "Hourly" && (
                                        <li>
                                          <span class="wt-dashboradclock">
                                            <i class="fa fa-usd"></i> Hourly
                                            Rate :{" "}
                                            {
                                              ele?.post_meta_details
                                                ?._hourly_rate
                                            }
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Start
                                          Date :{" "}
                                          {
                                            ele?.post_meta_details
                                              ?.starting_date
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> End
                                          Date :{" "}
                                          {ele?.post_meta_details?.end_date}
                                        </span>
                                      </li>
                                    </ul>
                                    <div class="wt-btnarea-btn">
                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          storeData(
                                            storageKey.SELECTED_JOB_TYPE,
                                            tab
                                          );
                                          const queryParams = new URLSearchParams(
                                            {
                                              id: ele?.profile?.ID,
                                              type:
                                                item?.type === "direct"
                                                  ? "notification"
                                                  : "",
                                            }
                                          );
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        View Job
                                      </span>

                                      {item?.type === "posted" ? (
                                        <>
                                          <span
                                            class="wt-bt"
                                            onClick={() => {
                                              const queryParams = new URLSearchParams(
                                                {
                                                  id: ele?.profile?.ID,
                                                }
                                              );
                                              navigate(
                                                `${
                                                  ele?.post_meta_details
                                                    ?.model_type_req ===
                                                  "casting calls"
                                                    ? routeName.CASTING_JOB
                                                    : routeName.JOB_POST
                                                }?${queryParams.toString()}`
                                              );
                                            }}
                                          >
                                            Edit
                                          </span>
                                          <span
                                            class="wt-bt"
                                            onClick={() => {
                                              setSelectedItem(ele);
                                              setHideShowModal(true);
                                            }}
                                          >
                                            {/* Hide Job */}
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip-profile`}>
                                                  {ele?.post_meta_details
                                                    ?.hide_status === "show"
                                                    ? "Hide"
                                                    : "Show"}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {ele?.post_meta_details
                                                  ?.hide_status === "show"
                                                  ? "Hide Job"
                                                  : "Show Job"}
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div class="wt-rightarea">
                                    <div class="wt-hireduserstatus">
                                      <span>Published By</span>
                                      <h4>
                                        {ele?.project_author?.author_title}
                                      </h4>
                                      <ul class="wt-hireduserimgs">
                                        <li>
                                          <figure>
                                            <img
                                              src={
                                                ele?.project_author?.profile_url
                                                  ?.url
                                              }
                                              alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                              class="mCS_img_loaded"
                                            />
                                          </figure>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : undefined}

                    {!loading && !jobsList?.length ? (
                      <div className="wt-contenthead-center">
                        <p>No Jobs Found</p>
                      </div>
                    ) : undefined}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>

        <RequestPayment
          setVisible={setIsWithDrawModal}
          visible={isWithDrawModal}
          selectedItem={selectedItem}
          getAllJobsList={getAllJobsList}
          setTab={setTab}
          setLoading={setLoading}
          tab={tab}
        />

        <FeedbackModal
          setShow={setRatingModal}
          show={RatingModal}
          proposalId={selectedItem?.post_meta_details?.proposal_id}
          projectId={selectedItem?.profile?.ID}
          type="Rating"
          role="talent"
          setLoading={setLoading}
          getAllJobsList={getAllJobsList}
          setRating={setRating}
          rating={rating}
        />
        {/* {isDirectConfirm ? (
          <DeletePost
            setShow={setIsDirectConfirm}
            show={isDirectConfirm}
            title="Alert"
            handleConfirm={() => {
              handleCancelProposal("direct_cancel");
            }}
            text="Are you sure you want to cancel this project?"
          />
        ) : undefined} */}

        {hideShowModal ? (
          <DeletePost
            setShow={setHideShowModal}
            show={hideShowModal}
            title="Alert"
            handleConfirm={() => {
              handleHideShow();
            }}
            text={`Are you sure you want to ${
              selectedItem?.post_meta_details?.hide_status === "show"
                ? "hide"
                : "show"
            } this Job?`}
          />
        ) : undefined}

        {/* {isCancelConfirm ? (
          <CAncelModal
            setVisible={setIsCancelConfirm}
            visible={isCancelConfirm}
            title="Alert"
            handleDirectConfirm={() => {
              setIsCancelConfirm(false);
              setIsDirectConfirm(true);
            }}
            handleAskToClient={() => {
              setIsAskModel(true);
              if (!description && isAskModel) {
                showToast("Please add description");
              } else if (isAskModel && description) {
                handleCancelProposal("ask_client");
              }
            }}
            setDescription={setDescription}
            description={description}
            isAskModel={isAskModel}
            setIsAskModel={setIsAskModel}
          />
        ) : undefined} */}
      </SidebarLayout>
    </>
  );
};

export default AllAdminJobs;
