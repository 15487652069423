import React, { useContext, useEffect, useState } from 'react'
import Messages from './Messages'
import ChatInput from './ChatInput'
import {
  doc,
  onSnapshot,
  updateDoc,
  getDoc,
  arrayUnion,
} from 'firebase/firestore'
import { db } from '../../Utility/firebase'
import { AuthContext } from '../../Context/AuthContext'
import { ChatContext } from '../../Context/ChatContext'

const ChatBar = ({ setLoading }) => {
  const { data } = useContext(ChatContext)
  const { currentUser } = useContext(AuthContext)
  const [isTyping, setIsTyping] = useState(false)
  const [status, setStatus] = useState('offline')
  const [isChatOpen, setIsChatOpen] = useState(true)
  console.log('ChatBar currentUser-------', currentUser)
  useEffect(() => {
    if (data?.user?.uid) {
      const statusRef = doc(db, 'userStatus', data?.user?.uid)
      const unsubscribe = onSnapshot(statusRef, (statusDoc) => {
        if (statusDoc.exists()) {
          setStatus(statusDoc.data()?.status || 'offline')
        } else {
          setStatus('offline')
        }
      })

      return () => unsubscribe()
    }
  }, [data?.user?.uid])

  useEffect(() => {
    if (data?.chatId && data?.user?.uid) {
      const otherUserId = data?.user?.uid
      const typingStatusRef = doc(
        db,
        'typingStatus',
        `${currentUser?.uid}_${otherUserId}`,
      )

      const unsubscribeTyping = onSnapshot(typingStatusRef, (doc) => {
        if (doc.exists()) {
          setIsTyping(doc.data()?.typing || false)
        } else {
          setIsTyping(false)
        }
      })

      return () => unsubscribeTyping()
    }
  }, [data?.chatId, data?.user?.uid])

  useEffect(() => {
    if (data?.chatId && data?.user?.uid) {
      const chatRef = doc(db, 'chats', data?.chatId)
      const userChatsRef = doc(db, 'userChats', currentUser.uid)

      const unsubscribe = onSnapshot(chatRef, async (chatDoc) => {
        updateDoc(userChatsRef, {
          [data?.chatId + '.unreadCount']: 0,
        })
        const messages = chatDoc.data()?.messages || []
        const unreadMessages = messages.filter(
          (message) =>
            message.receiverId === currentUser.uid && message.status !== 'read',
        )

        if (unreadMessages.length > 0) {
          const updatedMessages = messages.map((message) => {
            if (
              message.receiverId === currentUser.uid &&
              message.status !== 'read'
            ) {
              return { ...message, status: 'read' }
            }
            return message
          })
          try {
            await updateDoc(chatRef, { messages: updatedMessages })
            console.log('Messages marked as read')
          } catch (error) {
            console.error('Error marking messages as read: ', error)
          }
        }
      })

      // onSnapshot(userChatsRef, (chatDoc) => {
      //   const messages = chatDoc.data()?.messages || []
      //   messages.forEach(async (message) => {
      //     console.log('chatbarchatbarchatbarchatbar------',message.receiverId , currentUser.uid, message.status)
      //     if (
      //       message.receiverId === currentUser.uid &&
      //       message.status !== 'read'
      //     ) {
      //       const messageIndex = messages.findIndex(
      //         (m) => m._id === message._id,
      //       )
      //       messages[messageIndex].status = 'read'
      //       await updateDoc(chatRef, { messages })
      //     }
      //   })
      // })

      return () => {
        unsubscribe()
      }
    }
  }, [data?.chatId, currentUser?.uid])

  const handleDeleteMessagesForMe = async (messageIds) => {
    console.log(
      'currentUser?.uid && messageIds?.length---------',
      currentUser?.uid,
      messageIds,
    )
    if (currentUser?.uid && messageIds?.length) {
      const userRef = doc(db, 'users', currentUser.uid)

      try {
        await updateDoc(userRef, {
          deletedMessages: arrayUnion(...messageIds),
        })
      } catch (error) {
        console.error('Error deleting messages for current user: ', error)
      }
    }
  }

  return (
    <div className="chat">
      {data?.user?.uid ? (
        <>
          <div className="wt-dashboardboxtitle currentChat">
            <img src={data?.user?.photoURL} />
            <div className="typingStatus">
              <h2>{data?.user?.displayName} </h2>
              {isTyping ? (
                <span>Typing...</span>
              ) : (
                <span
                  style={{
                    color:
                      status === 'online'
                        ? 'green'
                        : status === 'away'
                        ? 'orange'
                        : 'gray',
                  }}
                >
                  {status === 'online'
                    ? 'Online'
                    : status === 'away'
                    ? 'Away'
                    : 'Offline'}
                </span>
              )}
              <div>
                {/* <i style={{color:'red'}}
                className="fa fa-trash"
                aria-hidden="true"
                onClick={()=>handleDeleteMessagesForMe(data)} // Trigger delete on click
              ></i> */}
              </div>
            </div>
          </div>
          <Messages setLoading={setLoading} />
          <ChatInput setLoading={setLoading} />
        </>
      ) : (
        <div className="wt-dashboardboxtitle currentChat">
          <span>Go Ahead and start the conversation</span>
        </div>
      )}
    </div>
  )
}

export default ChatBar
