import * as url from "../../API Services/Url";
import * as Services from "../../API Services/services";
import { getData, storageKey } from "../../Utility/Storage";
import {
  addEditBlogError,
  addEditBlogLoading,
  addEditBlogSuccess,
  addEditFaqError,
  addEditFaqLoading,
  addEditFaqSuccess,
  addEditPackageError,
  addEditPackageLoading,
  addEditPackageSuccess,
  allModelsListError,
  allModelsListLoading,
  allModelsListSuccess,
  newApplicantModelLoading,
  newApplicantModelError,
  newApplicantModelSuccess,
  uploadMediaError,
  uploadMediaLoading,
  uploadMediaSuccess,
  verifiedModelsError,
  verifiedModelsLoading,
  verifiedModelsSuccess,
  incompleteModelLoading,
  incompleteModelSuccess,
  incompleteModelError,
  allVerifiedModelLoading,
  allVerifiedModelSuccess,
  allVerifiedModelError,
  rejectedModelsLoading,
  rejectedModelsSuccess,
  rejectedModelsError,
  acceptedIdUserLoading,
  acceptedIdUserSuccess,
  acceptedIdUserError,
  rejectedIdUserLoading,
  rejectedIdUserSuccess,
  rejectedIdUserError,
  profileApproveDeclineLoading,
  profileApproveDeclineSuccess,
  profileApproveDeclineError,
  allActorsListLoading,
  allActorsListSuccess,
  allActorsListError,
  allVerifiedActorLoading,
  allVerifiedActorSuccess,
  allVerifiedActorError,
  newApplicantActorLoading,
  newApplicantActorSuccess,
  newApplicantActorError,
  verifiedActorsLoading,
  verifiedActorsSuccess,
  verifiedActorsError,
  rejectedActorsLoading,
  rejectedActorsSuccess,
  rejectedActorsError,
  incompleteActorLoading,
  incompleteActorSuccess,
  incompleteActorError,
  adminResetPasswordLoading,
  adminResetPasswordSuccess,
  adminResetPasswordError,
  rejectedClientsLoading,
  rejectedClientsSuccess,
  rejectedClientsError,
  verifiedClientsLoading,
  verifiedClientsSuccess,
  verifiedClientsError,
  incompleteClientLoading,
  incompleteClientSuccess,
  incompleteClientError,
  newApplicantCLientLoading,
  newApplicantCLientSuccess,
  newApplicantCLientError,
  allVerifiedCLientLoading,
  allVerifiedCLientSuccess,
  allVerifiedCLientError,
  allClientsListLoading,
  allClientsListSuccess,
  allClientsListError,
  allPartnerListLoading,
  allPartnerListSuccess,
  allPartnerListError,
  allVerifiedPartnerLoading,
  allVerifiedPartnerSuccess,
  allVerifiedPartnerError,
  newApplicantPartnerLoading,
  newApplicantPartnerSuccess,
  newApplicantPartnerError,
  incompletePartnerLoading,
  incompletePartnerSuccess,
  incompletePartnerError,
  verifiedPartnerLoading,
  verifiedPartnerSuccess,
  verifiedPartnerError,
  rejectedPartnerLoading,
  rejectedPartnerSuccess,
  rejectedPartnerError,
  rejectedPhotographerLoading,
  rejectedPhotographerSuccess,
  rejectedPhotographerError,
  verifiedPhotographerLoading,
  verifiedPhotographerSuccess,
  verifiedPhotographerError,
  incompletePhotographerLoading,
  incompletePhotographerSuccess,
  incompletePhotographerError,
  newApplicantPhotographerLoading,
  newApplicantPhotographerSuccess,
  newApplicantPhotographerError,
  allVerifiedPhotographerLoading,
  allVerifiedPhotographerSuccess,
  allVerifiedPhotographerError,
  allPhotographerListLoading,
  allPhotographerListSuccess,
  allPhotographerListError,
  addModelByAdminLoading,
  addModelByAdminSuccess,
  addModelByAdminError,
} from "../Actions/AdminActions";
import {
  photographerRegisterError,
  photographerRegisterLoading,
  photographerRegisterSuccess,
  updatePhotographerProfileError,
  updatePhotographerProfileLoading,
  updatePhotographerProfileSuccess,
} from "../Actions/AuthActions";

export const addEditFaqListAPi = (body) => async (dispatch) => {
  dispatch(addEditFaqLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_EDIT_FAQ + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addEditFaqSuccess, undefined);
    } else {
      dispatch(addEditFaqError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addEditFaqError(false));
    return { message: error };
  }
};
export const getBanners = (body, url) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    return response;
  } catch (error) {
    dispatch(addEditFaqError(false));
    return { message: error };
  }
};
export const addBanner = (body, url) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.formDataPost(
      url + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    return response;
  } catch (error) {
    dispatch(addEditFaqError(false));
    return { message: error };
  }
};
export const uploadImage = (body, URL) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.formDataPost(
      URL + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    return response;
  } catch (error) {
    dispatch(addEditFaqError(false));
    return { message: error };
  }
};

export const getUploadedImage = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.formDataPost(
      url.GET_UPLOADED_IMAGE + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    return response;
  } catch (error) {
    dispatch(addEditBlogError(false));
    return { message: error };
  }
};

export const addEditBlogApi = (body) => async (dispatch) => {
  dispatch(addEditBlogLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_BLOG + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addEditBlogSuccess, undefined);
    } else {
      dispatch(addEditBlogError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addEditBlogError(false));
    return { message: error };
  }
};

export const uploadMedia = (body) => async (dispatch) => {
  dispatch(uploadMediaLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.formDataPost(
      url.UPLOAD_MEDIA + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(uploadMediaSuccess, undefined);
    } else {
      dispatch(uploadMediaError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(uploadMediaError(false));
    return { message: error };
  }
};

export const addEditPackageApi = (body) => async (dispatch) => {
  dispatch(addEditPackageLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_EDIT_PACKAGE + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addEditPackageSuccess, undefined);
    } else {
      dispatch(addEditPackageError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addEditPackageError(false));
    return { message: error };
  }
};

export const deletePackageApi = (body) => async (dispatch) => {
  dispatch(addEditPackageLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.DELETE_PACKAGE + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addEditPackageSuccess, undefined);
    } else {
      dispatch(addEditPackageError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addEditPackageError(false));
    return { message: error };
  }
};

export const getSubscribedPackageUser = (body) => async (dispatch) => {
  dispatch(addEditPackageLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.SUBSCRIBED_PLANS_USER + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addEditPackageSuccess, undefined);
    } else {
      dispatch(addEditPackageError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addEditPackageError(false));
    return { message: error };
  }
};

export const allModelsListApi = (body) => async (dispatch) => {
  dispatch(allModelsListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ALL_MODELS_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allModelsListSuccess, undefined);
    } else {
      dispatch(allModelsListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allModelsListError(false));
    return { message: error };
  }
};

export const verifiedModelsApi = (body) => async (dispatch) => {
  dispatch(allVerifiedModelLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VERIFIED_MODELS_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allVerifiedModelSuccess, undefined);
    } else {
      dispatch(allVerifiedModelError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allVerifiedModelError(false));
    return { message: error };
  }
};

export const newModelsListAPi = (body) => async (dispatch) => {
  dispatch(newApplicantModelLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.NEW_APPLICANTS_MODEL_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(newApplicantModelSuccess, undefined);
    } else {
      dispatch(newApplicantModelError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(newApplicantModelError(false));
    return { message: error };
  }
};

export const incompleteModelsListAPi = (body) => async (dispatch) => {
  dispatch(incompleteModelLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.MODEL_INCOMPLETE_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(incompleteModelSuccess, undefined);
    } else {
      dispatch(incompleteModelError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(incompleteModelError(false));
    return { message: error };
  }
};

export const modelIdRequestApi = (body) => async (dispatch) => {
  dispatch(verifiedModelsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.MODEL_VERIFY_LIST_REQUEST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(verifiedModelsSuccess, undefined);
    } else {
      dispatch(verifiedModelsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(verifiedModelsError(false));
    return { message: error };
  }
};

export const rejectedModelApi = (body) => async (dispatch) => {
  dispatch(rejectedModelsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECTED_MODEL_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedModelsSuccess, undefined);
    } else {
      dispatch(rejectedModelsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedModelsError(false));
    return { message: error };
  }
};

export const acceptUserIdApi = (body) => async (dispatch) => {
  dispatch(acceptedIdUserLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ACCEPT_APPROVAL + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(acceptedIdUserSuccess, undefined);
    } else {
      dispatch(acceptedIdUserError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(acceptedIdUserError(false));
    return { message: error };
  }
};

export const rejectUserIdApi = (body) => async (dispatch) => {
  dispatch(rejectedIdUserLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECT_APPROVAL + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedIdUserSuccess, undefined);
    } else {
      dispatch(rejectedIdUserError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedIdUserError(false));
    return { message: error };
  }
};

export const updateTalentCategory = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.TALENT_CATEGORY + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );

    return response;
  } catch (error) {
    return { message: error };
  }
};

export const profileApproveDeclineApi = (body) => async (dispatch) => {
  dispatch(profileApproveDeclineLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PROFILE_APPROVE_DECLINE + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(profileApproveDeclineSuccess, undefined);
    } else {
      dispatch(profileApproveDeclineError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(profileApproveDeclineError(false));
    return { message: error };
  }
};

export const allActorListApi = (body) => async (dispatch) => {
  dispatch(allActorsListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ALL_ACTOR_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allActorsListSuccess, undefined);
    } else {
      dispatch(allActorsListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allActorsListError(false));
    return { message: error };
  }
};

export const verifiedActorApi = (body) => async (dispatch) => {
  dispatch(allVerifiedActorLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VERIFIED_ACTOR_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allVerifiedActorSuccess, undefined);
    } else {
      dispatch(allVerifiedActorError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allVerifiedActorError(false));
    return { message: error };
  }
};

export const newActorsListAPi = (body) => async (dispatch) => {
  dispatch(newApplicantActorLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.NEW_APPLICANTS_ACTOR_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(newApplicantActorSuccess, undefined);
    } else {
      dispatch(newApplicantActorError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(newApplicantActorError(false));
    return { message: error };
  }
};

export const incompleteActorListAPi = (body) => async (dispatch) => {
  dispatch(incompleteActorLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ACTOR_INCOMPLETE_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(incompleteActorSuccess, undefined);
    } else {
      dispatch(incompleteActorError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(incompleteActorError(false));
    return { message: error };
  }
};

export const ActorIdRequestApi = (body) => async (dispatch) => {
  dispatch(verifiedActorsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ACTOR_VERIFY_LIST_REQUEST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(verifiedActorsSuccess, undefined);
    } else {
      dispatch(verifiedActorsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(verifiedModelsError(false));
    return { message: error };
  }
};

export const rejectedActorApi = (body) => async (dispatch) => {
  dispatch(rejectedActorsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECTED_ACTOR_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedActorsSuccess, undefined);
    } else {
      dispatch(rejectedActorsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedActorsError(false));
    return { message: error };
  }
};

export const adminResetPasswordApi = (body) => async (dispatch) => {
  dispatch(adminResetPasswordLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADMIN_RESEND_PASSWORD + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(adminResetPasswordSuccess, undefined);
    } else {
      dispatch(adminResetPasswordError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(adminResetPasswordError(false));
    return { message: error };
  }
};

export const allClientListApi = (body) => async (dispatch) => {
  dispatch(allClientsListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ALL_CLIENT_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allClientsListSuccess, undefined);
    } else {
      dispatch(allClientsListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allClientsListError(false));
    return { message: error };
  }
};

export const verifiedClientApi = (body) => async (dispatch) => {
  dispatch(allVerifiedCLientLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VERIFIED_CLIENT_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allVerifiedCLientSuccess, undefined);
    } else {
      dispatch(allVerifiedCLientError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allVerifiedCLientError(false));
    return { message: error };
  }
};

export const newClientsListAPi = (body) => async (dispatch) => {
  dispatch(newApplicantCLientLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.NEW_APPLICANTS_CLIENT_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(newApplicantCLientSuccess, undefined);
    } else {
      dispatch(newApplicantCLientError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(newApplicantCLientError(false));
    return { message: error };
  }
};

export const incompleteClientListAPi = (body) => async (dispatch) => {
  dispatch(incompleteClientLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.CLIENT_INCOMPLETE_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(incompleteClientSuccess, undefined);
    } else {
      dispatch(incompleteClientError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(incompleteClientError(false));
    return { message: error };
  }
};

export const ClientIdRequestApi = (body) => async (dispatch) => {
  dispatch(verifiedClientsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.CLIENT_VERIFY_LIST_REQUEST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(verifiedClientsSuccess, undefined);
    } else {
      dispatch(verifiedClientsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(verifiedModelsError(false));
    return { message: error };
  }
};

export const rejectedClientApi = (body) => async (dispatch) => {
  dispatch(rejectedClientsLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECTED_CLIENT_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedClientsSuccess, undefined);
    } else {
      dispatch(rejectedClientsError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedClientsError(false));
    return { message: error };
  }
};

export const allPartnerListApi = (body) => async (dispatch) => {
  dispatch(allPartnerListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ALL_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allPartnerListSuccess, undefined);
    } else {
      dispatch(allPartnerListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allPartnerListError(false));
    return { message: error };
  }
};

export const verifiedPartnerApi = (body) => async (dispatch) => {
  dispatch(allVerifiedPartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VERIFIED_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allVerifiedPartnerSuccess, undefined);
    } else {
      dispatch(allVerifiedPartnerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allVerifiedPartnerError(false));
    return { message: error };
  }
};

export const newPartnerListAPi = (body) => async (dispatch) => {
  dispatch(newApplicantPartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.NEW_APPLICANTS_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(newApplicantPartnerSuccess, undefined);
    } else {
      dispatch(newApplicantPartnerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(newApplicantPartnerError(false));
    return { message: error };
  }
};

export const incompletePartnerListAPi = (body) => async (dispatch) => {
  dispatch(incompletePartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PARTNER_INCOMPLETE_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(incompletePartnerSuccess, undefined);
    } else {
      dispatch(incompletePartnerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(incompletePartnerError(false));
    return { message: error };
  }
};

export const partnerIdRequestApi = (body) => async (dispatch) => {
  dispatch(verifiedPartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PARTNER_VERIFY_LIST_REQUEST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(verifiedPartnerSuccess, undefined);
    } else {
      dispatch(verifiedPartnerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(verifiedModelsError(false));
    return { message: error };
  }
};

export const rejectedPartnerApi = (body) => async (dispatch) => {
  dispatch(rejectedPartnerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECTED_PARTNER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedPartnerSuccess, undefined);
    } else {
      dispatch(rejectedPartnerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedActorsError(false));
    return { message: error };
  }
};

export const allPhotographerListApi = (body) => async (dispatch) => {
  dispatch(allPhotographerListLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ALL_PHOTOGRAPHER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allPhotographerListSuccess, undefined);
    } else {
      dispatch(allPhotographerListError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allPhotographerListError(false));
    return { message: error };
  }
};

export const verifiedPhotographerApi = (body) => async (dispatch) => {
  dispatch(allVerifiedPhotographerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.VERIFIED_PHOTOGRAPHER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(allVerifiedPhotographerSuccess, undefined);
    } else {
      dispatch(allVerifiedPhotographerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(allVerifiedPhotographerError(false));
    return { message: error };
  }
};

export const newPhotographerListAPi = (body) => async (dispatch) => {
  dispatch(newApplicantPhotographerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.NEW_APPLICANTS_PHOTOGRAPHER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(newApplicantPhotographerSuccess, undefined);
    } else {
      dispatch(newApplicantPhotographerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(newApplicantPhotographerError(false));
    return { message: error };
  }
};

export const incompletePhotographerListAPi = (body) => async (dispatch) => {
  dispatch(incompletePhotographerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PHOTOGRAPHER_INCOMPLETE_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(incompletePhotographerSuccess, undefined);
    } else {
      dispatch(incompletePhotographerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(incompletePhotographerError(false));
    return { message: error };
  }
};

export const photoIdRequestApi = (body) => async (dispatch) => {
  dispatch(verifiedPhotographerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PHOTOGRAPHER_VERIFY_LIST_REQUEST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(verifiedPhotographerSuccess, undefined);
    } else {
      dispatch(verifiedPhotographerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(verifiedPhotographerError(false));
    return { message: error };
  }
};

export const rejectedPhotographerApi = (body) => async (dispatch) => {
  dispatch(rejectedPhotographerLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REJECTED_PHOTOGRAPHER_LIST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(rejectedPhotographerSuccess, undefined);
    } else {
      dispatch(rejectedPhotographerError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(rejectedPhotographerError(false));
    return { message: error };
  }
};

export const addModelByIdApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_MODEL_ADMIN + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const updateModelByIdApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.EDIT_MODEL_ADMIN + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getUsersDataById = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.GET_USER_DETAIL + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess(true));
      return response;
    }
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const photographerRegisterAdmin = (body) => async (dispatch) => {
  dispatch(photographerRegisterLoading(true));
  try {
    const response = await Services.post(
      url.PHOTOGRAPHER_REGISTER + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response?.status === 200) {
      dispatch(
        photographerRegisterSuccess(false, {
          registrationData: response?.results,
        })
      );
    }
    dispatch(photographerRegisterSuccess(false));
    return response;
  } catch (error) {
    dispatch(photographerRegisterError(false));
    return { message: error };
  }
};

export const updatePhotographerAdmin = (body) => async (dispatch) => {
  dispatch(updatePhotographerProfileLoading(true));
  try {
    const response = await Services.post(
      url.UPDATE_PHOTOGRAPHER_REGISTER + "?apiKey=apiKey",
      "",
      body
    );
    if (response.data) {
      dispatch(updatePhotographerProfileSuccess(false));
    }
    dispatch(updatePhotographerProfileSuccess(false));
    return response;
  } catch (error) {
    dispatch(updatePhotographerProfileError(false));
    return { message: error };
  }
};

export const addActorByAdmin = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_ACTOR_ADMIN + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const updateActoryAdminApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.EDIT_ACTOR_ADMIN + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const addPartnerByAdmin = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PARTNER_REGISTER + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const updatePartnerByAdminApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PARTNER_UPDATE + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const addClientByAdmin = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADD_CLIENT_ADMIN + "?apiKey=apiKey",
      "",
      body,
      false,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const updateClientByAdminApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.EDIT_CLIENT_ADMIN + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const reportedContentApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.REPORTED_CONTENT + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const updateAdminProfile = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.ADMIN_EDIT_PROFILE + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getAllDeletedAccounts = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.DELETED_ACCOUNTS + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getAllPausedAccounts = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.PAUSED_ACCOUNTS + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const restoreResumeAccount = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.RESTORE_RESUME_ACCOUNT + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const hideShowReportedApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.HIDE_SHOW_REPORTED + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getAdminJobsApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.ADMIN_JOB + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getAdminPostsApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.ADMIN_SOCIAL_POST + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const getAdminPortfolioApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.ADMIN_PORTFOLIO + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const allTransactionsApi = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.CLIENT_TRANSACTION + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};

export const sendCustomEmailAdmin = (body) => async (dispatch) => {
  dispatch(addModelByAdminLoading(true));
  try {
    const response = await Services.post(
      url.CUSTOM_EMAIL + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );
    if (response?.status == 200) {
      dispatch(addModelByAdminSuccess, undefined);
    } else {
      dispatch(addModelByAdminError, undefined);
    }
    return response;
  } catch (error) {
    dispatch(addModelByAdminError(false));
    return { message: error };
  }
};
