export const Images = {
  logo: require("../Assets/Images/PNG/logo.png"),
  hero1: require("../Assets/Images/madelyn.png"),
  hero2: require("../Assets/Images/brook.png"),
  banner: require("../Assets/Images/banner.jpeg"),
  newsletter: require("../Assets/Images/newsletter.png"),
  notFound: require("../Assets/Images/404_page-not-found.webp"),
  inboxImage: require("../Assets/Images/thumbnail/img-19.png"),
  latestProposals: require("../Assets/Images/thumbnail/img-20.png"),
  packageExpiry: require("../Assets/Images/thumbnail/img-21.png"),
  followers: require("../Assets/Images/thumbnail/img-22.png"),
  tiktok: require("../Assets/Images/tiktok.png"),
  // bag: require("../Assets/Images/bag.png"),
  user: require("../Assets/Images/thumbnail/user.png"),
  ongoingjobs: require("../Assets/Images/thumbnail/img-16.png"),
  completedobs: require("../Assets/Images/thumbnail/img-17.png"),
  canceledjobs: require("../Assets/Images/thumbnail/img-15.png"),
  jobList: require("../Assets/Images/thumbnail/img-18.png"),
  postedjobs: require("../Assets/Images/thumbnail/img-23.png"),
  calendar: require("../Assets/Images/thumbnail/calender.png"),
  money: require("../Assets/Images/thumbnail/money.png"),
  history: require("../Assets/Images/thumbnail/history.png"),
  pending: require("../Assets/Images/thumbnail/pending.png"),
  mailSend: require("../Assets/Images/thumbnail/mail-send.png"),
  viewCamera: require("../Assets/Images/thumbnail/viewCamera.png"),
  actor: require("../Assets/Images/thumbnail/actor.png"),
  client: require("../Assets/Images/thumbnail/client.png"),
  photographer: require("../Assets/Images/thumbnail/photographer.png"),
  model: require("../Assets/Images/thumbnail/model.png"),
  referal: require("../Assets/Images/thumbnail/referal.png"),
  danger: require("../Assets/Images/thumbnail/danger.png"),
  partner: require("../Assets/Images/thumbnail/partner.png"),
  report: require("../Assets/Images/thumbnail/report.png"),
  package: require("../Assets/Images/thumbnail/package.png"),
  verify: require("../Assets/Images/thumbnail/verify.svg"),
  create_profile: require("../Assets/Images/create_profile.jpg"),
  apply_jobs: require("../Assets/Images/apply_jobs.jpg"),
  connect_brand: require("../Assets/Images/connect_brand.jpg"),
  jobHiring: require("../Assets/Images/job-hiring.jpg"),

  Bass_Pro_Shops_Logo: require("../Assets/Images/ClientLogos/Bass_Pro_Shops_Logo.png"),
  Boot_Barn_Logo: require("../Assets/Images/ClientLogos/Boot_Barn_Logo.png"),
  Dillard_Logo: require("../Assets/Images/ClientLogos/Dillard_Logo.png"),
  Eileen_Fisher_Logo: require("../Assets/Images/ClientLogos/Eileen_Fisher_Logo.png"),
  Hilton_logo: require("../Assets/Images/ClientLogos/Hilton_logo.png"),
  Michael_Kors_brand_logo: require("../Assets/Images/ClientLogos/Michael_Kors_brand_logo.png"),
  Palm_Beach_Tan_Logo: require("../Assets/Images/ClientLogos/Palm_Beach_Tan_Logo.jpg"),
  Sam_Club_Logo: require("../Assets/Images/ClientLogos/Sam_Club_Logo.png"),
  Sodexo_Logo: require("../Assets/Images/ClientLogos/Sodexo_Logo.jpg"),
  Sport_Clips_Logo: require("../Assets/Images/ClientLogos/Sport_Clips_Logo.jpg"),
  Walmart_Logo: require("../Assets/Images/ClientLogos/Walmart_Logo.png"),
  dummy_female: require("../Assets/Images/PNG/dummy_female.png"),
  dummy_male: require("../Assets/Images/PNG/dummy_male.png"),
};
