import React, { useEffect, useState } from "react";
import { Header, Footer, Loader } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UpdateJobProposal,
  getJobDetails,
  productCheckout,
  submitJobProposal,
} from "../../Redux/Services/OtherServices";
import { showToast } from "../../Utility";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const JobProposal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const proposalId = queryParams?.get("proposalId");
  //   console.log("Your Param Value:", id);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [jobDetail, setjobDetail] = useState("");
  const [loading, setLoading] = useState(true);
  const [proposedAmount, setProposedAmount] = useState("");
  const [estimateHours, setEstimateHours] = useState("");
  const [amounts, setAmounts] = useState({
    employeeAmount: 0,
    usageFee: 0,
    diemAmount: 0,
    serviceAmountPercent: 0,
  });
  const [description, setDescription] = useState("");

  const jobDetails = async () => {
    setLoading(true);
    if (id) {
      let body = {
        project_id: id,
      };
      let res = await dispatch(getJobDetails(body));
      setLoading(false);
      if (res?.status == 200) {
        setjobDetail(res?.results?.[0]);
        setProposedAmount(
          res?.results?.[0]?.post_meta_details?._project_cost
            ? res?.results?.[0]?.post_meta_details?._project_cost
            : res?.results?.[0]?.post_meta_details?._hourly_rate
            ? res?.results?.[0]?.post_meta_details?._hourly_rate
            : 0
        );
        setEstimateHours(
          res?.results?.[0]?.post_meta_details?._estimated_hours
            ? Number(res?.results?.[0]?.post_meta_details?._estimated_hours)
            : 0
        );
        setAmounts({
          employeeAmount: res?.results?.[0]?.post_meta_details?._project_cost
            ? res?.results?.[0]?.post_meta_details?._project_cost
            : res?.results?.[0]?.post_meta_details?._hourly_rate
            ? res?.results?.[0]?.post_meta_details?._hourly_rate
            : 0,
          usageFee: res?.results?.[0]?.post_meta_details?.usage_fee || 0,
          diemAmount:
            res?.results?.[0]?.post_meta_details?._project_cost &&
            res?.results?.[0]?.post_meta_details?.amount_of_per_diem_provided
              ? res?.results?.[0]?.post_meta_details
                  ?.amount_of_per_diem_provided
              : res?.results?.[0]?.post_meta_details
                  ?.amount_of_per_diem_provided
              ? res?.results?.[0]?.post_meta_details
                  ?.amount_of_per_diem_provided *
                res?.results?.[0]?.post_meta_details?.set_custom_time?.length
              : 0,
          serviceAmountPercent:
            loggedInUser && loggedInUser?.post_meta_details
              ? loggedInUser?.post_meta_details?.subscription_pro_id == 107
                ? 15
                : 10
              : 15,
        });
      }
    } else {
      setLoading(false);
    }
  };

  function formatString(inputString) {
    const stringWithoutHyphens = inputString?.replace(/-/g, " ");
    const words = stringWithoutHyphens.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const resultString = capitalizedWords.join(" ");
    return resultString;
  }

  const getProjectCheckoutDetails = async () => {
    let body = {
      action: "web_react_hire",
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      project_id: id,
      proposal_id: proposalId,
    };
    setLoading(true);
    let res = await dispatch(productCheckout(body));
    setLoading(false);
    // console.log("productCheckoutbodybody----", res);
    if (res?.status == 200) {
      if (
        res?.results?.prop_detail?.proposal_data?.fw_option?.[0]
          ?.proposed_amount
      ) {
        setProposedAmount(
          Number(
            res?.results?.prop_detail?.proposal_data?.fw_option?.[0]
              ?.proposed_amount
          )
        );
      }
    }
  };

  const handleSubmit = async () => {
    if (!description) {
      showToast("Description is required!");
      return;
    }
    try {
      let body = {
        user_id:
          partnerPermissions?.user_id || loggedInUser?.user_data?.user_id || "",
        project_id: id || "",
        total_amount: jobDetail?.post_meta_details?._project_cost
          ? `${
              Number(jobDetail?.post_meta_details?._project_cost) +
              Number(jobDetail?.post_meta_details?.usage_fee) +
              Number(jobDetail?.post_meta_details?.amount_of_per_diem_provided)
            }`
          : jobDetail?.post_meta_details?._hourly_rate
          ? `${
              Number(jobDetail?.post_meta_details?._hourly_rate) *
                Number(jobDetail?.post_meta_details?._estimated_hours) +
              Number(jobDetail?.post_meta_details?.usage_fee) +
              Number(
                jobDetail?.post_meta_details?.amount_of_per_diem_provided *
                  jobDetail?.post_meta_details?.set_custom_time?.length
              )
            }`
          : 0,
        admin_share:
          (((estimateHours
            ? Number(proposedAmount) * estimateHours
            : Number(proposedAmount)) +
            Number(amounts?.usageFee)) *
            Number(amounts?.serviceAmountPercent)) /
            100 || 0,
        freelancer_share:
          Number(amounts?.diemAmount) +
            ((estimateHours
              ? Number(proposedAmount) * estimateHours
              : Number(proposedAmount)) +
              Number(amounts?.usageFee) -
              (((estimateHours
                ? Number(proposedAmount) * estimateHours
                : Number(proposedAmount)) +
                Number(amounts?.usageFee)) *
                Number(amounts?.serviceAmountPercent)) /
                100) || 0,
        _amt_provided: amounts?.diemAmount ? Number(amounts?.diemAmount) : 0,
        _total_work_days: 2,
        per_hour_amount: proposedAmount || 0,
        proposed_amount: proposedAmount || 0,
        estimeted_time: estimateHours,
        proposal_desc: description || "",
      };
      // console.log(body, "sdkjhfjdhfkjd");

      if (proposalId) {
        body.proposal_id = proposalId;
      }
      let res = {};
      setLoading(true);
      if (proposalId) {
        res = await dispatch(UpdateJobProposal(body));
      } else {
        res = await dispatch(submitJobProposal(body));
      }
      setLoading(false);
      if (res?.status === 200) {
        // showToast('Proposal Submitted Successfully', 'success');
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    jobDetails();
  }, [id]);

  useEffect(() => {
    if (proposalId) {
      getProjectCheckoutDetails();
    }
  }, []);

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>Job Proposal</h1>
        </div>
      </div>
      <div className="container">
        <div class="row justify-content-md-center">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 push-lg-2">
            <div class="wt-jobalertsholder"></div>
            <div class="wt-proposalholder">
              <div class="wt-proposalhead">
                <h2>{jobDetail?.profile?.post_title || ""}</h2>
                <ul class="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                  {jobDetail?.fw_option?.length ? (
                    <li>
                      <span>
                        Job Type:{" "}
                        {jobDetail?.fw_option?.length
                          ? formatString(jobDetail?.fw_option?.[0]?.job_option)
                          : ""}
                      </span>
                    </li>
                  ) : undefined}
                  <li>
                    <span>
                      {jobDetail?.post_meta_details?.country || ""} /{" "}
                      {jobDetail?.post_meta_details?.city}
                    </span>
                  </li>
                  <li>
                    <span>
                      Type:{" "}
                      {jobDetail?.post_meta_details?._project_type &&
                        jobDetail?.post_meta_details?._project_type}
                    </span>
                  </li>
                  <li>
                    <span>
                      Level:{" "}
                      {jobDetail?.fw_option?.length
                        ? formatString(jobDetail?.fw_option[0]?.project_level)
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="wt-proposalamount-holder">
              <div class="wt-title">
                <h2>Proposal Amount</h2>
              </div>
              <div class="wt-proposalamount accordion">
                <div class="form-group">
                  <span>
                    ( <i class="fa fa-dollar-sign"></i> )
                  </span>
                  <input
                    type="number"
                    name="amount"
                    class="form-control"
                    placeholder="Enter Your Proposal Amount"
                    value={proposedAmount}
                    onChange={(e) => setProposedAmount(e.target.value)}
                  />
                  <a
                    href="javascript:void(0);"
                    class="collapsed"
                    id="headingOne"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <i class="lnr lnr-chevron-up"></i>
                  </a>
                  <em>Total amount the client will see on your proposal</em>
                </div>
                <ul
                  class="wt-totalamount collapse show"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                >
                  <li>
                    <h3>
                      ( <i class="fa fa-dollar-sign"></i> ){" "}
                      <em>
                        {amounts?.employeeAmount || 0}{" "}
                        {estimateHours
                          ? `Per hour rate (for ${estimateHours} hours)`
                          : ""}{" "}
                      </em>
                    </h3>
                    <span>
                      <strong></strong>
                      {estimateHours
                        ? "Employer’s proposed hours and hourly rate"
                        : "Employer’s proposed amount"}
                    </span>
                  </li>
                  <li>
                    <h3>
                      ( <i class="fa fa-dollar-sign"></i> ){" "}
                      <em>
                        {estimateHours
                          ? proposedAmount * estimateHours || "00.00"
                          : proposedAmount || "00.00"}
                      </em>
                    </h3>
                    <span>
                      {estimateHours
                        ? "Your proposed hourly rate"
                        : "Your Proposed Amount"}
                    </span>
                  </li>
                  {jobDetail?.post_meta_details?.usage_fee ? (
                    <li>
                      <h3>
                        ( <i class="fa fa-dollar-sign"></i> ){" "}
                        <em>{amounts?.usageFee || "00.00"}</em>
                      </h3>
                      <span>
                        Your Usage Fees
                        <i
                          class="fa fa-exclamation-circle template-content tipso_style"
                          data-tipso="This is the fee associated with the cost of “duration, location, and media type"
                        ></i>
                      </span>
                    </li>
                  ) : undefined}
                  <li>
                    <h3>
                      (<i class="fa fa-dollar-sign"></i> ){" "}
                      <em>
                        -{" "}
                        {(((estimateHours
                          ? estimateHours * Number(proposedAmount)
                          : Number(proposedAmount)) +
                          Number(amounts?.usageFee)) *
                          Number(amounts?.serviceAmountPercent)) /
                          100 || 0}
                      </em>
                    </h3>
                    <span>
                      <strong>"Book Sculp"</strong> Service Fees
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip`}>
                            {
                              "This fee is based on your subscription tier. You can upgrade your subscription tier to have a reduced fee."
                            }
                          </Tooltip>
                        }
                      >
                        <i class="fa fa-exclamation-circle template-content tipso_style"></i>
                      </OverlayTrigger>
                    </span>
                  </li>

                  <li>
                    <h3>
                      ( <i class="fa fa-dollar-sign"></i> ){" "}
                      <em>
                        {" "}
                        {(estimateHours
                          ? Number(proposedAmount) * estimateHours
                          : Number(proposedAmount)) +
                          Number(amounts?.usageFee) -
                          (((estimateHours
                            ? Number(proposedAmount) * estimateHours
                            : Number(proposedAmount)) +
                            Number(amounts?.usageFee)) *
                            Number(amounts?.serviceAmountPercent)) /
                            100 || 0}
                      </em>
                    </h3>
                    <span>
                      Amount, You’ll receive after <strong>Book Sculp</strong>{" "}
                      service fee deduction
                    </span>
                  </li>

                  <li>
                    <h3>
                      ( <i class="fa fa-dollar-sign"></i> ){" "}
                      <em>+{Number(amounts?.diemAmount) || 0}</em>
                    </h3>
                    <span>
                      Amount of per diem Provided Fee
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip`}>
                            {
                              "This is the amount provided by the client per day for personal expenses during the project. This might include meals and milage."
                            }
                          </Tooltip>
                        }
                      >
                        <i class="fa fa-exclamation-circle template-content tipso_style"></i>
                      </OverlayTrigger>
                    </span>
                  </li>

                  <li>
                    <h3>
                      ( <i class="fa fa-dollar-sign"></i> ){" "}
                      <em>
                        {Number(amounts?.diemAmount) +
                          ((estimateHours
                            ? Number(proposedAmount) * estimateHours
                            : Number(proposedAmount)) +
                            Number(amounts?.usageFee) -
                            (((estimateHours
                              ? Number(proposedAmount) * estimateHours
                              : Number(proposedAmount)) +
                              Number(amounts?.usageFee)) *
                              Number(amounts?.serviceAmountPercent)) /
                              100) || 0}
                      </em>
                    </h3>
                    <span>Grand Total</span>
                  </li>
                </ul>
              </div>
              <form class="wt-formtheme wt-formproposal">
                <fieldset>
                  {/* <div class="form-group">
                    <span class="wt-select">
                      <select>
                        <option value="1">
                          I Can Finish This Project In: 01 Months
                        </option>
                        <option value="2">
                          I Can Finish This Project In: 02 Months
                        </option>
                        <option value="3">
                          I Can Finish This Project In: 03 Months
                        </option>
                        <option value="4">
                          I Can Finish This Project In: 04 Months
                        </option>
                      </select>
                    </span>
                  </div> */}
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      placeholder="Add Description*"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="wt-attachments wt-attachmentsvtwo">
                    <div class="wt-btnarea">
                      <a onClick={handleSubmit} class="wt-btn">
                        Send Now
                      </a>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
