import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyBIsJ_xuyftAv2c3rEnhaWYrNhxZTQWpx4",
//   authDomain: "booksculp-47a10.firebaseapp.com",
//   projectId: "booksculp-47a10",
//   storageBucket: "booksculp-47a10.appspot.com",
//   messagingSenderId: "712112377807",
//   appId: "1:712112377807:web:afd8224f61733611628cba",
//   measurementId: "G-FJLJJR3DY1",
// };

const firebaseConfig = {
  apiKey: "AIzaSyC7DgkbTD-uT_KenPEeQvkJmxLMxJhsZdY",
  authDomain: "booksculp-572d3.firebaseapp.com",
  projectId: "booksculp-572d3",
  storageBucket: "booksculp-572d3.firebasestorage.app",
  messagingSenderId: "646055473905",
  appId: "1:646055473905:web:d1bce769bdfc3bcc77ef50",
  measurementId: "G-1M5GFCJQ8Y",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyARu_QhNIlexdBMIL-IxFn0ghR8sqOawII",
//   authDomain: "chatsculp-e61df.firebaseapp.com",
//   projectId: "chatsculp-e61df",
//   storageBucket: "chatsculp-e61df.appspot.com",
//   messagingSenderId: "117769981907",
//   appId: "1:117769981907:web:63d0d784ae2b8f25436926",
//   measurementId: "G-JZQ01M15E1",
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export const firebaseDB = getFirestore(app);
