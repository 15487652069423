import React, { useContext, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { Loader } from "../../Components";
import Sidebar from "../../features/firebaseChat/Sidebar";
import ChatBar from "../../features/firebaseChat/ChatBar";
import useUserStatus from "../../features/firebaseChat/useUserStatus";
import { AuthContext } from "../../Context/AuthContext";

const Chat = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  console.log("currentUser----", currentUser);
  useUserStatus();

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <section class=" chatArea">
        <div class="row">
          <div class="col-xs-12">
            <div class="wt-dashboardbox wt-messages-holder">
              <div class="wt-dashboardboxcontent wt-dashboardholder wt-offersmessages">
                <div className="homeChat">
                  <div className="container">
                    <Sidebar setLoading={setLoading} />
                    <ChatBar setLoading={setLoading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SidebarLayout>
  );
};

export default Chat;
