import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, Footer, PlansCard, BlogCard, Loader } from "../../Components";
import Carousel from "react-bootstrap/Carousel";
import PartnerLogos from "../../Assets/Images/partnerLogo.png";
import { Colors, Images } from "../../Constants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { JobCardLanding, ReviewCard } from "./Home";
import {
  getBlogReviewsList,
  getJobsList,
  getKidsList,
  getModelsList,
  sendNewsLetter,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getPackages, getUserDetail } from "../../Redux/Services/AuthServices";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";
import { routeName } from "../../Utility";
import { Signup } from "../Auth/Signup";

export const MembershipPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const loginParam = queryParams?.get("login");
  const [packagesList, setPackagesList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [tab, setTab] = useState(1);
  const [blogPage, setBlogPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewMoreLoad, setViewMoreLoad] = useState(false);
  const [searchValue, setSearchValue] = useState("Model");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);

    if (userID) {
      let body = {
        user_id: userID ? JSON?.parse(userID) : "",
      };
      let res = await dispatch(getUserDetail(body));

      if (res?.status == 200) {
        storeData(
          storageKey.USER_DATA,
          JSON.stringify(res?.results?.user_data)
        );
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
      }
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      console.log(currentSlide, "lcurenttt");
      // Check if the last slide is reached
      if (currentSlide === 16) {
        // Navigate to another screen when the last slide is reached
        // Replace the following line with your navigation logic
        console.log("Last slide reached. Navigating to another screen.");
        navigate(routeName.SEARCH_TALENT);
      }
    },
  };
  var sculpReview = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  var jobList = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    setTab(1);
    getAllModelsList();
    getAllKidsList();
    getAllJobsList();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllPackages(1);
    handleBlogList();
    handleReviewsList();
  }, []);

  useEffect(() => {
    handleLoginModal();
  }, []);

  const handleLoginModal = async () => {
    const userId = await getData(storageKey?.USER_ID);
    if (loginParam && !userId) {
      setLoginModal(true);
    }
  };

  const getAllPackages = async (type) => {
    // setLoading(true);
    let res = await dispatch(getPackages());
    if (res?.status == 200) {
      if (type == 1) {
        let data = res?.results?.filter(
          (item) =>
            item?.wt_duration_type == "monthly" ||
            item?.wt_duration_type == "month"
        );
        setPackagesList(data);
        setLoading(false);
      } else if (type == 2) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "yearly"
        );
        setPackagesList(data);
        setLoading(false);
      }
      setTab(type);
    }
  };
  const handleBlogList = async () => {
    setLoading(true);
    var body = {
      post_type: "blogs",
      page_number: "1",
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(res?.results?.post);
    }
  };

  const getMoreBlogList = async () => {
    setViewMoreLoad(true);
    var body = {
      post_type: "blogs",
      page_number: blogPage + 1,
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(blogList?.concat(res?.results?.post));
      setBlogPage(blogPage + 1);
      setViewMoreLoad(false);
    }
  };

  const handleReviewsList = async () => {
    setLoading(true);
    var body = {
      post_type: "reviews",
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setReviewsList(res?.results?.post);
    }
  };

  const getAllModelsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getModelsList(body));
    if (res?.status == 200) {
      let arr = res?.results?.user_list?.filter(
        (item) => item?.post_meta_details?.subscription_pro_id != 107
      );
      setModelsList(arr);
      setLoading(false);
    }
  };
  const getAllKidsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getKidsList(body));
    if (res?.status == 200) {
      setKidsList(res?.results?.user_list);
      setLoading(false);
    }
  };

  const getAllJobsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getJobsList(body));
    if (res?.status == 200) {
      setJobsList(res?.results?.list);
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Membership Plans</title>
      </Helmet>
      <Header />
      <section>
        <div className="homeCarouselLanding">
          <img
            src="https://booksculp.com/LIVE/uploads/0r4a2308178.jpg"
            alt="Fashion model"
            className="topBannerLanding"
          />
          <div className="captionBar">
          
            <h2> Talent, Choose the Right Plan For You!</h2>
        
          </div>
        </div>
      </section>
      <section className="packages packLanding">
       
        <div className="container">
          <div className="wt-tabs">
            <div className="tabFlex">
              <div
                className={tab !== 1 ? "active" : null}
                onClick={() => {
                  getAllPackages(1);
                }}
              >
                Monthly
              </div>
              <div
                className={tab !== 2 ? "active" : null}
                onClick={() => {
                  getAllPackages(2);
                }}
              >
                Yearly
              </div>
            </div>
            <div className="row">
              {packagesList?.map((item) => {
                return (
                  <>
                    <PlansCard cardData={item} setLoading={setLoading} />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        show={signupModal}
        onHide={() => setSignupModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Be our new member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup hideLayouts={true} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSignupModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
