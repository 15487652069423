import React from "react";
import { Header, Footer, Loader, ImageCropper } from "../../../Components";
import Accordion from "react-bootstrap/Accordion";
import { getFaqTypeList } from "../../../Redux/Services/OtherServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  ADD_BANNERS,
  ADD_BANNERS_DESCRIPTION,
  FAQ_TITLE,
} from "../../../Constants/MetaData";
import { getData, storageKey } from "../../../Utility/Storage";
import { addBanner, getBanners } from "../../../Redux/Services/AdminServices";
import { Image_URL } from "../../../Utility";
import SidebarLayout from "../../../layout/SidebarLayout";
import {
  ADD_BANNER,
  DELETE_BANNER,
  GET_BANNERS,
  UPDATE_BANNER,
} from "../../../API Services/Url";

export const AddBanners = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [topBannerCrop, setTopBannerCrop] = useState(false);

  const [topBanners, setTopBanners] = useState([]);
  const [talentBanners, setTalentBanners] = useState([]);
  const [jobPostBanners, setJobPostBanners] = useState([]);
  const [jobFeatureBanners, setJobFeatureBanners] = useState([]);

  const [allBanners, setAllBanners] = useState({
    currentBanner: {},
    bannerModal: false,
    editBanner: {},
    deleteModal: false,
    type: "",
  });

  // State for bottom banner section
  const [bottomBanners, setBottomBanners] = useState({ title: "", uri: "" });
  const [showBottomBannerModal, setShowBottomBannerModal] = useState(false);
  useEffect(() => {
    getAllTopBanners();
    getAllTalentBanners();
    getAllJobPostBanners();
    getAllJobFeatureBanners();
    // getAllBottomBanners();
  }, []);

  const getAllTopBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "top_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));

    if (res?.status == 200) {
      if (Array.isArray(res?.results)) {
        setTopBanners(res?.results);
      } else {
        setTopBanners([]);
      }
      setLoading(false);
    }
  };
  const getAllTalentBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "talent_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));
    if (res?.status == 200) {
      if (Array.isArray(res?.results)) {
        setTalentBanners(res?.results);
      } else {
        setTalentBanners([]);
      }
      setLoading(false);
    }
  };

  const getAllJobPostBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "job_post_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));

    if (res?.status == 200) {
      if (Array.isArray(res?.results)) {
        setJobPostBanners(res?.results);
      } else {
        setJobPostBanners([]);
      }
      setLoading(false);
    }
  };
  const getAllJobFeatureBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "job_feature_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));

    if (res?.status == 200) {
      if (Array.isArray(res?.results)) {
        setJobFeatureBanners(res?.results);
      } else {
        setJobFeatureBanners([]);
      }
      setLoading(false);
    }
  };

  const getAllBottomBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "bottom_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));
    if (res?.status == 200) {
      setLoading(false);
      setBottomBanners(res?.results);
    }
  };

  // Add or Edit Top Banner
  const handleTopBannerSubmit = async (data) => {
    setLoading(true);
    let uploadData = new FormData();
    uploadData?.append("top_banner", allBanners?.type);
    uploadData.append("top_banner_image[]", data);

    let res = await dispatch(addBanner(uploadData, ADD_BANNER));
    if (res?.status == 200) {
      if (allBanners?.type == "top_banner") {
        getAllTopBanners();
      } else if (allBanners?.type == "talent_banner") {
        getAllTalentBanners();
      } else if (allBanners?.type == "job_post_banner") {
        getAllJobPostBanners();
      }
      setAllBanners({
        ...allBanners,
        currentBanner: {},
        bannerModal: false,
        editBanner: "",
      });
      setSelectedFile([]);
      setLoading(false);
    }
  };

  const handleTopBannerEdit = async (data) => {
    setLoading(true);
    let uploadData = new FormData();
    uploadData?.append("image_id", allBanners?.editBanner?.id);
    uploadData?.append("top_banner", allBanners?.type);
    uploadData.append("top_banner_image", data);
    let res = await dispatch(addBanner(uploadData, UPDATE_BANNER));
    if (res?.status == 200) {
      if (allBanners?.type == "top_banner") {
        getAllTopBanners();
      } else if (allBanners?.type == "talent_banner") {
        getAllTalentBanners();
      } else if (allBanners?.type == "job_post_banner") {
        getAllJobPostBanners();
      }
      setAllBanners({
        ...allBanners,
        editBanner: {},
        currentBanner: {},
        bannerModal: false,
      });
      setSelectedFile([]);
      setLoading(false);
    }
  };

  // Edit Bottom Banner
  const handleBottomBannerSubmit = () => {
    setBottomBanners({ ...bottomBanners });
    setShowBottomBannerModal(false);
  };

  const handleTopBannerImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAllBanners({
          ...allBanners,
          currentBanner: reader.result,
        });
      };
      reader.readAsDataURL(file);
      setTopBannerCrop(true);
      setSelectedFile(file);
    }
  };

  const handleDeleteBanner = async () => {
    setLoading(true);
    let body = {
      image_id: allBanners?.currentBanner?.id,
    };
    let res = await dispatch(getBanners(body, DELETE_BANNER));
    if (res?.status == 200) {
      if (allBanners?.type == "top_banner") {
        getAllTopBanners();
      } else if (allBanners?.type == "talent_banner") {
        getAllTalentBanners();
      } else if (allBanners?.type == "job_post_banner") {
        getAllJobPostBanners();
      }
      setAllBanners({
        ...allBanners,
        currentBanner: {},
        bannerModal: false,
        editBanner: "",
        deleteModal: false,
      });
      // setShowDeleteModal();
      setLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <Container>
        <div className="container">
          <div class="wt-userprofileholder acrdList">
            <h3>Banner Manager</h3>
            <div className="row">
              <div className="col-md-12 mt-2">
                <Accordion defaultActiveKey="0">
                  <>
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        {"Top Hero Slider Banners"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {topBanners.map((banner) => (
                            <Card key={banner.id} className="banner-card">
                              <div className="image-container">
                                <Card.Img variant="top" src={banner.images} />
                                <div className="hover-buttons">
                                  <Button
                                    variant="warning"
                                    className="button"
                                    onClick={() => {
                                      setAllBanners({
                                        ...allBanners,
                                        editBanner: banner,
                                        currentBanner: banner,
                                        bannerModal: true,
                                        type: "top_banner",
                                      });
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                    {"  "}
                                    Edit
                                  </Button>
                                  <Button
                                    variant="light"
                                    className="button"
                                    onClick={() => {
                                      setAllBanners({
                                        ...allBanners,
                                        editBanner: "",
                                        currentBanner: banner,
                                        bannerModal: false,
                                        deleteModal: true,
                                        type: "top_banner",
                                      });
                                      // setShowDeleteModal(true);
                                      // setCurrentTopBanner(banner);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {"  "}
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() =>
                            setAllBanners({
                              ...allBanners,
                              bannerModal: true,
                              type: "top_banner",
                              editBanner: "",
                            })
                          }
                        ></i>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header>
                        {"Talent Slider Banners"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {talentBanners?.length != 0 &&
                            talentBanners.map((banner) => (
                              <Card key={banner.id} className="banner-card">
                                <div className="image-container">
                                  <Card.Img variant="top" src={banner.images} />
                                  <div className="hover-buttons">
                                    <Button
                                      variant="warning"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: banner,
                                          currentBanner: banner,
                                          bannerModal: true,
                                          type: "talent_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Edit
                                    </Button>
                                    <Button
                                      variant="light"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: "",
                                          currentBanner: banner,
                                          bannerModal: false,
                                          deleteModal: true,
                                          type: "talent_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {"  "}
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() =>
                            setAllBanners({
                              ...allBanners,
                              bannerModal: true,
                              type: "talent_banner",
                              editBanner: "",
                            })
                          }
                        ></i>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2}>
                      <Accordion.Header>
                        {"Job Post & Casting Call Banners"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {jobPostBanners?.length != 0 &&
                            jobPostBanners.map((banner) => (
                              <Card key={banner.id} className="banner-card">
                                <div className="image-container">
                                  <Card.Img variant="top" src={banner.images} />
                                  <div className="hover-buttons">
                                    <Button
                                      variant="warning"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: banner,
                                          currentBanner: banner,
                                          bannerModal: true,
                                          type: "job_post_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Edit
                                    </Button>
                                    <Button
                                      variant="light"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: "",
                                          currentBanner: banner,
                                          bannerModal: false,
                                          deleteModal: true,
                                          type: "job_post_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {"  "}
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() =>
                            setAllBanners({
                              ...allBanners,
                              bannerModal: true,
                              type: "job_post_banner",
                              editBanner: "",
                            })
                          }
                        ></i>
                      </div>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3}>
                      <Accordion.Header>
                        {"Job Post & Casting Call Feature Image"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {jobFeatureBanners?.length != 0 &&
                            jobFeatureBanners.map((banner) => (
                              <Card key={banner.id} className="banner-card">
                                <div className="image-container">
                                  <Card.Img variant="top" src={banner.images} />
                                  <div className="hover-buttons">
                                    <Button
                                      variant="warning"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: banner,
                                          currentBanner: banner,
                                          bannerModal: true,
                                          type: "job_feature_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Edit
                                    </Button>
                                    <Button
                                      variant="light"
                                      className="button"
                                      onClick={() => {
                                        setAllBanners({
                                          ...allBanners,
                                          editBanner: "",
                                          currentBanner: banner,
                                          bannerModal: false,
                                          deleteModal: true,
                                          type: "job_feature_banner",
                                        });
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {"  "}
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() =>
                            setAllBanners({
                              ...allBanners,
                              bannerModal: true,
                              type: "job_feature_banner",
                              editBanner: "",
                            })
                          }
                        ></i>
                      </div>
                    </Accordion.Item>
                  </>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={allBanners?.bannerModal}
          onHide={() => setAllBanners({ ...allBanners, bannerModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {allBanners?.editBanner ? "Edit Banner" : "Add New Banner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {allBanners?.editBanner ? "Update Image" : "Upload Image"}
                </Form.Label>
                {selectedFile.uri || allBanners?.editBanner?.images ? (
                  <img
                    src={selectedFile.uri || allBanners?.editBanner?.images}
                    alt="Preview"
                    className="img-thumbnail mb-3"
                  />
                ) : null}
                <Form.Control
                  type="file"
                  onChange={handleTopBannerImageUpload}
                />

                {allBanners?.currentBanner && (
                  <ImageCropper
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    imageSrc={allBanners?.currentBanner}
                    type={
                      allBanners?.type == "job_post_banner"
                        ? "job_banner" : 
                      allBanners?.type == "top_banner"
                        ? "top_banner"
                        : allBanners?.type == "job_feature_banner"
                        ? "job_feature_image"
                        : "banner"
                    }
                    show={topBannerCrop}
                    setShow={setTopBannerCrop}
                    onCrop={
                      allBanners?.editBanner?.id
                        ? handleTopBannerEdit
                        : handleTopBannerSubmit
                    }
                  />
                )}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                setAllBanners({ ...allBanners, bannerModal: false })
              }
            >
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={handleTopBannerSubmit}>
              Save
            </Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          show={allBanners?.deleteModal}
          onHide={() => setAllBanners({ ...allBanners, deleteModal: false })}
          // onHide={() => setAllBanners(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure? You want to Delete this banner?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                setAllBanners({ ...allBanners, deleteModal: false })
              }
            >
              Not Now
            </Button>
            <Button variant="primary" onClick={() => handleDeleteBanner()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Bottom Banner Modal */}
      </Container>
    </SidebarLayout>
  );
};
