import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import {
  About,
  Insights,
  ContactUs,
  Home,
  SearchJobs,
  SearchTalent,
  NotFound,
  Dashboard,
  PrivacyPolicy,
  TermsConditions,
  TalentProfile,
  Faq,
  JobDetails,
  Notifications,
  JobProposal,
  IdentityVerification,
  Redirect,
  PayoutSetting,
  StripeResponse,
  JobPost,
  PostCastingCallJob,
  ModelJobs,
  Chat,
  ClientProjects,
  Proposals,
  Packages,
  AddPortfolio,
  AddPost,
  ManagePortfolio,
  ManagePost,
  SocialDetail,
  InsightDetail,
  FollowDetails,
  SavedCollection,
  AccountSetting,
  Checkout,
  PaymentStatus,
  ManageAvailability,
  ExtraPayList,
  Listing,
  ExcelUpload,
  FaqDetail,
  ManageBlogs,
  AddBlogs,
  ManagePackages,
  AddPackages,
  ModelsList,
  ActorList,
  PartnerList,
  ClientList,
  PhotographerList,
  AddEditUser,
  AddEditClient,
  PackageSubscribers,
  ReportedUsers,
  EditAdminProfile,
  PauseDeleteAccounts,
  PartnerDetailView,
  ReportedLink,
  AllAdminJobs,
  AdminPortfolio,
  AdminPosts,
  AllTransactions,
  SculpFeed,
  AddBanners,
  LandingPage,
  MembershipPlans,
} from "./Screens/Others";
import { Signup, EditProfile, ProfileSetup } from "./Screens/Auth";
import { useEffect, useState } from "react";
import { getData, storageKey, storeData } from "./Utility/Storage";
import { routeName } from "./Utility";
import { useSelector } from "react-redux";
import { ClientProfile } from "./Screens/Others/ClientProfile";
import { Loader, ScrollToTop } from "./Components";
export const Routing = () => {
  const auth = useSelector((state) => state?.authReducer);
  const { loggedInUser } = useSelector((state) => state?.authReducer);

  const verificationData = auth?.verificationData?.verificationData;
  const [paymentData, setPaymentData] = useState({
    session_id: "",
    user_id: "",
    success: false,
    cancel: false,
    mode: "",
  });
  const [userData, setUserData] = useState({
    userId: loggedInUser?.user_data?.user_id || "",
    email: verificationData?.user_email,
    userRole: loggedInUser?.user_data?.user_role || "",
    refferID: verificationData?.user_type,
  });

  
  useEffect(() => {
    // getUserData();
    getPaymentData();
  }, []);

  useEffect(() => {
    getPaymentData();
  }, [window.location.href]);

  const getPaymentData = () => {
    const params = new URLSearchParams(window.location.search);
    setPaymentData({
      session_id: params.get("session_id"),
      user_id: params.get("userID"),
      success: params.get("success"),
      cancel: params.get("canceled"),
      mode: params.get("mode"),
    });
  };

  const getUserData = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let userRole = await getData(storageKey?.USER_ROLE);
    const params = new URLSearchParams(window.location.href);
    const refferValue = params.get("reffer");
    await storeData(storageKey?.REFFER_ID, JSON?.stringify(refferValue));
    setUserData({
      userId: userId,
      email: verificationData?.user_email,
      userRole: userRole,
      refferID: refferValue,
    });
  };

  useEffect(() => {
    if (!loggedInUser?.user_data?.user_id) {
      getUserData();
    }
  }, [loggedInUser]);

  return (
    <>
      <Loader loading={auth?.isLoading} />
      <Router>
        <ScrollToTop />
        <Routes>
          {loggedInUser?.user_data?.user_id ? (
            <>
              <Route path={routeName?.DASHBOARD} element={<Dashboard />} />
              <Route path={routeName?.EDIT_PROFILE} element={<EditProfile />} />
              <Route
                path={routeName?.ACCOUNT_SETTING}
                element={<AccountSetting />}
              />
              <Route
                path={routeName?.CLIENT_PROFILE}
                element={<ClientProfile />}
              />
              
              <Route path={`${routeName?.LIST}`} element={<Listing />} />
              <Route
                path={`${routeName?.EXCEL_UPLOAD}`}
                element={<ExcelUpload />}
              />
              <Route
                path={routeName?.NOTIFICATION}
                element={<Notifications />}
              />
              <Route
                path={routeName?.MODEL_JOBS}
                element={
                  loggedInUser?.user_data?.user_role === 11 ||
                  loggedInUser?.user_data?.user_role === 15 ||
                  loggedInUser?.user_data?.user_role === 14 ? (
                    <ModelJobs />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path={`${routeName?.PROFILE}`}
                element={<ProfileSetup />}
              />
              <Route
                path={`${routeName?.IDENTITY_VERIFY}`}
                element={<IdentityVerification />}
              />
              <Route
                path={`${routeName?.PAYOUT}`}
                element={<PayoutSetting />}
              />
              <Route
                path={`${routeName?.STRIPE}`}
                element={<StripeResponse />}
              />
              <Route
                path={`${routeName?.EXTRA_PAY_LIST}`}
                element={<ExtraPayList />}
              />
              <Route
                path={`${routeName?.CASTING_JOB}`}
                element={<PostCastingCallJob />}
              />
              <Route path={`${routeName?.CHAT}`} element={<Chat />} />
              <Route
                path={`${routeName?.CLIENT_PROJECTS}`}
                element={
                  loggedInUser?.user_data?.user_role === 12 ||
                  loggedInUser?.user_data?.user_role === 14 ? (
                    <ClientProjects />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path={`${routeName?.PROPOSALS}`} element={<Proposals />} />
              <Route path={`${routeName?.PACKAGES}`} element={<Packages />} />
              <Route
                path={`${routeName?.ADD_PORTFOLIO}`}
                element={<AddPortfolio />}
              />
              <Route path={`${routeName?.ADD_POST}`} element={<AddPost />} />
              <Route
                path={`${routeName?.MANAGE_PORTFOLIO}`}
                element={<ManagePortfolio />}
              />
              <Route
                path={`${routeName?.MANAGE_POST}`}
                element={<ManagePost />}
              />
              <Route
                path={`${routeName?.SOCIAL_DETAIL}`}
                element={<SocialDetail />}
              />
              <Route
                path={`${routeName?.FOLLOW_DETAILS}`}
                element={<FollowDetails />}
              />
              <Route
                path={`${routeName?.SAVED_COLLECTION}`}
                element={<SavedCollection />}
              />
              <Route
                path={`${routeName?.MANAGE_AVAILABILITY}`}
                element={<ManageAvailability />}
              />
              <Route path={`${routeName?.JOB_POST}`} element={<JobPost />} />
              <Route path={`${routeName?.CHECKOUT}`} element={<Checkout />} />

              {/* admin */}
              <Route
                path={`${routeName?.MODEL_LIST}`}
                element={<ModelsList />}
              />
              <Route
                path={`${routeName?.ACTOR_LIST}`}
                element={<ActorList />}
              />
              <Route
                path={`${routeName?.PHOTO_LIST}`}
                element={<PhotographerList />}
              />
              <Route
                path={`${routeName?.CLIENT_LIST}`}
                element={<ClientList />}
              />
              <Route
                path={`${routeName?.PARTNER_LIST}`}
                element={<PartnerList />}
              />
              <Route
                path={`${routeName?.ADD_USER}`}
                element={<AddEditUser />}
              />
              <Route
                path={`${routeName?.ADD_CLIENT}`}
                element={<AddEditClient />}
              />
              <Route
                path={`${routeName?.SUBSCRIBERS_LIST}`}
                element={<PackageSubscribers />}
              />
              <Route
                path={`${routeName?.REPORTED_DATA}`}
                element={<ReportedUsers />}
              />
              <Route
                path={`${routeName?.EDIT_PROFILE_ADMIN}`}
                element={<EditAdminProfile />}
              />
              <Route
                path={`${routeName?.DELETED_ACCOUNTS}`}
                element={<PauseDeleteAccounts />}
              />
              <Route
                path={`${routeName?.PARTNER_DETAIL_VIEW}`}
                element={<PartnerDetailView />}
              />
              <Route
                path={`${routeName?.ALL_ADMIN_JOBS}`}
                element={<AllAdminJobs />}
              />
              <Route
                path={`${routeName?.ADMIN_POSTS}`}
                element={<AdminPosts />}
              />
              <Route
                path={`${routeName?.ADMIN_PORTFOLIO}`}
                element={<AdminPortfolio />}
              />
              <Route
                path={`${routeName?.ALL_TRANSACTIONS}`}
                element={<AllTransactions />}
              />
            </>
          ) : null}

          <>
            <Route exact path="/" element={<Home />} />
            <Route
                path={
                  "/payment-status" ||
                  `/payment-status?canceled=true&mode=${paymentData?.mode}&userID=${paymentData?.user_id}` ||
                  `/payment-status?success=true&mode=${paymentData?.mode}&userID=${paymentData?.user_id}&session_id=${paymentData?.session_id}`
                }
                element={<PaymentStatus />}
              />
            <Route path={routeName?.ABOUT} element={<About />} />
            <Route path={routeName?.CONTACT_US} element={<ContactUs />} />
            <Route path={routeName?.INSIGHTS} element={<Insights />} />
            <Route path={routeName?.SEARCH_JOBS} element={<SearchJobs />} />
            <Route path={routeName?.SEARCH_TALENT} element={<SearchTalent />} />
            <Route path={routeName?.SCULP_FEED} element={<SculpFeed />} />
            <Route path={routeName?.LANDING_PAGE} element={<LandingPage />} />
            <Route
              path={routeName?.MEMBERSHIP_PLANS}
              element={<MembershipPlans />}
            />
            <Route
              path={routeName?.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />
            <Route
              path={routeName?.TERMS_CONTIONS}
              element={<TermsConditions />}
            />

            <Route
              path={routeName?.TALENT_PROFILE}
              element={<TalentProfile />}
            />
            <Route path={routeName?.FAQ_LIST} element={<FaqDetail />} />
            <Route path={routeName?.BANNERS} element={<AddBanners />} />
            <Route path={routeName?.MANAGE_BLOGS} element={<ManageBlogs />} />
            <Route path={routeName?.ADD_BLOGS} element={<AddBlogs />} />
            <Route
              path={routeName?.MANGE_PACKAGE}
              element={<ManagePackages />}
            />
            <Route path={routeName?.ADD_PACKAGE} element={<AddPackages />} />
            <Route path={routeName?.FAQ} element={<Faq />} />
            <Route path={routeName?.JOB_DETAILS} element={<JobDetails />} />
            <Route path={routeName?.JOB_PROPOSAL} element={<JobProposal />} />
            <Route path={`${routeName?.REGISTER}`} element={<ProfileSetup />} />
            <Route
              path={
                userData?.refferID
                  ? `/signup&reffer=${userData?.refferID}`
                  : "/signup"
              }
              element={<Signup />}
            />
            <Route path={`${routeName?.RE_DIRECT}`} element={<Redirect />} />

            <Route
              path={`${routeName?.INSIGHT_DETAIL}`}
              element={<InsightDetail />}
            />
            <Route path={`${routeName?.PROPOSALS}`} element={<Proposals />} />
            <Route path={`${routeName?.PACKAGES}`} element={<Packages />} />
            <Route
              path={`${routeName?.ADD_PORTFOLIO}`}
              element={<AddPortfolio />}
            />
            <Route path={`${routeName?.ADD_POST}`} element={<AddPost />} />
            <Route
              path={`${routeName?.MANAGE_PORTFOLIO}`}
              element={<ManagePortfolio />}
            />
            <Route
              path={`${routeName?.MANAGE_POST}`}
              element={<ManagePost />}
            />
            <Route
              path={`${routeName?.SOCIAL_DETAIL}`}
              element={<SocialDetail />}
            />
            <Route
              path={`${routeName?.FOLLOW_DETAILS}`}
              element={<FollowDetails />}
            />
            <Route
              path={`${routeName?.SAVED_COLLECTION}`}
              element={<SavedCollection />}
            />
            <Route
              path={`${routeName?.MANAGE_AVAILABILITY}`}
              element={<ManageAvailability />}
            />
            <Route
              path={`${routeName?.REPORTED_PG_LINK}`}
              element={<ReportedLink />}
            />
            {auth?.isLoading ? undefined : (
              <Route path="*" element={<NotFound />} />
            )}
          </>
        </Routes>
      </Router>
    </>
  );
};
