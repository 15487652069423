import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Footer, BlogCard, Loader } from "../../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Modal, Spinner } from "react-bootstrap";
import {
  addComment,
  deleteCommentAPi,
  getAllLikesUsers,
  getBlogReviewsList,
  getSocialPostDetails,
  getSocialPosts,
  likeDislike,
  likeUnlikeCommentApi,
  savePost,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import {
  INSIGHTS_DESCRIPTION,
  INSIGHTS_TITLE,
  SCULP_FEED_DESCRIPTION,
  SCULP_FEED_TITLE,
} from "../../Constants/MetaData";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  convertUTCToLocalTime,
  routeName,
  showToast,
  timeSince,
} from "../../Utility";

import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import { BsTruckFlatbed } from "react-icons/bs";
import { Colors, Images } from "../../Constants";
import { getData, storageKey } from "../../Utility/Storage";
import { SAVE_POST, UNSAVE_POST } from "../../API Services/Url";
export const SculpFeed = () => {
  const dispatch = useDispatch();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const [blogList, setBlogList] = useState([]);
  const [blogPage, setBlogPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewMoreLoad, setViewMoreLoad] = useState(false);
  const [updatedBlogs, setUpdatedBlogs] = useState([]);
  const [postCardModal, setPostCardModal] = useState(false);

  useEffect(() => {
    getAllPostListing();
  }, []);

  const getAllPostListing = async () => {
    setLoading(true);
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      user_id: userID,
    };
    let res = await dispatch(getSocialPosts(body));
    console.log("getAllPostListing-------", res);
    if (res?.status == 200) {
      let data = res?.results.reverse();
      let arr = res?.results?.filter(
        (item) => item?.extra?.hide_profile_status == false
      );
      // console.log("ararararar--------", arr);
      setBlogList(arr);
      setLoading(false);
    }
  };

  const getMoreBlogList = async () => {
    setViewMoreLoad(true);
    var body = {
      post_type: "blogs",
      page_number: blogPage + 1,
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(blogList?.concat(res?.results?.post));
      setBlogPage(blogPage + 1);
      setViewMoreLoad(false);
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemClick = (item) => {
    // Move clicked item to the first position in the array
    const updatedItems = [item, ...blogList.filter((i) => i !== item)];
    setUpdatedBlogs(updatedItems);
    console.log("updatedItemsupdatedItems------", updatedItems);

    // Store updated array in another state
    setSelectedItems(updatedItems);
    setPostCardModal(true);
  };

  return (
    <div>
      <Helmet>
        <title>{SCULP_FEED_TITLE}</title>
        <meta name="keywords" content={SCULP_FEED_TITLE} />
        <meta name="description" content={SCULP_FEED_DESCRIPTION} />
      </Helmet>
      <Header />
      <Loader
        loading={
          loading
            ? loading
            : other?.isLoading
            ? other?.isLoading
            : auth?.isLoading
            ? auth?.isLoading
            : false
        }
      />
      {/* <div className="pageTitle">
        <div className="wt-title">
          <h1>From The Book Sculp</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <p>Latest Feed From The Book Sculp</p>
            </li>
          </ol>
        </div>
      </div> */}
      <section className="blogsScreen">
          <div className="">
            <div className="image-grid">
              {blogList?.map((item, index) => {
                return (
                  <>
                    {/* <div className="col-md-3"></div> */}
                    {/* <div className="col-md-6 mb-5" key={index}> */}
                    <PostCard
                      key={index}
                      cardData={item}
                      refreshList={getAllPostListing}
                      postType={"social"}
                      handleItemClick={handleItemClick}
                    />
                    {/* <div className="col-md-3"></div> */}
                  </>
                );
              })}
              {viewMoreLoad ? (
                <div className="loadMore">
                  <Button variant="" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-md-12 text-center"
                    onClick={() => getMoreBlogList()}
                  >
                    {/* {!loading ? (
                      <button class="moreBlog">View More</button>
                    ) : null} */}
                  </div>
                </div>
              )}
            </div>
          </div>

        <Modal
          show={postCardModal}
          onHide={() => setPostCardModal(false)}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Similar Social Posts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {updatedBlogs?.map((item, index) => {
              return (
                <>
                  {/* <div className="col-md-3"></div> */}
                  {/* <div className="col-md-6 mb-5" key={index}> */}
                  <PostCardModal
                    key={index}
                    cardData={item}
                    refreshList={getAllPostListing}
                    postType={"social"}
                    handleItemClick={handleItemClick}
                  />
                  {/* <div className="col-md-3"></div> */}
                </>
              );
            })}
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export const PostCard = (props) => {
  const { cardData, refreshList, postType, handleItemClick } = props;
  const navigate = useNavigate();
  const [commentModal, setCommentModal] = useState(false);
  const [likesModal, setLikesModal] = useState(false);
  const [save, setSave] = useState(false);
  const [likeStatus, setLikeStatus] = useState({
    status: cardData?.extra?.like_status,
    count: cardData?.extra?.likes_count,
  });
  // console.log("cardDatacardData------", cardData);
  const dispatch = useDispatch();
  const handleLikeDislike = async (status) => {
    setLikeStatus({
      ...likeStatus,
      status: status == 0 ? 1 : 0,
      count:
        status == 0
          ? JSON.parse(likeStatus?.count) + 1
          : JSON.parse(likeStatus?.count) - 1,
    });
    const userId = await getData(storageKey?.USER_ID);
    var body = {
      type: status == 0 ? "like" : "unlike",
      user_id: userId,
      post_id: cardData?.post_details?.ID,
    };
    console.log("likeDislike body------", body);

    let res = await dispatch(likeDislike(body));
    console.log("resresresresres------", res);
    if (res?.status == 200) {
      refreshList();
    }
  };
  const handleSavePost = async (type, status) => {
    setSave(status == 0 ? 1 : 0);
    let userId = await getData(storageKey?.USER_ID);
    var body;
    if (type == "saved") {
      body = {
        user_id: userId,
        post_id: cardData?.post_details?.ID,
        type: "saved",
        post_type: "211",
      };
      let res = await dispatch(savePost(body, SAVE_POST));
      if (res?.status == 200) {
        refreshList();
      }
    } else {
      body = {
        user_id: userId,
        post_id: cardData?.post_details?.ID,
        type: "unsaved",
        post_type: "211",
      };
      let res = await dispatch(savePost(body, UNSAVE_POST));
      if (res?.status == 200) {
        refreshList();
      }
    }
  };

  const handleCardClick = async (item) => {
    handleItemClick(item);
    // let user = await getData(storageKey.USER_ID);
    // if (user) {
    //   const queryParams = new URLSearchParams({
    //     id: item?.post_details?.ID,
    //     type: "post",
    //   });
    //   navigate(`${routeName.SOCIAL_DETAIL}?${queryParams.toString()}`);
    // } else {
    //   // setLoginModalVisible(true);
    // }
    // window.scrollTo(0, 0);
  };

  const handleUserClick = async () => {
    // if (
    //   item?.post_meta_details?.user_type?.includes("freelancer") ||
    //   item?.post_meta_details?.freelancer_type?.includes("photographer") ||
    //   item?.post_meta_details?.freelancer_type === "actor" ||
    //   item?.post_meta_details?.freelancer_type?.includes("model")
    // ) {
    const queryParams = new URLSearchParams({
      id: cardData?.post_details?.post_author,
    });
    navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    // } else {
    //   const queryParams = new URLSearchParams({
    //     id: cardData?.post_details?.post_author,
    //   });
    //   navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    // }
    window.scrollTo(0, 0);
  };
  return (
    <>
      <img
        src={cardData?.media[0]?.url}
        className="d-block w-100 socilFeedImg"
        style={{
          maxHeight: "500px",
          objectFit: "cover",
        }}
        onClick={() => handleCardClick(cardData)}
      />
    </>
  );
};

export const PostCardModal = (props) => {
  const { cardData, refreshList, postType } = props;
  const navigate = useNavigate();
  const [commentModal, setCommentModal] = useState(false);
  const [likesModal, setLikesModal] = useState(false);
  const [save, setSave] = useState(false);
  const [likeStatus, setLikeStatus] = useState({
    status: cardData?.extra?.like_status,
    count: cardData?.extra?.likes_count,
  });
  const dispatch = useDispatch();

  const handleLikeDislike = async (status) => {
    setLikeStatus({
      ...likeStatus,
      status: status == 0 ? 1 : 0,
      count:
        status == 0
          ? JSON.parse(likeStatus?.count) + 1
          : JSON.parse(likeStatus?.count) - 1,
    });
    const userId = await getData(storageKey?.USER_ID);
    var body = {
      type: status == 0 ? "like" : "unlike",
      user_id: userId,
      post_id: cardData?.post_details?.ID,
    };

    let res = await dispatch(likeDislike(body));
    if (res?.status == 200) {
      refreshList();
    }
  };
  const handleSavePost = async (type, status) => {
    setSave(status == 0 ? 1 : 0);
    let userId = await getData(storageKey?.USER_ID);
    var body;
    if (type == "saved") {
      body = {
        user_id: userId,
        post_id: cardData?.post_details?.ID,
        type: "saved",
        post_type: "211",
      };
      let res = await dispatch(savePost(body, SAVE_POST));
      if (res?.status == 200) {
        refreshList();
      }
    } else {
      body = {
        user_id: userId,
        post_id: cardData?.post_details?.ID,
        type: "unsaved",
        post_type: "211",
      };
      let res = await dispatch(savePost(body, UNSAVE_POST));
      if (res?.status == 200) {
        refreshList();
      }
    }
  };

  const handleCardClick = async (item) => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      const queryParams = new URLSearchParams({
        id: item?.post_details?.ID,
        type: "post",
      });
      navigate(`${routeName.SOCIAL_DETAIL}?${queryParams.toString()}`);
    } else {
      // setLoginModalVisible(true);
    }
    window.scrollTo(0, 0);
  };

  const handleUserClick = async () => {
    const queryParams = new URLSearchParams({
      id: cardData?.post_details?.post_author,
    });
    navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div
        className="card mb-4"
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          alignContent: "center",
          border: "none",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-between p-3"
          style={{ backgroundColor: "#f8f9fa" }}
        >
          <div
            className="d-flex align-items-center"
            onClick={() => handleUserClick()}
          >
            <img
              src={cardData?.user_data?.attachment?.url}
              alt={`${cardData?.user_data?.display_name} profile`}
              className="rounded-circle"
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
                objectFit: "contain",
              }}
            />

            <h6 className="mb-0">{cardData?.user_data?.display_name}</h6>
          </div>
          <span
            className="wt-datetime"
            style={{
              color: Colors?.darkgrey,
              fontSize: "13px",
            }}
          >
            {timeSince(
              moment(cardData?.post_details?.post_date).format("MMMM DD, YYYY")
            )}
          </span>
        </div>

        <Carousel
          indicators={cardData?.media?.length > 1 ? true : null}
          slide={cardData?.media?.length > 1 ? false : null}
          controls={cardData?.media?.length > 1 ? false : null}
          fade={cardData?.media?.length > 1 ? null : null}
          interval={null}
        >
          {cardData?.media.map((item, index) => (
            <Carousel.Item key={index}>
              <>
              <img
                  src={item?.url}
                  className="bgBlurImage"
                />
                <img
                  src={item?.url}
                  className="socialPostImg"
                  alt={`Slide ${index}`}
                  style={{
                    maxHeight: "500px",
                    objectFit: "cover",
                  }}
                  onClick={() => handleCardClick(cardData)}
                />
              </>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="card-body">
          <h5 className="card-title">{cardData.title}</h5>
          <p className="card-text">{cardData.text}</p>
          <div className="d-flex justify-content-evenly">
            <ul className="wt-moreoptions">
              <li>
                <a>
                  <i
                    className={
                      likeStatus?.status == 0 ? "fa fa-heart-o" : "fa fa-heart"
                    }
                    onClick={() => handleLikeDislike(likeStatus?.status)}
                  ></i>{" "}
                  <span onClick={() => setLikesModal(true)}>
                    {` ${likeStatus?.count ? likeStatus?.count : 0} ${
                      likeStatus?.count > 1 ? "Likes" : "Like"
                    } `}
                  </span>
                </a>
              </li>
              <li onClick={() => setCommentModal(true)}>
                <a>
                  <i className="fa fa-comment-o"> </i>{" "}
                  {cardData?.extra?.comments_count
                    ? cardData?.extra?.comments_count
                    : 0}{" "}
                  Comments
                </a>
              </li>
              {/* <li>
                <i className="fa fa-eye"></i>{" "}
                {cardData?.extra?.portfolio_views
                  ? cardData?.extra?.portfolio_views
                  : 0}
                &nbsp;Views
              </li> */}
              <li
                onClick={() =>
                  save == 1
                    ? handleSavePost("unsaved", save)
                    : handleSavePost("saved", save)
                }
                style={{ color: save == 1 && Colors?.pink }}
              >
                <i
                  className={save == 1 ? "fa fa-bookmark" : "fa fa-bookmark-o"}
                ></i>{" "}
                {save == 1 ? "Saved" : "Save"}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {likesModal && (
        <LikesModal
          showModal={likesModal}
          setShowModal={setLikesModal}
          socialId={cardData?.post_details?.ID}
          type={postType}
          refreshList={refreshList}
          postId={cardData?.post_details?.ID}
        />
      )}
      {commentModal && (
        <CommentModal
          showModal={commentModal}
          setShowModal={setCommentModal}
          socialId={cardData?.post_details?.ID}
          type={postType}
          refreshList={refreshList}
          postId={cardData?.post_details?.ID}
        />
      )}
    </>
  );
};

const LikesModal = (props) => {
  const {
    showModal,
    setShowModal,
    userData,
    portId,
    socialId,
    type,
    refreshList,
    cardData,
    userID,
    postId,
  } = props;
  const [userList, setUserList] = useState("");
  const [follow, setFollow] = useState(cardData?.extra?.following_status);

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    getUsersList();
  }, []);
  // console.log("socialId-----", socialId);
  const getUsersList = async () => {
    const userId = await getData(storageKey?.USER_ID);
    var body = {
      type: "like",
      post_id: socialId,
      page: 1,
      limit: 15,
    };

    let res = await dispatch(getAllLikesUsers(body));
    console.log("userListuserList-----", res);

    if (res?.status == 200) {
      console.log("userListuserList-----", res);
      setUserList(res?.results?.like_list);
      //   setFollow(cardData?.extra?.following_status);
    }
  };

  const handleFollow = async (type) => {
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      action: type,
      user_id: userID,
      post_id: cardData?.post_details?.profile_id,
    };
    let res = await dispatch(userFollowing(body));
    if (res?.status == 200) {
      refreshList();
    }
  };
  const handleUserClick = async (item) => {
    const queryParams = new URLSearchParams({
      id: item?.user_id,
    });
    navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Who liked this post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userList.length > 0 ? (
          userList.map((item) => (
            <div key={item.id} className="mb-1">
              <div className="commentSec d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center"
                  onClick={() => handleUserClick(item)}
                >
                  <img
                    src={item?.user_image}
                    alt={`${item?.user_name} profile`}
                    className="rounded-circle m-1"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                  />
                  <h6 className="mb-0 p-2">{item?.user_name}</h6>
                  <i
                    className={"fa fa-heart"}
                    style={{
                      color: Colors?.pink,
                    }}
                  ></i>
                </div>

                {/* <i
                  onClick={() =>
                    follow == 0
                      ? handleFollow("follow")
                      : handleFollow("unfollow")
                  }
                  className={
                    follow == 0 ? "fa fa-user-minus" : "fa fa-user-plus"
                  }
                  style={{
                    color: Colors?.green,
                  }}
                >
                  {" "}
                  {follow == 0 ? "Following" : "Follow"}
                </i> */}
              </div>
            </div>
          ))
        ) : (
          <p>No Like yet. Be the first to Like!</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

const CommentModal = (props) => {
  const {
    showModal,
    setShowModal,
    userData,
    portId,
    socialId,
    type,
    refreshList,
    cardData,
    userID,
    postId,
  } = props;
  const [commentData, setCommentData] = useState("");
  const [comment, setComment] = useState("");
  const [commentID, setCommentID] = useState("");
  const [commentTo, setCommentTo] = useState("");
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);
  useEffect(() => {
    getSocialPostData();
  }, []);

  const getSocialPostData = async () => {
    const userId = await getData(storageKey?.USER_ID);
    var body = {
      social_id: socialId,
      user_id: userId,
    };
    let res = await dispatch(getSocialPostDetails(body));
    if (res?.status == 200) {
      setCommentData(res?.results[0]?.comment_result);
      //   setFollow(cardData?.extra?.following_status);
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
  const handleAddCommentOnPost = async () => {
    console.log("commentID----", commentID);
    if (!comment) {
      showToast("Please enter the comment", "error");
    } else {
      const userId = await getData(storageKey?.USER_ID);
      var body = {
        user_id: userId,
        portfolio_id: type == "social" ? socialId : portId,
        type: commentID ? "reply" : "comment",
        comment_id: commentID,
        comment: comment,
      };
      let res = await dispatch(addComment(body));
      console.log("resresres---", body);
      if (res?.status == 200) {
        if (type == "social") {
          getSocialPostData();
        } else {
          //   getPostDetails();
        }
        if (commentID) {
          handleClickOutside();
        }
        refreshList();
        setCommentID("");
        setComment("");
        setCommentTo("");
      }
    }
  };
  const handleReply = (ele, item) => {
    ele.stopPropagation();
    if (inputRef.current.focus) {
      inputRef.current.focus();
      setCommentID(item?.comment?.comment_ID);
      setCommentTo(item);
    } else {
      setCommentID("");
      setCommentTo("");
    }
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      inputRef.current?.blur();
      setCommentID("");
      setCommentTo("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserClick = async (item) => {
    const queryParams = new URLSearchParams({
      id: item?.comment?.user_id,
    });
    navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };

  const handleLikeUnlikeComment = async (type1, commentId, status) => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        user_id: partnerPermissions?.user_id || JSON.parse(userId),
        action: type1 === "like" ? "like_comment" : "unlike_comment",
        comment_id: commentId,
      };
      setLoading(true);
      let res = await dispatch(likeUnlikeCommentApi(body));
      setLoading(false);
      if (res?.status === 200) {
        getSocialPostData();
        refreshList();

        // if (type === "post") {
        //   getSocialPostData();
        // } else {
        //   // getSinglePortfolioData();
        // }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        user_id: partnerPermissions?.user_id || JSON.parse(userId),
        action: "remove",
        comment_id: commentId,
        post_id: postId,
      };
      setLoading(true);
      let res = await dispatch(deleteCommentAPi(body));
      setLoading(false);
      if (res?.status === 200) {
        getSocialPostData();
        refreshList();
        // if (type === "post") {
        //   getSocialPostData();
        // } else {
        //   // getSinglePortfolioData();
        // }
      }
    } catch (err) {
      setLoading(false);
    }
  };
  console.log("commentData-----", commentData);
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Comments</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {commentData.length > 0 ? (
          commentData.map((comment) => (
            <div key={comment.id} className="mb-3">
              <div
                className="commentSec p-3"
                style={{
                  backgroundColor:
                    comment?.comment?.comment_ID == commentID
                      ? Colors?.lightPink
                      : "#f8f9fa",
                }}
              >
                <div className="d-flex justify-content-between align-items-start">
                  <div className="d-flex align-items-start">
                    <img
                      onClick={() => handleUserClick(comment)}
                      src={
                        comment?.comment?.user_data?.attachment?.url &&
                        comment?.comment?.user_data?.delete_account == 0
                          ? comment?.comment?.user_data?.attachment?.url
                          : Images.user
                      }
                      alt={`${comment?.user_data?.display_name} profile`}
                      className="rounded-circle"
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        objectFit: "cover",
                      }}
                    />
                    <div>
                      <h6 className="mb-0">
                        {comment?.comment?.user_data?.delete_account == 0
                          ? comment?.comment?.comment_author
                          : "User"}{" "}
                      </h6>
                      <small>
                        {comment?.comment?.comment_content || "Not Available"}
                      </small>
                    </div>
                    <span
                      className="wt-datetime"
                      style={{
                        color: Colors?.darkgrey,
                        fontSize: "11px",
                        paddingInlineStart: 5,
                      }}
                    >
                      {timeSince(
                        convertUTCToLocalTime(comment?.comment?.comment_date)
                      )}
                    </span>
                  </div>
                  {comment?.comment?.user_data?.delete_account == 0 && (
                    <div className="d-flex justify-content-between">
                      <a
                        className="p-1"
                        style={{
                          fontSize: 12,
                          cursor: "pointer",
                          color:
                            comment?.comment?.like_status == "true"
                              ? Colors?.pink
                              : null,
                        }}
                        onClick={() =>
                          handleLikeUnlikeComment(
                            comment?.comment?.like_status == "true"
                              ? "unlike"
                              : "like",
                            comment?.comment?.comment_ID,
                            comment?.comment?.like_status
                          )
                        }
                      >
                        <i
                          className={
                            comment?.comment?.like_status == "true"
                              ? "fa fa-heart"
                              : "fa fa-heart-o"
                          }
                        >
                          {" "}
                        </i>{" "}
                        {`${
                          comment?.comment?.like_count
                            ? comment?.comment?.like_count
                            : 0
                        } ${
                          comment?.comment?.like_count > 1 ? " Likes" : " Like"
                        } `}{" "}
                      </a>
                      <a
                        className="p-1"
                        style={{ fontSize: 12, cursor: "pointer" }}
                        onClick={(ele) => handleReply(ele, comment)}
                      >
                        <i className="fa fa-reply"></i> Reply{" "}
                      </a>
                      {comment?.comment?.user_id ==
                        loggedInUser?.user_data?.user_id && (
                        <a
                          className="p-1"
                          style={{ fontSize: 12, cursor: "pointer" }}
                          onClick={() =>
                            handleDeleteComment(comment?.comment?.comment_ID)
                          }
                        >
                          <i className="fa fa-trash"></i> Delete{" "}
                        </a>
                      )}
                    </div>
                  )}
                </div>
                {comment?.reply.length != 0 &&
                  comment?.reply.map((item) => (
                    <div className="d-flex justify-content-between p-3 pt-1 pb-1 mt-1 replies ">
                      <div className="d-flex">
                        <img
                          src={item?.user_data?.attachment?.url}
                          alt={`${item?.user_data?.display_name} profile`}
                          className="rounded-circle"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <div>
                          <h6 className="mb-0">
                            {item?.user_data?.delete_account == 0
                              ? item?.comment_author
                              : "User"}
                          </h6>
                          <small>{item?.comment_content}</small>
                        </div>
                      </div>
                      <div className="comment-options">
                        <small
                          className="wt-datetime"
                          style={{
                            color: Colors?.darkgrey,
                            fontSize: "12px",
                          }}
                        >
                          {timeSince(convertUTCToLocalTime(item?.comment_date))}
                        </small>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <p>No comments yet. Be the first to comment!</p>
        )}
      </Modal.Body>

      <Modal.Footer>
        {commentTo && commentID ? (
          <div className="comment-user m-2 mt-0">
            <small className="comment-to" style={{ color: Colors?.white }}>
              Replying to {commentTo?.comment?.comment_author}
            </small>

            <i
              className="fa fa-times-circle p-2 comment-to-close"
              onClick={() => handleClickOutside()}
            />
          </div>
        ) : null}
        <div className="input-group">
          <input
            ref={inputRef}
            type="text"
            className="comment-input"
            placeholder="Add your comment..."
            value={comment}
            onChange={handleCommentChange}
            style={{ border: "none" }}
            onFocus={() => setIsFocused(true)}
            onBlur={handleClickOutside} // Handle blur event
          />
          <span
            className="submit-text"
            onClick={handleAddCommentOnPost}
            ref={buttonRef}
          >
            Submit
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
