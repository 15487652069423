import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Global variable to store custom scroll position
let customScrollPosition = null;

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (customScrollPosition !== null) {
      window.scrollTo({
        top: customScrollPosition,
        behavior: "smooth",
      });
      customScrollPosition = null; // Reset after scrolling
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  return null;
};

// Function to allow custom scrolling
export const scrollToPosition = (position) => {
  customScrollPosition = position;
};

// // ScrollToTop.js
// import { useLocation } from 'react-router-dom'
// import { useEffect } from 'react'

// export const ScrollToTop = (props) => {
//   const location = useLocation() // Get the current location

//   useEffect(() => {
//     // Scroll to top every time the route changes
//     window.scrollTo(0, 0)
//   }, [location]) // Only run when the location changes

//   return null // No need to render anything
// }
