import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const ToggleButton = ({ isOn, onToggle }) => {
  return (
    <button
      onClick={() => onToggle(!isOn)} 
      className={`btn btn-sm ${isOn ? "btn-success" : "btn-secondary"}`}
    >
      {isOn ?  "ON" :"OFF"}
    </button>
  );
};

export const CookiePreferencesModal = ({ showPreferences, setShowPreferences, handlePreferencesSave }) => {
  const [preferences, setPreferences] = useState({
    strictly: false, // Always enabled
    performance: false,
    functional: false,
    targeting: false,
  });

  const [functionalCookies, setFunctionalCookies] = useState(true);
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const [strictlyCookies, setStrictlyCookies] = useState(true);
  const [targetingCookies, setTargetingCookies] = useState(true);
// Function to get a cookie by name
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? JSON.parse(match[2]) : null;
  };
  useEffect(() => {
    const savedFunctional = getCookie("functionalCookies");
    const savedPerformance = getCookie("performanceCookies");
    const savedStrictly = getCookie("strictlyCookies");
    const savedTargeting = getCookie("targetingCookies");

    if (savedFunctional !== null) setFunctionalCookies(savedFunctional);
    if (savedPerformance !== null) setPerformanceCookies(savedPerformance);
    if (savedStrictly !== null) setStrictlyCookies(savedStrictly);
    if (savedTargeting !== null) setTargetingCookies(savedTargeting);
  }, []);

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${JSON.stringify(value)};expires=${expires.toUTCString()};path=/;SameSite=Lax`;
  };

  const handleConfirm = () => {
    setCookie("functionalCookies", functionalCookies, 365);
    setCookie("performanceCookies", performanceCookies, 365);
    setCookie("strictlyCookies", strictlyCookies, 365);
    setCookie("targetingCookies", targetingCookies, 365);
    setCookie("modalShown", true, 365);
    setShowPreferences(false);
  };

  const handleChangeLabel = (name, value) => {
    setPreferences((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal show={showPreferences} onHide={() => setShowPreferences(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Privacy Preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We respect your right to privacy. You can choose not to allow some types of cookies by changing the settings below. However, blocking some types of cookies may impact your experience of this website.
        </p>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg  w-96">
            <p className="text-sm text-gray-700 my-2">Manage Consent Preferences</p>
            <div className="border rounded p-2 my-2">
              <div className="d-flex justify-content-between align-items-center">
                <button 
                  className="font-semibold bg-transparent border-0"
                  onClick={() => handleChangeLabel('functional',!preferences.functional)}
                >Functional Cookies
                </button>
                <ToggleButton
                  isOn={functionalCookies}
                  onToggle={() => setFunctionalCookies(!functionalCookies)}
                />
                
              </div>
              {preferences.functional? (
              <p className="text-xs text-gray-500 mt-1">These cookies help us understand how visitors interact with our website.</p>
            ) : ''}
            </div>
            <div className="border rounded p-2 my-2">
              <div className="d-flex justify-content-between align-items-center">
              <button  className="font-semibold bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent" onClick={() => handleChangeLabel('performance',!preferences.performance)}>Performance Cookies</button>
              <ToggleButton
                  isOn={performanceCookies}
                  onToggle={() => setPerformanceCookies(!performanceCookies)}
                />
              </div>
              {preferences.performance? (
              <p className="text-xs text-gray-500 mt-1">These cookies help us understand how visitors interact with our website.</p>
            ) : ''}
            </div>

            <div className="border rounded p-2 my-2">
              <div className="d-flex justify-content-between align-items-center">
              <button  className="font-semibold bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent" onClick={() => handleChangeLabel('strictly',!preferences.strictly)}>Strictly Necessary Cookies</button>
              <p className="text-xs text-green my-2">Always Active</p>
              </div>
              {preferences.strictly ? (
                <p className="text-xs text-gray-500 mt-1">
                These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
              ) : ''}
              
            </div>
            <div className="border rounded p-2 my-2">
              <div className="d-flex justify-content-between align-items-center">
              <button  className="font-semibold bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent" onClick={() => handleChangeLabel('targeting',!preferences.targeting)}>Targeting Cookies</button>
              <ToggleButton
                  isOn={targetingCookies}
                  onToggle={() => setTargetingCookies(!targetingCookies)}
                />
              </div>
              {preferences.targeting? (
                <p className="text-xs text-gray-500 mt-1">
                These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising</p>
              ) : ''}
              
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowPreferences(false)}
        >
          Cancel
        </Button>
        <Button
         variant="primary"
          onClick={handleConfirm}
        >
          Confirm My Choices
        </Button>
      </Modal.Footer>
    </Modal>
  );
};