import React, { useEffect, useState } from "react";
import { SitePagination } from "./filtersComponents";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isValidInput } from "../Utility/validations";
import SearchBar from "./SearchBar";
import {
  Button,
  Container,
  Modal,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { routeName, showToast } from "../Utility";
import { useNavigate } from "react-router-dom";
import { Colors, Images } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { selectedTabAction } from "../Redux/Actions/AdminActions";
import moment from "moment";
import { DropdownList } from "./DropdownList";
import { modelAccountApproval } from "../Global";
import {
  allModelsListApi,
  updateTalentCategory,
} from "../Redux/Services/AdminServices";
import { getData, storageKey } from "../Utility/Storage";
import { getSearchResults } from "../Redux/Services/OtherServices";
import { saveTalentFiltersAction } from "../Redux/Actions/OtherActions";
import { AppliedFilters } from "./AppliedFilters";
import { Filter } from "./Filter";
import { Loader } from "./Loader";

const AdminTable = (props) => {
  const {
    listType,
    totalPages,
    page,
    setPage,
    onPageChange,
    noDataFound,
    tab,
    Heading,
    btnText,
    onBtnPress,
    options,
    list,
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    setDebouncedSearchTerm,
    setTab,
    handleGetFunctions,
    selectedId,
    setSelectedId,
    setIsDeleteModal,
    setIsIdentityModal,
    setIsResendModal,
    setIsIdentityViewModal,
    setList,
    setIsProfileModal,
    onEditClick,
    handleProfileView,
    from,
    setIsReportModals,
    setReportedData,
    deletePause,
    setIsResumeModal,
    part,
    setSendMailModal,
    selectedRows,
    setSelectedRows,
    role,
    totalCount,
    setTotalCount,
    isAllSelected,
    setIsAllSelected,
    // filterModal,
    // setFilterModal,
    // renderAppliedFilter,
    hasAnyFilter,
  } = props;
  console.log("lisoiosoiojsojosjsojosj=======", list);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [tabName, setTabName] = useState("");
  const [category, setCategory] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const talentFilter = useSelector((state) => state.otherReducer.talentFilter);
  const [filterModal, setFilterModal] = useState(false);
  const [modelsList, setModelsList] = useState([]);
  const [country, setCountry] = useState(talentFilter?.country || "");
  const [state, setState] = useState(talentFilter?.state || "");
  const [city, setCity] = useState(talentFilter?.city || "");
  const [mileRange, setMileRange] = useState(
    talentFilter?.searchmilescity || 0
  );
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [selectedAge, setSelectedAge] = useState(talentFilter?.age || "");
  const [selectedGender, setSelectedGender] = useState(
    talentFilter?.gender === "other" ? "Non Binary" : talentFilter?.gender || ""
  );
  const [addTitle, setAddTitle] = useState("");
  const [selectedTalentCategory, setSelectedTalentCategory] = useState("");
  const [selectedTalent, setSelectedTalent] = useState(
    talentFilter?.talent_type === "actor" && talentFilter?.user_type === "actor"
      ? "actor"
      : talentFilter?.talent_type === "talent" &&
        talentFilter?.user_type === "model"
      ? "model"
      : talentFilter?.talent_type === "child" &&
        talentFilter?.user_type === "talent"
      ? "modelKid"
      : talentFilter?.talent_type === "child" &&
        talentFilter?.user_type === "actor"
      ? "actorKid"
      : talentFilter?.user_type === "photographer"
      ? "photographer"
      : ""
  );
  const [selectedkeyword, setSelectedkeyword] = useState(
    talentFilter?.keyword || ""
  );
  const [myUsers, setMyUsers] = useState(talentFilter?.myusers || "");

  const [isSubmit, setIsSubmit] = useState(false);
  const resultsPerPage = 21;
  // let totalPages = Math.ceil(totalCount / resultsPerPage);
  const resultHpourly = talentFilter?.hourly_rate?.length
    ? talentFilter?.hourly_rate?.map((item) => {
        let label = item;
        let value = item;

        if (!item.includes("-")) {
          label = `${item}+`;
          value = `${item}+`;
        }

        return {
          label: label,
          value: value,
        };
      })
    : [];
  const [selectedFilters, setSelectedFilters] = useState({
    hair_colour: talentFilter?.hair_color?.length
      ? talentFilter?.hair_color?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    weight: talentFilter?.weight?.length
      ? talentFilter?.weight?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    ethnicities: talentFilter?.ethnicity?.length
      ? talentFilter?.ethnicity?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    height: talentFilter?.height?.length
      ? talentFilter?.height?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    language: talentFilter?.language?.length
      ? talentFilter?.language?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    english_level: talentFilter?.english_level?.length
      ? talentFilter?.english_level?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    influencers: talentFilter?.influencer?.length
      ? talentFilter?.influencer?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    hourlyRate: resultHpourly || [],
    skills: talentFilter?.skill?.length
      ? talentFilter?.skill?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    experienceLevel: talentFilter?.experience_level?.length
      ? talentFilter?.experience_level?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    projectType: "",
    projectLength: [],
    projectLocation: [],
  });

  const startRange = modelsList?.length ? (page - 1) * resultsPerPage + 1 : 0;
  const endRange = Math.min(page * resultsPerPage, totalCount);
  // const hasAnyFilter = Object.values(talentFilter || []).some(
  //   (value) =>
  //     value !== undefined &&
  //     value !== null &&
  //     value !== "" &&
  //     value?.length != 0
  // );

  const [selectedSizes, setSelectedSizes] = useState({
    shirtSize: talentFilter?.shirt_size?.length
      ? talentFilter?.shirt_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSize: talentFilter?.pant_size?.length
      ? talentFilter?.pant_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shoeSize: talentFilter?.shoe_size?.length
      ? talentFilter?.shoe_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressShirtSize: talentFilter?.dress_shirt_size?.length
      ? talentFilter?.dress_shirt_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    braCup: talentFilter?.bra_cup?.length
      ? talentFilter?.bra_cup?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    braSize: talentFilter?.bra_size?.length
      ? talentFilter?.bra_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressSize: talentFilter?.dress_size?.length
      ? talentFilter?.dress_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeL: talentFilter?.pant_size_length?.length
      ? talentFilter?.pant_size_length?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeW: talentFilter?.pant_size_waist?.length
      ? talentFilter?.pant_size_waist?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressShirtSleeve: talentFilter?.dress_shirt_sleeve?.length
      ? talentFilter?.dress_shirt_sleeve?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    neckSize: talentFilter?.neck_size?.length
      ? talentFilter?.neck_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    chestSize: talentFilter?.chest_size?.length
      ? talentFilter?.chest_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    jacket: talentFilter?.jacket?.length
      ? talentFilter?.jacket?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressSizeFemale: talentFilter?.dress_size_female?.length
      ? talentFilter?.dress_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shirtSizeFemail: talentFilter?.shirt_size_female?.length
      ? talentFilter?.shirt_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeFemale: talentFilter?.pant_size_female?.length
      ? talentFilter?.pant_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shoeSizeFemale: talentFilter?.shoe_size_female?.length
      ? talentFilter?.shoe_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
  });
  const [isSpace, setIsSpace] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const handleApplyFilter = async (pg, selectedTalentVal) => {
    console.log("tabName?.keytabName?.key---", tabName?.key);
    let user = await getData(storageKey.USER_ID);
    setLoading(true);
    var body = {
      page_number: pg || 1,
      per_page: resultsPerPage,
      user_id: user ? user : "",
      user_profile_type: tabName?.key,
    };

    if (Number(loggedInUser?.user_data?.user_role) === 14) {
      body.myusers = myUsers ? "myall" : "";
    }

    if (mileRange !== 0) {
      body.searchmilescity = mileRange;
    }

    if (selectedTalentVal) {
      body.user_type =
        selectedTalentVal === "model" || selectedTalentVal === "modelKid"
          ? "talent"
          : selectedTalentVal === "photographer"
          ? "photographer"
          : "actor";
      body.talent_type =
        selectedTalentVal === "modelKid" || selectedTalentVal === "actorKid"
          ? "child"
          : selectedTalentVal === "photographer"
          ? ""
          : selectedTalentVal;
    }

    const addIfDefined = (key, value) => {
      if (value?.length) {
        body[key] = value;
      }
    };

    addIfDefined("age", selectedAge);
    addIfDefined("talent_category", selectedTalentCategory);
    addIfDefined(
      "bra_cup",
      selectedSizes?.braCup?.map((item) => item.value)
    );
    addIfDefined(
      "bra_size",
      selectedSizes?.braSize?.map((item) => item.value)
    );
    addIfDefined("country", country);
    addIfDefined("state", state);
    addIfDefined("city", city);
    addIfDefined("searchmilescity", mileRange);

    addIfDefined(
      "dress_shirt_size",
      selectedSizes?.dressShirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_shirt_sleeve",
      selectedSizes?.dressShirtSleeve?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size",
      selectedSizes?.dressSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size_female",
      selectedSizes?.dressSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "english_level",
      selectedFilters?.english_level?.map((item) => item.value)
    );
    addIfDefined(
      "ethnicity",
      selectedFilters?.ethnicities?.map((item) => item.value)
    );
    addIfDefined(
      "gender",
      selectedGender === "Non Binary" ? "other" : selectedGender
    );
    addIfDefined(
      "hair_color",
      selectedFilters?.hair_colour?.map((item) => item.value)
    );
    addIfDefined(
      "height",
      selectedFilters?.height?.map((item) => item.value)
    );
    addIfDefined(
      "hourly_rate",
      selectedFilters?.hourlyRate?.map((item) =>
        item.value?.includes("+") ? item.value?.replace(/\+/, "") : item.value
      )
    );
    addIfDefined(
      "influencer",
      selectedFilters?.influencers?.map((item) => item.value)
    );
    addIfDefined(
      "jacket",
      selectedSizes?.jacket?.map((item) => item.value)
    );
    addIfDefined(
      "neck_size",
      selectedSizes?.neckSize?.map((item) => item.value)
    );
    addIfDefined(
      "chest_size",
      selectedSizes?.chestSize?.map((item) => item.value)
    );
    addIfDefined(
      "language",
      selectedFilters?.language?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_female",
      selectedSizes?.pantSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_length",
      selectedSizes?.pantSizeL?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_waist",
      selectedSizes?.pantSizeW?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size",
      selectedSizes?.shirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size",
      selectedSizes?.shoeSize?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size",
      selectedSizes?.pantSize?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size_female",
      selectedSizes?.shirtSizeFemail?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size_female",
      selectedSizes?.shoeSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "skill",
      selectedFilters?.skills?.map((item) => item.value)
    );
    addIfDefined(
      "experience_level",
      selectedFilters?.experienceLevel?.map((item) => item.value)
    );
    addIfDefined(
      "weight",
      selectedFilters?.weight?.map((item) => item.value)
    );
    addIfDefined("keyword", selectedkeyword);
    let res = await dispatch(getSearchResults(body));
    if (res?.status == 200) {
      dispatch(saveTalentFiltersAction(body));
      // setList
      setList(res?.results?.search);
      setFilterModal(false);
      setTotalCount(res?.results?.pagination?.total_result || 0);
      setLoading(false);
    }
    setLoading(false);
  };

  const getAllModelsList = async (pages) => {
    setLoading(true);
    let body = {
      search: debouncedSearchTerm,
      page_number: pages,
      per_page: "20",
    };
    let res = await dispatch(allModelsListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      console.log("res?.results?.pagination?.total_result======", res?.results);
      // setTotalCount(res?.results?.pagination?.total_result || 0);
    } else {
      setList([]);
    }
  };

  const handleUserClick = async (item) => {
    setLoading(true);

    dispatch(selectedTabAction(tab));
    if (
      item?.post_meta_details?.user_type?.includes("freelancer") ||
      item?.post_meta_details?.freelancer_type?.includes("photographer") ||
      item?.post_meta_details?.freelancer_type === "actor" ||
      item?.post_meta_details?.freelancer_type?.includes("model")
    ) {
      const queryParams = new URLSearchParams({
        id: item?.post_meta_details?.user_id,
      });
      navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    } else {
      const queryParams = new URLSearchParams({
        id: item?.post_meta_details?.user_id,
      });
      navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    }
    setLoading(false);
  };

  const handleRowChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, item]);
    } else {
      setSelectedRows(selectedRows.filter((n) => n !== item));
    }
  };

  const handleUpdateTalentCategory = async () => {
    setLoading(true);
    const userId = await getData(storageKey.USER_ID);
    let body = {
      talent_category: category,
      user_id:
        tab == 4
          ? selectedId?.profile?.post_author
          : selectedId?.post_meta_details?.user_id,
      action: "approved",
      super_id: userId,
    };
    let res = await dispatch(updateTalentCategory(body));
    if (res?.status == 200) {
      setCategory("");
      setCategoryModal(false);
      handleGetFunctions(tab, 1);
    }
    setLoading(false);
  };
  const renderAppliedFilter = () => {
    return (
      <>
        <div className={isSpace ? "wt-filterholder" : ""}>
          {/* {isSpace && (
            <div>
              {loading ? undefined : (
                <span>
                  {`${startRange} - ${endRange} of ${totalCount} results for`}{" "}
                  <em>"{tabName?.name}"</em>
                </span>
              )}
            </div>
          )} */}
          <AppliedFilters
            setSelectedSizes={setSelectedSizes}
            selectedSizes={selectedSizes}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setCountry={setCountry}
            country={country}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            mileRange={mileRange}
            setMileRange={setMileRange}
            getAllModelsList={getAllModelsList}
            setIsSpace={setIsSpace}
            setIsSubmit={setIsSubmit}
            isSubmit={isSubmit}
            selectedAge={selectedAge}
            setSelectedAge={setSelectedAge}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            selectedkeyword={selectedkeyword}
            setSelectedkeyword={setSelectedkeyword}
            setSelectedTalent={setSelectedTalent}
            selectedTalent={selectedTalent}
            myUsers={myUsers}
            setMyUsers={setMyUsers}
            selectedTalentCategory={selectedTalentCategory}
            setSelectedTalentCategory={setSelectedTalentCategory}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    const selectedOption = options.find((option) => option.type == 1);
    console.log("Selected Option:", selectedOption);
    setTabName(selectedOption);
  }, [tab == 1]);

  return (
    <div>
      {filterModal && (
        <div className="overlay" onClick={() => setFilterModal(false)}></div>
      )}
      <Loader loading={loading} />
      <Container>
        <div className="list_cnnt">
          <h3>{`${Heading}`}</h3>

          {from === "report" ? undefined : (
            <a class="wt-btn" onClick={onBtnPress}>
              {`${btnText}`}
            </a>
          )}
        </div>
      </Container>
      <div className="dashboard_main">
        <div className="myJobs adminData">
          <Tabs
            defaultActiveKey="posted"
            id="list"
            activeKey={tab}
            onSelect={(type) => {
              const selectedOption = options.find(
                (option) => option.type == type
              );
              console.log("Selected Option:", selectedOption);
              setTabName(selectedOption);
              console.log("setTabsetTab----", selectedOption);
              setTab(type);

              setList([]);
              setPage(1);
              handleGetFunctions(type, "1");
            }}
          >
            {options?.map((item, index) => (
              <Tab
                key={index}
                eventKey={item.type}
                title={item.name}
                accessKey={item?.name}
              >
                <div class="wt-managejobcontent">
                  <div className="cards">
                    <div className="cards_header align-items-center">
                      {list?.length ? (
                        <a
                          className="wt-btn"
                          onClick={() => {
                            if (selectedRows?.length === Number(totalCount)) {
                              setIsAllSelected(true);
                              setSendMailModal(true);
                            } else if (selectedRows?.length) {
                              setIsAllSelected(false);
                              setSendMailModal(true);
                            } else {
                              setIsAllSelected(false);
                              showToast("Select list of users first", "error");
                            }
                          }}
                        >
                          Send Mail
                        </a>
                      ) : (
                        <div />
                      )}

                      <div className="d-flex">
                        {tab == 4 || tab == 6 ? null : (
                          <a
                            className="wt-btn mx-2"
                            onClick={() => setFilterModal(true)}
                          >
                            <i className="fa fa-filter mx-1"></i>
                            Filter
                          </a>
                        )}
                        <SearchBar
                          searchTerm={searchTerm}
                          setDebouncedSearchTerm={setDebouncedSearchTerm}
                          value={searchTerm}
                          onCross={() => setSearchTerm("")}
                          onChange={(val) => {
                            if (isValidInput(val.target.value)) {
                              setSearchTerm(val.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    {renderAppliedFilter()}
                    <TableContainer className="table_container">
                      <Table>
                        <TableHead>
                          <TableRow>
                            {role === "admin" ? (
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <div>
                                      <span>Select All</span>
                                      <Checkbox
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            setSelectedRows(
                                              list.filter((el) => el)
                                            );
                                          } else {
                                            setSelectedRows([]);
                                          }
                                        }}
                                        checked={
                                          list?.length > 0 &&
                                          selectedRows.length === list?.length
                                        }
                                        inputProps={{
                                          "aria-label": "select all checkbox",
                                        }}
                                      />
                                    </div>
                                  }
                                  label=""
                                />
                              </TableCell>
                            ) : undefined}
                            <TableCell>Profile</TableCell>
                            <TableCell>Registration Date</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            {from === "report" ? (
                              <TableCell>Reported By</TableCell>
                            ) : undefined}
                            <TableCell align="center">
                              Registered From
                            </TableCell>
                            {listType == "model" && (
                              <TableCell>Talent Category</TableCell>
                            )}

                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {list?.length ? (
                          list?.map((item, i) => {
                            return (
                              <TableBody key={i}>
                                <TableRow>
                                  {role === "admin" ? (
                                    <TableCell align="center">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(event) =>
                                              handleRowChange(event, item)
                                            }
                                            checked={selectedRows.includes(
                                              item
                                            )}
                                            inputProps={{
                                              "aria-label": "row checkbox",
                                            }}
                                          />
                                        }
                                      />
                                    </TableCell>
                                  ) : undefined}
                                  <TableCell>
                                    <div
                                      onClick={() => handleUserClick(item)}
                                      className="userImgName"
                                    >
                                      <img
                                        alt="profile"
                                        src={item?.profile_image || Images.user}
                                      />
                                      {`${item?.profile?.post_title || "-"}` ||
                                        ""}
                                    </div>
                                    <div className="admAction">
                                      {Number(deletePause) === 1 ? (
                                        <span
                                          onClick={() => {
                                            setSelectedId(item);
                                            setIsDeleteModal(true);
                                          }}
                                        >
                                          Restore
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setSelectedId(item);
                                            setIsDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </span>
                                      )}
                                      <span
                                        onClick={() => {
                                          setSelectedId(item);
                                          setIsResendModal(true);
                                        }}
                                      >
                                        Reset Password
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {moment(
                                      item?.profile?.post_date,
                                      "DD-MM-YYYY HH:mm:ss"
                                    ).format("MM-DD-YYYY")}
                                    {/* {item?.profile?.post_date?.slice(0, 10) ||
                                      "-"} */}
                                  </TableCell>
                                  <TableCell>
                                    {item?.post_meta_details?.user_type ||
                                    item?.post_meta_details?.freelancer_type
                                      ? item?.post_meta_details?.user_type ===
                                          "freelancer" ||
                                        item?.post_meta_details?.user_type ===
                                          "freelancers" ||
                                        item?.post_meta_details
                                          ?.freelancer_type === "child" ||
                                        item?.post_meta_details
                                          ?.freelancer_type === "model"
                                        ? "MODEL"
                                        : item?.post_meta_details?.user_type?.toUpperCase()
                                      : item?.profile?.post_type === "employers"
                                      ? "CLIENT"
                                      : item?.profile?.post_type ===
                                        "freelancers"
                                      ? "MODEL"
                                      : item?.profile?.post_type?.toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {item?.post_meta_details?.user_email || ""}
                                  </TableCell>

                                  <TableCell>
                                    {item?.post_meta_details?.phone_number ||
                                      ""}
                                  </TableCell>
                                  <TableCell>
                                    <span className="latForm">
                                      {" "}
                                      {item?.post_meta_details?.platform ||
                                        "NA"}
                                    </span>
                                  </TableCell>
                                  {listType == "model" && (
                                    <TableCell>
                                      <span className="latForm d-flex align-items-center">
                                        {item?.post_meta_details
                                          ?.talent_category || "NA"}
                                        <i
                                          onClick={() => {
                                            setSelectedId(item);
                                            setCategoryModal(true);
                                          }}
                                          class="fa fa-edit edit-category"
                                          aria-hidden="true"
                                        ></i>
                                        {/* (
                                          <small
                                            onClick={() => {
                                              setSelectedId(item);
                                              setCategoryModal(true);
                                            }}
                                          >
                                            <b>+ Add Category</b>
                                          </small>
                                        ) */}
                                      </span>
                                    </TableCell>
                                  )}

                                  {from === "report" ? (
                                    <TableCell
                                      onClick={() => {
                                        setReportedData(item?.reported_users);
                                        setIsReportModals(true);
                                      }}
                                    >
                                      <span>
                                        {item?.reported_users?.length}
                                      </span>
                                      <i
                                        class="fa fa-user"
                                        aria-hidden="true"
                                      ></i>
                                    </TableCell>
                                  ) : undefined}

                                  <TableCell align="center">
                                    <div className="table_actions">
                                      {Number(tab) == 4 ? undefined : (
                                        <>
                                          <IconButton>
                                            <div>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-idenetity`}
                                                  >
                                                    Identity Verification
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      item?.identity
                                                        ?.attached_id?.length
                                                    ) {
                                                      setSelectedId(item);
                                                      setIsIdentityModal(true);
                                                    } else {
                                                      showToast(
                                                        "User does not add identity verification details"
                                                      );
                                                    }
                                                  }}
                                                  className={
                                                    item?.identity?.attached_id
                                                      ?.length
                                                      ? ""
                                                      : "diabled_view"
                                                  }
                                                >
                                                  {item?.post_meta_details
                                                    ?.identity_verified ===
                                                    "yes" ||
                                                  item?.post_meta_details
                                                    ?.identity_verified ===
                                                    "1" ? (
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                      color="green"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fa fa-times"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                  <i
                                                    class="fa fa-address-book-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </OverlayTrigger>

                                              {item?.identity?.attached_id
                                                ?.length ? (
                                                <p
                                                  onClick={() => {
                                                    setSelectedId(item);
                                                    setIsIdentityViewModal(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  View
                                                </p>
                                              ) : (
                                                <p></p>
                                              )}
                                            </div>
                                          </IconButton>

                                          <IconButton>
                                            <div>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-Application`}
                                                  >
                                                    Application Verification
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    setSelectedId(item);
                                                    setIsProfileModal(true);
                                                  }}
                                                  className="actionicons"
                                                >
                                                  {item?.post_meta_details
                                                    ?.profile_approval ===
                                                  "yes" ? (
                                                    <i
                                                      class="fa fa-check"
                                                      aria-hidden="true"
                                                      color="green"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fa fa-times"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                  <i
                                                    class="fa fa-address-book-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </OverlayTrigger>
                                              <p
                                                onClick={() => {
                                                  dispatch(
                                                    selectedTabAction(tab)
                                                  );
                                                  if (handleProfileView) {
                                                    handleProfileView(
                                                      item?.post_meta_details
                                                        ?.user_id
                                                    );
                                                  } else {
                                                    const queryParams =
                                                      new URLSearchParams({
                                                        id: item
                                                          ?.post_meta_details
                                                          ?.user_id,
                                                      });
                                                    navigate(
                                                      `${
                                                        routeName.TALENT_PROFILE
                                                      }?${queryParams.toString()}`
                                                    );
                                                  }
                                                }}
                                              >
                                                Profile
                                              </p>
                                            </div>
                                          </IconButton>
                                        </>
                                      )}
                                      {part === "email" ? (
                                        <IconButton
                                          onClick={() => {
                                            setSelectedId(item);
                                            setSendMailModal(true);
                                          }}
                                        >
                                          <div>
                                            <i
                                              class="fa fa-envelope"
                                              aria-hidden="true"
                                              color="#00000"
                                            ></i>
                                            <p>Email</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      {Number(deletePause) === 2 ? (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              setSelectedId(item);
                                              setIsResumeModal(true);
                                            }}
                                          >
                                            <div>
                                              <i
                                                class="fa fa-play-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <p>Resume</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      {item?.profile?.post_type ===
                                      "partners" ? (
                                        <IconButton>
                                          <div
                                            onClick={() => {
                                              dispatch(selectedTabAction(tab));
                                              const queryParams =
                                                new URLSearchParams({
                                                  id: item?.post_meta_details
                                                    ?.user_id,
                                                });
                                              navigate(
                                                `${
                                                  routeName.PARTNER_DETAIL_VIEW
                                                }?${queryParams.toString()}`
                                              );
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-idenetity`}
                                                >
                                                  View Details
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <i
                                                  class="fa fa-file-text"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </OverlayTrigger>

                                            <p>Details</p>
                                          </div>
                                        </IconButton>
                                      ) : undefined}
                                      <IconButton>
                                        <div
                                          onClick={() => {
                                            onEditClick(
                                              item?.post_meta_details?.user_id
                                            );
                                          }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-profile`}>
                                                Edit Profile
                                              </Tooltip>
                                            }
                                          >
                                            <i
                                              class="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                            ></i>
                                          </OverlayTrigger>

                                          <p>Edit</p>
                                        </div>
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })
                        ) : (
                          <TableBody>
                            <TableCell
                              align="center"
                              colSpan={10}
                              sx={{ color: "black" }}
                            >
                              {noDataFound}
                            </TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>

                    <div className="pg_vew">
                      {totalPages > 1 ? (
                        <SitePagination
                          module={list}
                          page={page}
                          onPageChange={onPageChange}
                          totalPages={totalPages}
                          setPage={setPage}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
          {categoryModal && (
            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={categoryModal}
              onHide={() => {
                setCategoryModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Talent Category
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DropdownList
                  title="Model Category"
                  placeholder="Model Category"
                  options={modelAccountApproval}
                  value={category}
                  setValue={setCategory}
                />
              </Modal.Body>
              <Modal.Footer className="footer-center">
                <Button
                  onClick={() => {
                    handleUpdateTalentCategory();
                  }}
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    setCategoryModal(false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
      {filterModal ? (
        <div className={`slider ${filterModal ? "open" : ""}`}>
          {/* <i
            onClick={() => setFilterModal(false)}
            className="fa fa-close filter-close-btn"
          ></i> */}
          <Filter
            listType={listType}
            showFilter={filterModal}
            setShowFilter={setFilterModal}
            setSelectedSizes={setSelectedSizes}
            selectedSizes={selectedSizes}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setCountry={setCountry}
            country={country}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            mileRange={mileRange}
            setMileRange={setMileRange}
            handleApplyFilter={handleApplyFilter}
            setPage={setPage}
            setIsSubmit={setIsSubmit}
            selectedAge={selectedAge}
            setSelectedAge={setSelectedAge}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            selectedkeyword={selectedkeyword}
            setSelectedkeyword={setSelectedkeyword}
            setSelectedTalent={setSelectedTalent}
            selectedTalent={selectedTalent}
            myUsers={myUsers}
            setMyUsers={setMyUsers}
            userType="admin"
            selectedTalentCategory={selectedTalentCategory}
            setSelectedTalentCategory={setSelectedTalentCategory}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default AdminTable;
