import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer, Header, Loader, PlansCard } from "../../Components";
import { Button, Container, Form, ProgressBar } from "react-bootstrap";
import {
  ActorAudition,
  BasicDetails,
  OtherDetails,
  PersonalDetail,
  SocialMediaHandle,
  UserImages,
} from "../../features/profile";
import {
  getOptionsData,
  getPackages,
  getUserDetail,
  photographerRegister,
  profileSetup,
  removeImage,
  updatePhotographerProfile,
  uploadGalleryProfile,
  userRegister,
  verifyOtpApi,
} from "../../Redux/Services/AuthServices";
import { PARTNER_REGISTER, USER_REGISTER } from "../../API Services/Url";
import moment from "moment";
import {
  addFirebaseUid,
  getCountryList,
} from "../../Redux/Services/OtherServices";
import { FIREBASE_KEY, getRefferID, routeName, showToast } from "../../Utility";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import {
  loggedInUsedAction,
  loginStepUserData,
} from "../../Redux/Actions/AuthActions";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../Utility/firebase";
import { StripeCardModal, VerifyOtp } from "../../Components/Popups";
import { Helmet } from "react-helmet";
import {
  PROFILE_SETUP_DESCRIPTION,
  PROFILE_SETUP_TITLE,
} from "../../Constants/MetaData";
import Bowser from "bowser";
import { CheckCircle, Circle } from "lucide-react";
import { Colors } from "../../Constants";

export const ProfileSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const optionsType = useSelector((state) => state.authReducer.allOptionData);
  const loading = useSelector((state) => state?.otherReducer);
  const currentLocation = useSelector(
    (state) => state?.authReducer?.currentLocation
  );
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const stepsForm = ["Personal Info", "Address", "Payment", "Review"];

  const data = params?.state?.data;
  const queryParams = new URLSearchParams(params.search);
  const user_email = queryParams.get("email");
  const user_type = queryParams.get("type");
  const referal = queryParams.get("reffer");
  const [currentStep, setCurrentStep] = useState(0);
  const [formProgress, setFormProgress] = useState(0);
  const [load, setLoad] = useState(false);
  const [talent, setTalent] = useState(user_type);
  const [headerLoad, setHeaderLoad] = useState(false);
  const [relationStatus, setRelationStatus] = useState("");
  const [legal, setLegal] = useState(false);
  const [agree, setAgree] = useState(false);
  const [steps, setSteps] = useState(0);
  const [validated, setValidated] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editSecStep, setEditSecStep] = useState(false);
  const [editImgSection, setEditImgSection] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(user_email);
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("United States");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [expertise, setExpertise] = useState("");
  const [experience, setExperience] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const [waistImageData, setWaistImageData] = useState(null);
  const [cropped, setCropped] = useState(false);
  const [galleryCropper, setGalleryCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [waistImage, setWaistImage] = useState("");
  const [shoulderUpImage, setShoulderUpImage] = useState("");
  const [shoulderUpImageData, setShoulderUpImageData] = useState("");
  const [happy, setHappy] = useState("");
  const [happyData, setHappyData] = useState("");
  const [sad, setSad] = useState("");
  const [sadData, setSadData] = useState("");
  const [angry, setangry] = useState("");
  const [AngryData, setAngryData] = useState("");
  const [shoulderDownImage, setShoulderDownImage] = useState("");
  const [shoulderDownImageData, setShoulderDownImageData] = useState("");
  const [childrenCount, setChildrenCount] = useState("");
  const [auditions, setAuditions] = useState({
    occupation: "",
    threeThings: "",
    showOn: "",
    realityShow: "",
  });
  const [tiktokFollowers, setTiktokFollowers] = useState("");
  const [vimeoFollowers, setVimeoFollowers] = useState("");
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [mySkill, setMySkill] = useState("");
  const [customSkills, setCustomSkills] = useState("");
  const [awards, setAwards] = useState("");
  const [tattooLoc, setTattoLoc] = useState("");
  const [memberOf, setMemberOf] = useState("");
  const [educationTraining, setEducationTraining] = useState("");
  const [theaterExp, setTheaterExp] = useState("");
  const [commercialExp, setCommercialExp] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [reelLink, setReelLink] = useState("");
  const [language, setLanguage] = useState([]);
  const [englishLevel, setEnglishLevel] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [pantSize, setPantSize] = useState("");
  const [pantSizeW, setPantSizeW] = useState("");
  const [pantSizeL, setPantSizeL] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [shirtSizeF, setShirtSizeF] = useState("");
  const [dressShirt, setDressShirt] = useState("");
  const [shirtSleeve, setShirtSleeve] = useState("");
  const [neckSize, setNeckSize] = useState("");
  const [chestSize, setChestSize] = useState("");
  const [jacket, setJacket] = useState("");
  const [braCup, setBraCup] = useState("");
  const [braSize, setBraSize] = useState("");
  const [dressSize, setDressSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [shoeSizeF, setShoeSizeF] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [shirtSizeC, setShirtSizeC] = useState("");
  const [pantSizeWC, setPantSizeWC] = useState("");
  const [dressSizeC, setDressSizeC] = useState("");
  const [shoeSizeC, setShoeSizeC] = useState("");
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [clientDetails, setClientDetails] = useState({
    organization: "",
    title: "",
    businessEmail: "",
    businessWebsite: "",
    businessSince: "",
    description: "",
  });
  const [basicDetails, setBasicDetails] = useState({
    fname: "",
    lname: "",
    mobileNumber: "",
    birthDate: "",
    age: "",
    displayName: "",
    hourlyRate:
      user_type === "Actor" || user_type === "Model"
        ? 150
        : user_type === "Actor Kid" || user_type === "Model Kid"
        ? 75
        : "",
    fullRate: "",
    halfRate: "",
    tagLine: "",
    profilePhoto: "",
    guardianName: "",
    yearsExperience: "",
    cameraType: "",
    retouchConcent: "",
    listedFor: "",
    customExpertise: "",
  });
  const [address, setAddress] = useState({
    addressLine: "",
    addressLine2: "",
    postalCode: "",
  });
  const [socialLinks, setSocialLinks] = useState({
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    vimeo: "",
    tikTok: "",
  });
  const [bioDetails, setBioDetails] = useState({
    professionalBio: "",
    personalBio: "",
  });
  const [userData, setUserData] = useState("");
  const [cityOptions, setCityOptions] = useState("");
  const [countryOptions, setCountryOptions] = useState("");
  const [stateOptions, setStateOptions] = useState("");
  const [isImageInfoModal, setIsImageInfoModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [isVerifyModal, setIsVerifyModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(179);
  const [packagesList, setPackagesList] = useState([]);
  const [tab, setTab] = useState(1);
  const [browser, setBrowser] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [stepCount, setStepCount] = useState([]);

  const handlePhoneVerify = async (val) => {
    if (
      (val === "verify" && otp?.length < 6) ||
      (val === "otp_verify" && otp?.length < 6)
    ) {
      showToast("Please enter a valid OTP", "error");
      return;
    }
    try {
      let userId = await getData(storageKey?.USER_ID);
      let body = {
        user_id: userId ? JSON.parse(userId) : "",
        action: val === "send" || val === "resend" ? "send" : "otp_verify",
        country_code: phoneCode,
        phone: basicDetails?.mobileNumber,
        verify_otp: val === "send" || val === "resend" ? "" : otp,
      };
      let res = await dispatch(verifyOtpApi(body));

      if (res?.status === 200) {
        setCountDown(179);
        if (val == "otp_verify") {
          setIsVerifyModal(!isVerifyModal);
        }
      }
    } catch (err) {
      console.log("Verify mobile number error", err);
    }
  };

  const getAllCountryName = async (type) => {
    var body = {
      country: "",
      state: "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (type == "country") {
        data = res?.results;
        setCountryOptions(data);
      }
    }
  };

  const getAllStateName = async () => {
    const selectedOptionObject = countryOptions?.length
      ? countryOptions?.find(
          (option) => option.value === country || option.name === country
        )
      : {};
    var body = {
      country: selectedOptionObject?.id || "",
      state: "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setStateOptions(data);
      } else {
        setStateOptions([]);
      }
    }
  };

  const getAllCityName = async (countryId, stateId) => {
    const selectedCountry =
      !countryId && countryOptions?.length
        ? countryOptions?.find(
            (option) => option.value === country || option.name === country
          )
        : {};
    const selectedState =
      !stateId && stateOptions?.length
        ? stateOptions?.find(
            (option) => option.value === state || option.name === state
          )
        : {};
    var body = {
      country: countryId || selectedCountry?.id || "",
      state: stateId || selectedState?.id || "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (res?.results?.length) {
        data = res?.results?.length ? JSON?.parse(res?.results) : [];
        setCityOptions(data);
      } else {
      }
    }
  };

  const getAllOptionsData = async () => {
    await dispatch(getOptionsData());
  };

  useEffect(() => {
    const browserInfo = Bowser.getParser(window.navigator.userAgent);
    setBrowser(browserInfo.getBrowserName());
    setDeviceType(browserInfo.getPlatformType());
  }, []);

  const handleForm = async (event) => {
    setError(true);
    event.preventDefault();
    if (
      talent === "Actor" ||
      talent === "Model" ||
      talent === "Model Kid" ||
      talent === "Actor Kid"
    ) {
      if (!basicDetails?.birthDate) {
        showToast("Date of birth is required", "error");
        return;
      }
      if (!gender) {
        showToast("Gender is required", "error");
        return;
      }
    }
    if (!basicDetails?.fname) {
      showToast("First Name is required", "error");
      return;
    }
    if (!basicDetails?.lname) {
      showToast("Last Name is required", "error");
      return;
    }
    if (!basicDetails?.displayName) {
      showToast("Display Name is required", "error");
      return;
    }

    if (!basicDetails?.mobileNumber) {
      showToast("Mobile number is required", "error");
      return;
    }
    if (
      !basicDetails?.guardianName &&
      (talent === "Model Kid" || talent === "Actor Kid")
    ) {
      showToast("Guardian Name is required", "error");
      return;
    }

    if (talent === "Photographer") {
      if (!basicDetails?.hourlyRate) {
        showToast("Hourly Rate is required", "error");
        return;
      }
      if (!basicDetails?.halfRate) {
        showToast("Half Day Rate is required", "error");
        return;
      }
      if (!basicDetails?.fullRate) {
        showToast("Full Day Rate is required", "error");
        return;
      }
      if (!country) {
        showToast("Country is required", "error");
        return;
      }
      if (stateOptions?.length && !state) {
        showToast("State is required", "error");
        return;
      }
      if (cityOptions?.length && !city) {
        showToast("City is required", "error");
        return;
      }
      if (!address?.addressLine) {
        showToast("Address is required", "error");
        return;
      }
      if (!address?.postalCode) {
        showToast("Postal Code is required", "error");
        return;
      }
      if (!bioDetails?.professionalBio) {
        showToast("Professional Bio is required", "error");
        return;
      }
      if (!bioDetails?.personalBio) {
        showToast("Personal Bio is required", "error");
        return;
      }
      if (!expertise?.length) {
        showToast("Expertise are required", "error");
        return;
      }
      if (!experience) {
        showToast("Experience level is required", "error");
        return;
      }
      if (!basicDetails?.yearsExperience) {
        showToast("Years of experience is required", "error");
        return;
      }
      // if (!mySkill?.length) {
      //   showToast("Skills are required", "error");
      //   return;
      // }
    }

    if (steps > 1) {
      if (talent == "Client" || talent === "client" || talent == "Partners") {
        if (!clientDetails?.organization) {
          showToast("Organisation is required", "error");
          return;
        }
        if (!clientDetails?.title) {
          showToast("Title is required", "error");
          return;
        }
        if (!clientDetails?.businessEmail) {
          showToast("Business Email is required", "error");
          return;
        }
        if (!clientDetails?.businessWebsite) {
          showToast("Business Website is required", "error");
          return;
        }
        if (!clientDetails?.description) {
          showToast("Description is required", "error");
          return;
        }
      }

      if (
        talent === "Actor" ||
        talent === "Model" ||
        talent === "Model Kid" ||
        talent === "Actor Kid"
      ) {
        if (!basicDetails?.hourlyRate) {
          showToast("Hourly Rate is required", "error");
          return;
        }
        if (!country) {
          showToast("Country is required", "error");
          return;
        }
        if (stateOptions?.length && !state) {
          showToast("State is required", "error");
          return;
        }
        if (cityOptions?.length && !city) {
          showToast("City is required", "error");
          return;
        }
        if (!address?.addressLine) {
          showToast("Address is required", "error");
          return;
        }
        if (!address?.postalCode) {
          showToast("Postal Code is required", "error");
          return;
        }
        if (!language?.length) {
          showToast("Language is required", "error");
          return;
        }
        if (!englishLevel) {
          showToast("English Level is required", "error");
          return;
        }
        if (!englishLevel) {
          showToast("English Level is required", "error");
          return;
        }
        if (!bioDetails?.professionalBio) {
          showToast("Professional Bio is required", "error");
          return;
        }
        if (!bioDetails?.personalBio) {
          showToast("Personal Bio is required", "error");
          return;
        }
        if (!height) {
          showToast("Height is required", "error");
          return;
        }
        if (!weight) {
          showToast("Weight is required", "error");
          return;
        }
        if (!ethnicity?.length) {
          showToast("Ethnicity is required", "error");
          return;
        }
        if (!hairColor) {
          showToast("Hair Colour is required", "error");
          return;
        }
        if (!eyeColor) {
          showToast("Eye Colour is required", "error");
          return;
        }
        if (!experience) {
          showToast("Experience is required", "error");
          return;
        }
        // if (!mySkill?.length) {
        //   showToast("Skills are required", "error");
        //   return;
        // }
        if (
          basicDetails?.age <= 13 ||
          basicDetails?.age?.includes("Month") ||
          basicDetails?.age?.includes("M")
        ) {
          if (!shirtSizeC?.length) {
            showToast("Shirt size is required", "error");
            return;
          }
          if (!pantSizeWC?.length) {
            showToast("Pant size is required", "error");
            return;
          }
          if (!shoeSizeC?.length) {
            showToast("Shoe size is required", "error");
            return;
          }
          if (
            !dressSizeC?.length &&
            (gender == "Female" || gender == "female")
          ) {
            showToast("Dress size is required", "error");
            return;
          }
        }
        if (basicDetails?.age > 13 && (gender == "Male" || gender == "male")) {
          if (!shirtSize?.length) {
            showToast("Shirt size is required", "error");
            return;
          }
          if (!pantSizeW?.length) {
            showToast("Pant size(waist) is required", "error");
            return;
          }
          if (!pantSizeL?.length) {
            showToast("Pant size(length) is required", "error");
            return;
          }
          if (!shoeSize?.length) {
            showToast("Shoe size is required", "error");
            return;
          }
          // if (!dressShirt) {
          //   showToast("Dress shirt size is required", "error");
          //   return;
          // }
          // if (!shirtSleeve) {
          //   showToast("Dress Shirt Sleeve is required", "error");
          //   return;
          // }
          // if (!neckSize) {
          //   showToast("Neck Size is required", "error");
          //   return;
          // }
          // if (!chestSize) {
          //   showToast("Chest Size is required", "error");
          //   return;
          // }
          // if (!jacket) {
          //   showToast("Jacket Size is required", "error");
          //   return;
          // }
        }
        if (
          basicDetails?.age > 13 &&
          (gender == "Female" || gender == "female")
        ) {
          if (!shirtSizeF?.length) {
            showToast("Shirt size is required", "error");
            return;
          }
          if (!pantSize?.length) {
            showToast("Pant size is required", "error");
            return;
          }
          if (!braCup) {
            showToast("Bra cup is required", "error");
            return;
          }
          if (!braSize) {
            showToast("Bra size is required", "error");
            return;
          }
          if (!dressSize) {
            showToast("Dress Size is required", "error");
            return;
          }
          if (!shoeSizeF?.length) {
            showToast("Shoe size is required", "error");
            return;
          }
        }
      }
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (steps == 0 && talent == "Photographer") {
        handlePhotographerRegisteration();
      } else if (steps == 0 && talent == "Partners") {
        handlePatnerRegisteration();
      } else if (steps == 1) {
        userRegistration();
      } else if (steps == 2) {
        handleProfileSetup();
      } else {
        handleProfileSetup();
      }
      setError(false);
    }
  };

  const userRegistration = async () => {
    setError(true);
    let refferId = await getRefferID();
    var body = {
      reffer: referal || refferId,
      user_type:
        talent == "Model" ||
        talent == "model" ||
        talent == "Model Kid" ||
        talent == "kid"
          ? "freelancer"
          : talent === "Actor" || talent === "Actor Kid"
          ? "actor"
          : "employer",
      model_type:
        talent == "Model"
          ? "model"
          : talent == "Model Kid"
          ? "child"
          : talent === "Actor Kid"
          ? "child"
          : talent === "Actor"
          ? "actor"
          : "",
      email: email,
      dob: moment(basicDetails?.birthDate).format("YYYY-MM-DD"),
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      display_name: basicDetails?.displayName,
      gender:
        gender == "Male" || gender == "male"
          ? "male"
          : gender == "Female" || gender == "female"
          ? "female"
          : gender == "Non Binary" ||
            gender == "non binary" ||
            gender == "other"
          ? "other"
          : "",
      password: password,
      organization: clientDetails?.organization,
      mobile: basicDetails?.mobileNumber,
      country_code: phoneCode,
      terms: agree,
      platform: `${deviceType} (${browser})` || "Web",
    };

    if (talent === "Actor Kid" || talent === "Model Kid") {
      body.gardian_name = basicDetails?.guardianName;
      body.gardian_concent = legal;
    }

    if (talent === "Actor") {
      body.relationship_status = relationStatus;
    }

    let res = await dispatch(userRegister(body, USER_REGISTER));
    if (res?.status == 200) {
      let data = res?.results?.[0];
      storeData(storageKey.USER_STEP_DATA, data);
      storeData(storageKey.USER_ID, data?.user_id);
      dispatch(loginStepUserData(data || null));
      setUserData(data);
      // setPhoneCode(data?.country_code);
      setBasicDetails({
        ...basicDetails,
        age: data?.age_months || data?.model_age,
      });
      setError(false);
      setSteps(2);
      setFormProgress(25);
      setValidated(false);
      window.scrollTo(0, 0);

      setEditDetail(false);
      setIsVerifyModal(true);
    }
  };

  const handlePhotographerRegisteration = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let refferId = await getRefferID();

    setError(true);
    var body = {
      reffer: referal || refferId,
      user_type: "photographer",
      pre: "new",
      user_id: userId ? JSON.parse(userId) : "",
      profile_id: profileId ? JSON.parse(profileId) : "",
      per_website: "",
      email: email,
      model_type:
        talent == "Model" ? "model" : talent == "Model Kid" ? "child" : "",
      password: password,
      address_line1: address?.addressLine,
      avatar:
        "https://booksculp.com/dev/wp-content/uploads/workreap-temp/00002-2.jpg",
      country_code: phoneCode,
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      display_name: basicDetails?.displayName,

      gender:
        gender == "Male" || gender == "male"
          ? "male"
          : gender == "Female" || gender == "female"
          ? "female"
          : gender == "Non Binary" ||
            gender == "non binary" ||
            gender == "other"
          ? "other"
          : "",
      country: country,
      state: state,
      city: city,

      skills: mySkill,
      custom_skills: customSkills,
      list_awards: awards,
      dob: moment(basicDetails?.birthDate).format("YYYY-MM-DD"),
      tageline: basicDetails?.tagLine,

      expertise: expertise,
      custom_expertise: basicDetails?.customExpertise,
      experiences_level: experience,

      full_day_rate: basicDetails?.fullRate,
      half_day_rate: basicDetails?.halfRate,
      hourly_rate: basicDetails?.hourlyRate,

      mobile: basicDetails?.mobileNumber,
      pack_status: "basic",

      personal_bio: bioDetails?.personalBio,
      professional_bio: bioDetails?.professionalBio,
      postal_code: address?.postalCode,
      years_experience: basicDetails?.yearsExperience,
      camera_type: "dxsdxcsc",
      retouch_concent: "cdscdsc",
      listed_with_booksculp: "cdscsc",

      facebook_followers: facebookFollowers,
      facebook_link: socialLinks?.facebook,

      instagram_followers: instagramFollowers,
      instagram_link: socialLinks?.instagram,

      youtube_followers: youtubeFollowers,
      youtube_link: socialLinks?.youtube,

      twitter_followers: twitterFollowers,
      twitter_link: socialLinks?.twitter,

      vimeo_followers: vimeoFollowers,
      vimeo_link: socialLinks?.vimeo,

      tiktok_followers: tiktokFollowers,
      tiktok_link: socialLinks?.tikTok,
      platform: `Web (${browser})` || "Web",
    };
    let res = {};
    if (userId) {
      res = await dispatch(updatePhotographerProfile(body));
    } else {
      res = await dispatch(photographerRegister(body));
    }
    if (res?.status == 200) {
      setError(false);
      setValidated(false);
      setEditSecStep(false);
      setSteps(3);
      setFormProgress(75);

      if (!userId) {
        setIsVerifyModal(true);
      }
      window.scrollTo(0, 0);
    }
  };

  const handleChooseGallery = async (event) => {
    const files = event.target.files;
    const file = files?.[0];
    if (files?.length != 0) {
      if (file && file.type.startsWith("image/")) {
        const newDetails = Array.from(files).map((file) => ({
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        }));

        let newArr = [...(images || []), ...newDetails];
        if (newArr?.length > 10) {
          alert(
            "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
          );
          
        } else {
          setSelectedFiles((prevFileDetails) => [
            ...(prevFileDetails || []),
            ...newDetails,
          ]);
          setIsImageInfoModal(false);
          setImageType("");
          setGalleryCropper(true);
        }
      } else {
        showToast("Please select a valid image", "error");
      }
    }
  };

  const registerLoginApi = async (uid, loginId) => {
    try {
      let body = {
        user_id: loginId,
        chat_udi: uid,
        register_by: "self",
      };

      let response = await dispatch(addFirebaseUid(body));

      if (response?.status === 200) {
        const queryParams = new URLSearchParams({
          applicationType: "new",
        });
        showToast("Application Submitted Successfully !", "success");
        navigate(`${routeName.DASHBOARD}?${queryParams.toString()}`);
        // navigate(routeName?.DASHBOARD);
        setHeaderLoad(true);
        setTimeout(() => {
          setHeaderLoad(false);
        }, 3000);

        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error, "erro 1");
    }
  };

  const handleFirebaseRegister = async (loginId) => {
    const displayName = `${basicDetails?.fname || ""} ${
      basicDetails?.lname || ""
    }`;

    try {
      //Create user
      const res = await createUserWithEmailAndPassword(
        auth,
        email,
        FIREBASE_KEY
      );

      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        displayName,
        email: email,
        photoURL: profileImage,
      });
      await setDoc(doc(db, "userChats", res.user.uid), {});

      registerLoginApi(res?.user?.uid, loginId);
    } catch (err) {
      const queryParams = new URLSearchParams({
        "application-type": "new",
      });
      showToast("Application Submitted Successfully !", "success");
      navigate(`${routeName.DASHBOARD}?${queryParams.toString()}`);
      // navigate(routeName?.DASHBOARD);
      setHeaderLoad(true);
      setTimeout(() => {
        setHeaderLoad(false);
      }, 3000);

      window.scrollTo(0, 0);
      console.log(err, "error--");
    }
  };

  const handleNext = async () => {
    setLoad(true);
    if (!profileImage) {
      showToast("Please upload your profile photo", "error");
    } else if (
      talent != "Client" &&
      talent != "Partners" &&
      images?.length >= 0 &&
      images?.length < 3
    ) {
      alert(
        "You have to upload Minimum 3 Images or Maximum 10 Images in Gallery Section ."
      );
    } else {
      // setTimeout(() => {
      //   setLoad(false);
      //   setSteps(4);
      //   window.scrollTo(0, 20);
      // }, 2000);

      let uploadData = new FormData();
      let userId = await getData(storageKey?.USER_ID);
      let profileId = await getData(storageKey?.PROFILE_ID);

      uploadData.append("upload_type", "next");
      uploadData.append("user_id", JSON.parse(userId));
      uploadData.append("profile_id", JSON?.parse(profileId));

      let res = await dispatch(uploadGalleryProfile(uploadData));
      if (res?.status == 200) {
        handleFirebaseRegister(JSON.parse(userId));
      }
    }
  };

  const handleSubmit = async () => {
    // if (!loggedInUser?.user_data?.subscription_pro_id) {
    //   showToast("Please choose a subscription", "error");
    // } else {

    let uploadData = new FormData();
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);

    uploadData.append("upload_type", "next");
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));

    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      handleFirebaseRegister(JSON.parse(userId));
    }
    // }
  };
  const removeGalleryImage = async (eachImage, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);

    var body = {
      user_id: JSON.parse(userId),
      profile_id: JSON.parse(profileId),
      image_type: type,
      image_id:
        type == "gallery"
          ? eachImage?.attachment_id
          : type === "waist_up"
          ? waistImageData?.attachment_id
          : type === "shoulders_up_straight"
          ? shoulderUpImageData?.attachment_id
          : type === "shoulders_up_turn"
          ? shoulderDownImageData?.attachment_id
          : type === "happy"
          ? happyData?.attachment_id
          : type === "sad"
          ? sadData?.attachment_id
          : type === "angry"
          ? AngryData?.attachment_id
          : profileImage?.attachment_id,
      action: "remove",
    };
    let res = await dispatch(removeImage(body));
    if (res?.status == 200) {
      getUserImages();
    }
    if (type == "profile") {
      setProfileImage("");
    } else if (type === "waist_up") {
      setWaistImageData("");
    } else if (type === "shoulders_up_straight") {
      setShoulderUpImageData("");
    } else if (type === "shoulders_up_turn") {
      setShoulderDownImageData("");
    } else if (type === "happy") {
      setHappyData("");
    } else if (type === "sad") {
      setSadData("");
    } else if (type === "angry") {
      setAngryData("");
    }
  };

  const getUserImages = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
        let data =
          res?.results?.images_gallery &&
          res?.results?.images_gallery?.length != 0
            ? res?.results?.images_gallery
            : [];
        res?.results?.profile_image && res?.results?.profile_image?.length != 0
          ? setProfileImage({
              ...profileImage,
              uri: res?.results?.profile_image[0]?.guid,
            })
          : setProfileImage("");
        data?.length != 0 ? setImages(data) : setImages([]);
        setUserData(res.results);

        let user_Data = res?.results?.user_data;
        let company_details = res?.results?.company_details;
        let post_meta_details = res?.results?.post_meta_details;
        let social_followers = res?.results?.social_followers;
        let measurment = res?.results?.measurment;
        if (user_Data?.completed_step == "1") {
          setSteps(2);
          setFormProgress(25);
        } else if (user_Data?.completed_step == "2") {
          setSteps(3);
          setFormProgress(75);
        }
        setPhoneCode(user_Data?.country_code || "+1");
        setRelationStatus(res?.results?.user_data?.relationship_status || "");
        setChildrenCount(post_meta_details?.have_children || "");
        setAuditions({
          occupation: post_meta_details?.occupation || "",
          threeThings: post_meta_details?.about_you_nobody_knows || "",
          showOn: post_meta_details?.reality_show_yourself_why || "",
          realityShow: post_meta_details?.reality_show_what_when || "",
        });
        setExpertise(res?.results?.personal_details?.expertise);
        storeData(storageKey?.COUNTRY_ID, post_meta_details?.country_id);
        storeData(storageKey?.STATE_ID, post_meta_details?.state_id);
        setShoulderUpImageData(
          res?.results?.fw_option?.[0]?.shoulders_up_straight
            ? res?.results?.fw_option?.[0]?.shoulders_up_straight
            : ""
        );
        setAngryData(
          res?.results?.fw_option?.[0]?.angry
            ? res?.results?.fw_option?.[0]?.angry
            : ""
        );
        setHappyData(
          res?.results?.fw_option?.[0]?.happy
            ? res?.results?.fw_option?.[0]?.happy
            : ""
        );
        setSadData(
          res?.results?.fw_option?.[0]?.sad
            ? res?.results?.fw_option?.[0]?.sad
            : ""
        );
        setWaistImageData(
          res?.results?.fw_option?.[0]?.waist_up
            ? res?.results?.fw_option?.[0]?.waist_up
            : ""
        );
        setShoulderDownImageData(
          res?.results?.fw_option?.[0]?.shoulders_up_turn
            ? res?.results?.fw_option?.[0]?.shoulders_up_turn
            : ""
        );
        setPhoneCode(res?.results?.user_data?.country_code);
        setBasicDetails({
          ...basicDetails,
          fname: user_Data?.first_name,
          lname: user_Data?.last_name,
          mobileNumber: res?.results?.fw_option[0]?.user_phone_number,
          birthDate: post_meta_details?.dob
            ? post_meta_details?.dob
            : res?.results?.personal_details?.date_of_birth
            ? res?.results?.personal_details?.date_of_birth
            : new Date(),
          age:
            post_meta_details?.age != "00"
              ? post_meta_details?.age
              : res?.results?.extra?.age_months
              ? res?.results?.extra?.age_months
              : "",
          model_age: post_meta_details?.age,
          displayName: user_Data?.display_name,
          hourlyRate:
            post_meta_details?.perhour_rate ||
            res?.results?.personal_details?.perhour_rate,
          tagLine: res?.results?.fw_option[0]?.tag_line
            ? res?.results?.fw_option[0]?.tag_line
            : res?.results?.personal_details?.tag_line
            ? res?.results?.personal_details?.tag_line
            : post_meta_details?.tag_line,
          guardianName: res?.results?.guardian_detail?.guardian_name,
          customExpertise: res?.results?.personal_details?.custom_expertise,
          yearsExperience: res?.results?.personal_details?.years_experience,
          fullRate: res?.results?.personal_details?.full_day_rate,
          halfRate: res?.results?.personal_details?.half_day_rate,
        });
        setPassword(steps == 2 || steps == 3 ? "*********" : "");
        setEyeColor(post_meta_details?.eye_color);
        setExperience(
          post_meta_details?.experience_level ||
            res?.results?.personal_details?.experience_level
        );
        setLanguage(
          post_meta_details?.languages?.map((item) => {
            return {
              value: item?.name,
              label: item?.name,
            };
          })
        );
        setCountry(post_meta_details?.country);
        setState(post_meta_details?.state);
        setCity(post_meta_details?.city);
        setAddress({
          ...address,
          addressLine:
            post_meta_details?.address_line || post_meta_details?.address,
          addressLine2: post_meta_details?.address_line_2,
          postalCode: post_meta_details?.postal_code,
        });
        if (
          user_Data?.gender == "male" ||
          res?.results?.personal_details?.gender === "male"
        ) {
          setGender("Male");
        } else if (
          user_Data?.gender == "female" ||
          res?.results?.personal_details?.gender === "female"
        ) {
          setGender("Female");
        } else if (
          user_Data?.gender == "other" ||
          res?.results?.personal_details?.gender === "other"
        ) {
          setGender("Non Binary");
        }
        setEnglishLevel(post_meta_details?.english_level);
        setEthnicity(post_meta_details?.ethnicity);
        setHairColor(post_meta_details?.hair_colour);
        setHeight(post_meta_details?.height);
        setWeight(post_meta_details?.weight);
        setNeckSize(measurment?.neck_size);
        setBioDetails({
          ...bioDetails,
          personalBio:
            post_meta_details?.personal_bio ||
            res?.results?.personal_details?.personal_bio,
          professionalBio:
            post_meta_details?.professional_bio ||
            res?.results?.personal_details?.professional_bio,
        });
        setMySkill(post_meta_details?.skills_names);
        setCustomSkills(post_meta_details?.custom_skills);
        setTattoLoc(post_meta_details?.tattoo_location || "");
        setTheaterExp(post_meta_details?.theater_exp || "");
        setEducationTraining(post_meta_details?.edu_training || "");
        setAwards(post_meta_details?.list_awards || "");
        setCommercialExp(post_meta_details?.comm_exp || "");
        setMemberOf(post_meta_details?.member_of || "");
        setVideoLink(post_meta_details?.video_link || "");

        // follow details
        setSocialLinks({
          ...socialLinks,
          facebook: social_followers?.facebook_profile_link,
          instagram: social_followers?.instagram_profile_link,
          tikTok: social_followers?.tiktok_profile_link
            ? social_followers?.tiktok_profile_link
            : social_followers?.ticktok_profile_link,
          youtube: social_followers?.youtube_profile_link,
          twitter: social_followers?.twitter_profile_link,
          vimeo: social_followers?.vimeo_profile_link,
          website: post_meta_details?.personal_website,
        });
        setFacebookFollowers(social_followers?.facebook_follower);
        setInstagramFollowers(social_followers?.instagram_follower);
        setYoutubeFollowers(social_followers?.youtube_follower);
        setTwitterFollowers(social_followers?.twitter_follower);
        setTiktokFollowers(social_followers?.tiktok_follower);
        setVimeoFollowers(social_followers?.vimeo_follower);
        // measurement
        if (
          user_Data?.gender == "male" ||
          user_Data?.gender == "female" ||
          user_Data?.gender == "Male" ||
          user_Data?.gender == "Female" ||
          user_Data?.gender == "non binary" ||
          user_Data?.gender == "other"
        ) {
          if (measurment) {
            setShirtSize(
              measurment?.shirt_size
                ? measurment?.shirt_size
                : measurment?.shirt_size_m
            );
            setPantSizeW(measurment?.pant_size_waist);
            setPantSizeL(measurment?.pant_size_length);
            setJacket(measurment?.jacket);
            setChestSize(measurment?.chest_size);
            setDressShirt(measurment?.dress_shirt_size);
            setShirtSleeve(measurment?.dress_shirt_sleeve);
            setShoeSize(
              measurment?.shoe_size
                ? measurment?.shoe_size
                : measurment?.shoe_size_m
            );
            setShoeSizeF(
              measurment?.shoe_size_f
                ? measurment?.shoe_size_f
                : measurment?.shoe_size
            );
            setShirtSizeF(
              measurment?.shirt_size
                ? measurment?.shirt_size
                : measurment?.shirt_size_f
            );
            setBraCup(measurment?.bra_cup);
            setBraSize(measurment?.bra_size);
            setDressSize(
              user_Data?.gender !== "male" || user_Data?.gender !== "Male"
                ? measurment?.dress_size?.map((item) => item.value)
                : measurment?.dress_size
            );
            setPantSize(
              measurment?.pant_size
                ? measurment?.pant_size
                : measurment?.pant_size_f
            );
          }
        }

        if (measurment) {
          setShirtSizeC(measurment?.shirt_size);
          setPantSizeWC(
            measurment?.pant_size
              ? measurment?.pant_size
              : measurment?.pant_size_waist
              ? measurment?.pant_size_waist
              : measurment?.pant_size_length
          );
          setDressSizeC(measurment?.dress_size);

          setShoeSizeC(measurment?.shoe_size);
          setPantSize(
            measurment?.pant_size
              ? measurment?.pant_size
              : measurment?.pant_size_waist
              ? measurment?.pant_size_waist
              : measurment?.pant_size_length
          );
        }

        // child
        if (
          post_meta_details?.freelancer_type == "child" ||
          post_meta_details?.freelancer_type == "Kid" ||
          post_meta_details?.freelancer_type == "kid"
        ) {
          if (user_Data?.gender == "male") {
            setGender("Male");
          } else if (user_Data?.gender == "female") {
            setGender("Female");
          } else if (user_Data?.gender == "other") {
            setGender("Non Binary");
          }
          if (measurment) {
            setShirtSizeC(measurment?.shirt_size);
            setPantSizeWC(
              measurment?.pant_size
                ? measurment?.pant_size
                : measurment?.pant_size_waist
                ? measurment?.pant_size_waist
                : measurment?.pant_size_length
            );
            setDressSizeC(measurment?.dress_size);
            setShoeSizeC(measurment?.shoe_size);
            setPantSize(
              measurment?.pant_size
                ? measurment?.pant_size
                : measurment?.pant_size_waist
                ? measurment?.pant_size_waist
                : measurment?.pant_size_length
            );
          }
        }

        if (
          res?.results?.user_data?.user_role == 12 ||
          res?.results?.user_data?.user_role == 14
        ) {
          setBasicDetails({
            ...basicDetails,
            fname: user_Data?.first_name,
            lname: user_Data?.last_name,
            mobileNumber: res?.results?.fw_option[0]?.user_phone_number,
            tagLine: company_details?.tag_line,
            displayName: user_Data?.display_name,
            age:
              post_meta_details?.age != "00"
                ? post_meta_details?.age
                : res?.results?.extra?.age_months
                ? res?.results?.extra?.age_months
                : "",
          });
          if (res?.results?.fw_option?.[0]?.gender == "male") {
            setGender("Male");
          } else if (res?.results?.fw_option?.[0]?.gender == "female") {
            setGender("Female");
          } else if (res?.results?.fw_option?.[0]?.gender == "other") {
            setGender("Non Binary");
          }
          setClientDetails({
            ...clientDetails,
            organization: company_details?.organization,
            title: company_details?.title,
            businessEmail: company_details?.bussiness_email_address,
            businessWebsite: company_details?.bussiness_website,
            businessSince: company_details?.bussiness_since,
            description: company_details?.description,
          });
          // setPhoneCode(res?.status?.user_data?.country_code)
          setCountry(post_meta_details?.country);
          setState(post_meta_details?.state);
          setCity(post_meta_details?.city);
          setAddress({
            ...address,
            addressLine: post_meta_details?.address,
            addressLine2: post_meta_details?.address_line_2,
            postalCode: post_meta_details?.postal_code,
          });
        }
      }
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      if (file && file.type.startsWith("image/")) {
        // uploadProfilePhoto(file, "profile");
        const detail = {
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        };
        setSelectedFile(detail);
        setIsImageInfoModal(false);
        setImageType("");
        setCropped(true);
      } else {
        showToast("Please select a valid image", "error");
      }
    } else {
      setProfileImage(null);
    }
  };

  const handleChooseImages = (event, type) => {
    const file = event.target.files[0];
    if (type === "waist") {
      setWaistImage(event.target.files[0]);
    } else if (type === "shoulderUp") {
      setShoulderUpImage(event.target.files[0]);
    } else if (type === "shoulderDown") {
      setShoulderDownImage(event.target.files[0]);
    } else if (type === "happy") {
      setHappy(event.target.files[0]);
    } else if (type === "sad") {
      setSad(event.target.files[0]);
    } else if (type === "angry") {
      setangry(event.target.files[0]);
    }
    if (file) {
      if (file && file.type.startsWith("image/")) {
        const detail = {
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        };
        if (type === "waist") {
          setWaistImage(detail);
        } else if (type === "shoulderUp") {
          setShoulderUpImage(detail);
        } else if (type === "shoulderDown") {
          setShoulderDownImage(detail);
        } else if (type === "happy") {
          setHappy(detail);
        } else if (type === "sad") {
          setSad(detail);
        } else if (type === "angry") {
          setangry(detail);
        }
      } else {
        showToast("Please select a valid image", "error");
      }
      setIsImageInfoModal(false);
      setImageType("");
      setCropped(true);
    } else {
      if (type === "waist") {
        setWaistImageData(null);
      } else if (type === "shoulderUp") {
        setShoulderUpImageData(null);
      } else if (type === "shoulderDown") {
        setShoulderDownImageData(null);
      } else if (type === "happy") {
        setHappyData(null);
      } else if (type === "sad") {
        setSadData(null);
      } else if (type === "angry") {
        setAngryData(null);
      }
    }
  };

  const uploadProfilePhoto = async (data, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    uploadData.append("upload_type", "profile");
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setFormProgress(85);
      setCropped(false);
      setSelectedFile([]);

      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
      });
    }
  };

  const uploadWaistphoto = async (data, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    uploadData.append("upload_type", type);
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);

      if (type === "waist_up") {
        setWaistImage([]);
        setWaistImageData({
          ...waistImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_straight") {
        setShoulderUpImage([]);
        setShoulderUpImageData({
          ...shoulderUpImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_turn") {
        setShoulderDownImage([]);
        setShoulderDownImageData({
          ...shoulderDownImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "happy") {
        setHappy([]);
        setHappyData({
          ...happyData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "sad") {
        setSad([]);
        setSadData({
          ...sadData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "angry") {
        setangry([]);
        setAngryData({
          ...AngryData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      }
    }
  };

  const handleGallerySubmit = async (data) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    uploadData.append("upload_type", "gallery");
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    Array.from(data).map((file, index) => {
      uploadData.append("file_type" + "[" + [index] + "]", file);
    });
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setImages(res.results);
      setSelectedFiles([]);
      setFormProgress(95);
      // setSteps(4);
    }
  };

  const handleProfileSetup = async () => {
    if (talent == "Photographer") {
      handlePhotographerRegisteration();
    } else if (talent == "Partners") {
      handlePatnerRegisteration();
    } else {
      handleModelRegistration();
    }
  };

  const handlePatnerRegisteration = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    setError(true);
    let refferId = await getRefferID();
    let body = {
      reffer: referal || refferId,
      pre: "new",
      user_type: talent == "Partners" ? "partners" : "",
      email: email,
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      password: password,
      country_code: phoneCode,
      display_name: basicDetails?.displayName,
      organization: clientDetails?.organization,
      terms: agree,
      mobile: basicDetails?.mobileNumber,
      tageline: basicDetails?.tagLine,
      country: country,
      state: state,
      city: city,
      address_line1: address?.addressLine,
      address_line2: address?.addressLine2,
      postal_code: address?.postalCode,
      facebook_link: socialLinks?.facebook,
      instagram_link: socialLinks?.instagram,
      twitter_link: socialLinks?.twitter,
      youtube_link: socialLinks?.youtube,
      vimeo_link: socialLinks?.vimeo,
      tiktok_link: socialLinks?.tikTok,
      user_id: userId ? JSON.parse(userId) : "",
      profile_id: profileId ? JSON.parse(profileId) : "",
      title: clientDetails?.title,
      business_email: clientDetails?.businessEmail,
      business_website: clientDetails?.businessWebsite,
      business_since: clientDetails?.businessSince,
      description: clientDetails?.description,
      platform: `Web (${browser})` || "Web",
    };

    let res = await dispatch(userRegister(body, PARTNER_REGISTER));
    if (res?.status == 200) {
      setEditSecStep(false);
      setError(false);
      setValidated(false);
      setSteps(3);
      setFormProgress(75);
      window.scrollTo(0, 0);
      setIsVerifyModal(true);
      setEditDetail(false);
    }
  };

  const handleModelRegistration = async () => {
    setError(true);
    let refferId = await getRefferID();
    let body = {
      reffer: referal || refferId,
      user_id: userData?.user_data?.user_id
        ? userData?.user_data?.user_id
        : userData?.user_id,
      profile_id: userData?.user_data?.profile_id || userData?.profile_id,
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      display_name: basicDetails?.displayName,
      phone_number: basicDetails?.mobileNumber,
      country_code: phoneCode,
      hourly_rate: basicDetails?.hourlyRate,
      tageline: basicDetails?.tagLine,
      country: country,
      state: state,
      city: city,
      address_line1: address?.addressLine,
      address_line2: address?.addressLine2,
      postal_code: address?.postalCode,
      languages: language?.length
        ? language?.map(({ value, label }) => ({
            name: label,
            id: value,
          }))
        : [],
      english: englishLevel,
      talent_type: talent === "Actor" ? "Actor" : "model",
      professional_bio: bioDetails?.professionalBio,
      personal_bio: bioDetails?.personalBio,
      height_inch: height,
      weight_pound: weight,
      ethnicity: ethnicity,
      hair_color: hairColor,
      experience_level: experience,
      // following detail
      facebook_link: socialLinks?.facebook,
      instagram_link: socialLinks?.instagram,
      twitter_link: socialLinks?.twitter,
      youtube_link: socialLinks?.youtube,
      vimeo_link: socialLinks?.vimeo,
      tiktok_link: socialLinks?.tikTok,
      facebook_followers: facebookFollowers,
      instagram_followers: instagramFollowers,
      twitter_followers: twitterFollowers,
      youtube_followers: youtubeFollowers,
      vimeo_followers: vimeoFollowers,
      tiktok_followers: tiktokFollowers,
      // measurement
      shirt_size: shirtSize,
      pant_size_waist: pantSizeW,
      pant_size_length: pantSizeL,
      shoe_size: shoeSize,
      dress_shirt_size: dressShirt,
      dress_shirt_sleeve: shirtSleeve,
      neck_size: neckSize,
      chest_size: chestSize,
      jacket: jacket,
      shirt_size_f: shirtSizeF,
      pant_size_f: pantSize,
      bra_cup: braCup,
      bra_size: braSize,
      dress_size_f: dressSize,
      shoe_size_f: shoeSizeF,
      skills: mySkill || [],
      custom_skills: customSkills,
      list_awards: awards,
      // kid Registeration
      eye_color: eyeColor,
      gardian_name: basicDetails?.guardianName,
      gender:
        gender == "Male" || gender == "male"
          ? "male"
          : gender == "Female" || gender == "female"
          ? "female"
          : gender == "non binary" ||
            gender == "Non Binary" ||
            gender == "other" ||
            gender == "Other"
          ? "other"
          : "",
      children_shirt_size: shirtSizeC,
      children_pant_size: pantSizeWC,
      children_shoe_size: shoeSizeC,
      children_dress_size: dressSizeC,

      toddler_shirt_size: shirtSizeC,
      toddler_pant_size: pantSizeWC,
      toddler_shoe_size: shoeSizeC,
      toddler_dress_size: dressSizeC,

      infant_shirt_size: shirtSizeC,
      infant_dress_size: dressSizeC,
      infant_pant_size: pantSizeWC,
      infant_shoe_size: shoeSizeC,

      // Client
      title: clientDetails?.title,
      business_Email: clientDetails?.businessEmail,
      business_website: clientDetails?.businessWebsite,
      business_since: clientDetails?.businessSince,
      description: clientDetails?.description,

      tattoo_location: tattooLoc,
      member_of: memberOf,
      organization: clientDetails?.organization,
      theater_exp: theaterExp,
      edu_training: educationTraining,
      comm_exp: commercialExp,
      video_link: videoLink,
      // link_to_reel: reelLink,
      personal_website: socialLinks?.website,
    };
    if (talent === "Actor") {
      body.relationship_status = relationStatus || "";
      body.have_children = childrenCount || "";
      body.occupation = auditions?.occupation || "";
      body.about_you_nobody_knows = auditions?.threeThings || "";
      body.reality_show_yourself_why = auditions?.showOn || "";
      body.reality_show_what_when = auditions?.realityShow || "";
    }
    let res = await dispatch(profileSetup(body));
    if (res?.status == 200) {
      setEditSecStep(false);
      showToast("Saved !", "success");
      setError(false);
      setValidated(false);
      setSteps(3);
      setFormProgress(75);

      window.scrollTo(0, 0);
    }
  };

  const getUserData = () => {
    setUserData(data);
    // setPhoneCode(data?.country_code);
    setBasicDetails({
      ...basicDetails,
      fname: data?.first_name,
      lname: data?.last_name,
      displayName: data?.display_name,
      age: data?.age_months ? data?.age_months : data?.model_age,
      model_age: data?.model_age,
      birthDate: data?.dob ? new Date(data?.dob) : new Date(),
      mobileNumber: data?.mobile,
      guardianName: data?.gardian_name,
      hourlyRate:
        data?.model_type == "Kid" || data?.model_type == "child" ? "75" : "100",
    });
    if (data?.gender == "male") {
      setGender("Male");
    } else if (data?.gender == "female") {
      setGender("Female");
    } else if (data?.gender == "other") {
      setGender("Non Binary");
    }
    setClientDetails({
      ...clientDetails,
      organization: data?.organization,
    });
  };

  useEffect(() => {
    getUserImages();
    if (data) {
      getUserData();
      if (data?.completed_step == "1") {
        setSteps(2);
        setFormProgress(25);
      } else if (data?.completed_step == "2") {
        setSteps(3);
        setFormProgress(75);
      }
    } else if (talent == "Photographer" || talent == "Partners") {
      setSteps(0);
    } else {
      setSteps(1);
    }
    setTalent(user_type);
    getAllCountryName("country", 1);
  }, []);

  useEffect(() => {
    if (country && countryOptions?.length) {
      getAllStateName();
    }
  }, [country, countryOptions]);

  useEffect(() => {
    if (currentLocation) {
      setPhoneCode(currentLocation?.phonecode || "+1");
      setCountry(currentLocation?.country || "");
    }
  }, [currentLocation]);

  useEffect(() => {
    if (state && stateOptions?.length) {
      getAllCityName();
    }
  }, [state, stateOptions]);

  useEffect(() => {
    if (optionsType === "error") {
      getAllOptionsData();
    }
  }, [optionsType]);

  const stepsBar = {
    talentCount: [1, 2, 3],
    otherCount: [1, 2],
  };
  useEffect(() => {
    if (loggedInUser?.user_data?.user_role == 11) {
      setStepCount(stepsBar?.talentCount);
    } else if (loggedInUser?.user_data?.user_role == 13) {
     
      setStepCount([]);
    } else {
      setStepCount(stepsBar?.otherCount);
    }
  }, []);
  useEffect(() => {
    getAllPackages(1);
  }, []);

  const getAllPackages = async (type) => {
    let res = await dispatch(getPackages());
    if (res?.status == 200) {
      if (type == 1) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "monthly"
        );
        setPackagesList(data);
      } else if (type == 2) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "yearly"
        );
        setPackagesList(data);
      }
      setTab(type);
    }
  };
  // console.log("loggedInUser----", loggedInUser);

  return (
    <>
      <Helmet>
        <title>{PROFILE_SETUP_TITLE}</title>
        <meta name="keywords" content={PROFILE_SETUP_TITLE} />
        <meta name="description" content={PROFILE_SETUP_DESCRIPTION} />
      </Helmet>
      <Loader loading={loading?.isLoading || load} />
      <Header load={headerLoad} />

      <div className="pageTitle">
        <div className="wt-title">
          <h1>Register</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Register</li>
          </ol>
        </div>
      </div>

      <Container>
        <div className="registerForm full-size">
          <div className="verification_contain_form wt-email-cont">
            {/* <div class="progress-container">
              {console.log(" steps - 1 == item-------", steps)}
              {stepCount?.length != 0 &&
                stepCount?.map((item, index) => {
                  return (
                    <>
                      <div
                        class="progress-line"
                        style={{
                          backgroundColor:
                            item >= steps ? Colors?.green : Colors?.grey,
                        }}
                      ></div>

                      <div
                        class={`step ${
                          item <= steps
                            ? "completed"
                            : item == steps
                            ? "active"
                            : null
                        }`}
                      >
                        <div class="step-circle">{item}</div> 
                      </div>
                    </>
                  );
                })}

              
            </div> */}
            {formProgress != 0 && (
              <ProgressBar
                animated
                style={{
                  backgroundColor: Colors?.lightBlue,
                }}
                now={formProgress}
                label={`Profile Setup completed ${formProgress}%`}
                className="mb-5"
              />
            )}

            {steps == 4 ? (
              <>
                <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
                  {loggedInUser?.user_data?.user_role == 12 ||
                  loggedInUser?.user_data?.user_role == 14 ? undefined : (
                    <section className="packages">
                      <div className="container">
                        <h3 className="titleCenter">
                          Select Your Subscription
                        </h3>

                        <div className="wt-tabs">
                          <div className="tabFlex">
                            <div
                              className={tab !== 2 ? "active" : null}
                              onClick={() => {
                                getAllPackages(1);
                              }}
                            >
                              Monthly
                            </div>
                            <div
                              className={tab !== 1 ? "active" : null}
                              onClick={() => {
                                getAllPackages(2);
                              }}
                            >
                              Yearly
                            </div>
                          </div>
                          <div className="row">
                            {packagesList?.map((item) => {
                              return (
                                <>
                                  <PlansCard
                                    cardData={item}
                                    setLoading={false}
                                    getAllPackages={getAllPackages}
                                    register={true}
                                    handleSubmit={handleSubmit}
                                  />
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
                <div className="packTable">
                  <Button
                    variant="wt-btn"
                    class="wt-btn hide_org"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <>
                <BasicDetails
                  talent={talent}
                  setTalent={setTalent}
                  steps={steps}
                  validated={validated}
                  editDetail={editDetail}
                  setEditDetail={setEditDetail}
                  setEditSecStep={setEditSecStep}
                  basicDetails={basicDetails}
                  setBasicDetails={setBasicDetails}
                  phoneCode={phoneCode}
                  setPhoneCode={setPhoneCode}
                  email={email}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  password={password}
                  setGender={setGender}
                  gender={gender}
                  legal={legal}
                  setLegal={setLegal}
                  agree={agree}
                  setAgree={setAgree}
                  relationStatus={relationStatus}
                  setRelationStatus={setRelationStatus}
                  isVerifyModal={isVerifyModal}
                  setIsVerifyModal={setIsVerifyModal}
                  otp={otp}
                  setOtp={setOtp}
                  countDown={countDown}
                  setCountDown={setCountDown}
                  handlePhoneVerify={handlePhoneVerify}
                  handleForm={handleForm}
                  error={error}
                  formProgress={formProgress}
                  setFormProgress={setFormProgress}
                />
                <div style={{ height: 20 }} />

                {(editDetail && steps == 2) || steps == 3 ? (
                  <div
                    className="form-section-title"
                    style={{
                      backgroundColor: editSecStep ? "#fff" : "#ffffff57",
                    }}
                    onClick={() => {
                      setEditDetail(false);
                      setEditSecStep(!editSecStep);
                    }}
                  >
                    <span>
                      2.{" "}
                      {talent == "Client" ||
                      talent === "client" ||
                      talent == "Partners"
                        ? "Company Info"
                        : talent == "Photographer"
                        ? "Photographer Details"
                        : talent == "Model Kid"
                        ? "Model Kid Details"
                        : talent == "Actor"
                        ? "Actor Details"
                        : talent == "Actor Kid"
                        ? "Actor Kid Details"
                        : "Model Details"}
                    </span>
                    <p>Edit</p>
                  </div>
                ) : null}

                {editDetail ||
                (editDetail && steps == 3) ? null : (editSecStep &&
                    steps == 3) ||
                  steps == 2 ||
                  steps == 0 ? (
                  <Form noValidate validated={validated} onSubmit={handleForm}>
                    <div class="reg_application">
                      <OtherDetails
                        talent={talent}
                        setClientDetails={setClientDetails}
                        clientDetails={clientDetails}
                        setBasicDetails={setBasicDetails}
                        basicDetails={basicDetails}
                        address={address}
                        setAddress={setAddress}
                        editSecStep={editSecStep}
                        country={country}
                        setCountry={setCountry}
                        getAllCountryName={getAllCityName}
                        setState={setState}
                        state={state}
                        city={city}
                        setCity={setCity}
                        cityOptions={cityOptions}
                        stateOptions={stateOptions}
                        countryOptions={countryOptions}
                        setCityOptions={setCityOptions}
                        error={error}
                      />

                      <PersonalDetail
                        expertise={expertise}
                        setExpertise={setExpertise}
                        talent={talent}
                        language={language}
                        setLanguage={setLanguage}
                        englishLevel={englishLevel}
                        setEnglishLevel={setEnglishLevel}
                        bioDetails={bioDetails}
                        setBioDetails={setBioDetails}
                        height={height}
                        setHeight={setHeight}
                        weight={weight}
                        setWeight={setWeight}
                        hairColor={hairColor}
                        setHairColor={setHairColor}
                        ethnicity={ethnicity}
                        setEthnicity={setEthnicity}
                        eyeColor={eyeColor}
                        setEyeColor={setEyeColor}
                        experience={experience}
                        setExperience={setExperience}
                        mySkill={mySkill}
                        setMySkill={setMySkill}
                        customSkills={customSkills}
                        setCustomSkills={setCustomSkills}
                        tattooLoc={tattooLoc}
                        setTattoLoc={setTattoLoc}
                        memberOf={memberOf}
                        setMemberOf={setMemberOf}
                        educationTraining={educationTraining}
                        setEducationTraining={setEducationTraining}
                        theaterExp={theaterExp}
                        setTheaterExp={setTheaterExp}
                        awards={awards}
                        setAwards={setAwards}
                        commercialExp={commercialExp}
                        setCommercialExp={setCommercialExp}
                        basicDetails={basicDetails}
                        shirtSize={shirtSize}
                        setShirtSize={setShirtSize}
                        shirtSizeC={shirtSizeC}
                        setShirtSizeC={setShirtSizeC}
                        shirtSizeF={shirtSizeF}
                        setShirtSizeF={setShirtSizeF}
                        setBraCup={setBraCup}
                        braCup={braCup}
                        setBraSize={setBraSize}
                        braSize={braSize}
                        pantSize={pantSize}
                        pantSizeL={pantSizeL}
                        pantSizeW={pantSizeW}
                        pantSizeWC={pantSizeWC}
                        setPantSize={setPantSize}
                        setPantSizeL={setPantSizeL}
                        setPantSizeW={setPantSizeW}
                        setPantSizeWC={setPantSizeWC}
                        shoeSize={shoeSize}
                        shoeSizeC={shoeSizeC}
                        setShoeSize={setShoeSize}
                        setShoeSizeC={setShoeSizeC}
                        jacket={jacket}
                        setJacket={setJacket}
                        neckSize={neckSize}
                        setNeckSize={setNeckSize}
                        shirtSleeve={shirtSleeve}
                        setShirtSleeve={setShirtSleeve}
                        dressShirt={dressShirt}
                        setDressShirt={setDressShirt}
                        dressSize={dressSize}
                        setDressSize={setDressSize}
                        dressSizeC={dressSizeC}
                        setDressSizeC={setDressSizeC}
                        setBasicDetails={setBasicDetails}
                        error={error}
                        gender={gender}
                        chestSize={chestSize}
                        setChestSize={setChestSize}
                        shoeSizeF={shoeSizeF}
                        setShoeSizeF={setShoeSizeF}
                      />

                      <ActorAudition
                        talent={talent}
                        auditions={auditions}
                        setAuditions={setAuditions}
                        childrenCount={childrenCount}
                        setChildrenCount={setChildrenCount}
                      />

                      <SocialMediaHandle
                        socialLinks={socialLinks}
                        setSocialLinks={setSocialLinks}
                        talent={talent}
                        setFacebookFollowers={setFacebookFollowers}
                        facebookFollowers={facebookFollowers}
                        setVideoLink={setVideoLink}
                        videoLink={videoLink}
                        setInstagramFollowers={setInstagramFollowers}
                        instagramFollowers={instagramFollowers}
                        setTiktokFollowers={setTiktokFollowers}
                        tiktokFollowers={tiktokFollowers}
                        vimeoFollowers={vimeoFollowers}
                        setVimeoFollowers={setVimeoFollowers}
                        twitterFollowers={twitterFollowers}
                        setTwitterFollowers={setTwitterFollowers}
                        youtubeFollowers={youtubeFollowers}
                        setYoutubeFollowers={setYoutubeFollowers}
                        reelLink={reelLink}
                        setReelLink={setReelLink}
                      />
                      <Button
                        variant=" wt-btn"
                        class="wt-btn hide_org"
                        type="submit"
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                ) : null}
                <div style={{ height: 20 }} />

                <UserImages
                  handleChooseProfile={handleChooseProfile}
                  selectedFile={selectedFile}
                  page="register"
                  setSelectedFile={setSelectedFile}
                  cropped={cropped}
                  setCropped={setCropped}
                  uploadProfilePhoto={uploadProfilePhoto}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  removeGalleryImage={removeGalleryImage}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  galleryCropper={galleryCropper}
                  setGalleryCropper={setGalleryCropper}
                  images={images}
                  setImages={setImages}
                  handleChooseGallery={handleChooseGallery}
                  talent={talent}
                  handleGallerySubmit={handleGallerySubmit}
                  setAngryData={setAngryData}
                  AngryData={AngryData}
                  uploadWaistphoto={uploadWaistphoto}
                  angry={angry}
                  setangry={setangry}
                  happy={happy}
                  setHappy={setHappy}
                  happyData={happyData}
                  setHappyData={setHappyData}
                  shoulderDownImage={shoulderDownImage}
                  setShoulderDownImage={setShoulderDownImage}
                  shoulderUpImage={shoulderUpImage}
                  setShoulderUpImage={setShoulderUpImage}
                  shoulderDownImageData={shoulderDownImageData}
                  shoulderUpImageData={shoulderUpImageData}
                  sad={sad}
                  sadData={sadData}
                  setSad={setSad}
                  setSadData={setSadData}
                  waistImage={waistImage}
                  waistImageData={waistImageData}
                  setWaistImageData={setWaistImageData}
                  setWaistImage={setWaistImage}
                  steps={steps}
                  handleChooseImages={handleChooseImages}
                  setShoulderDownImageData={setShoulderDownImageData}
                  setShoulderUpImageData={setShoulderUpImageData}
                  setImageType={setImageType}
                  imageType={imageType}
                  setIsImageInfoModal={setIsImageInfoModal}
                  isImageInfoModal={isImageInfoModal}
                  formProgress={formProgress}
                  setFormProgress={setFormProgress}
                />
                {steps === 1 || steps === 2 || steps == 0 ? undefined : (
                  <Button
                    variant=" wt-btn"
                    class="wt-btn hide_org"
                    onClick={() => handleNext()}
                  >
                    Submit
                  </Button>
                )}
              </>
            )}
            <div style={{ height: 50 }} />
          </div>
        </div>

        <div style={{ height: 50 }} />
      </Container>
      <Footer />
    </>
  );
};
