import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, Footer, PlansCard, BlogCard, Loader } from "../../Components";
import Carousel from "react-bootstrap/Carousel";
import PartnerLogos from "../../Assets/Images/partnerLogo.png";
import { Colors, Images } from "../../Constants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { JobCard, ReviewCard } from "./Home";
import {
  getBlogReviewsList,
  getJobsList,
  getKidsList,
  getModelsList,
  sendNewsLetter,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getPackages, getUserDetail } from "../../Redux/Services/AuthServices";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";
import { routeName } from "../../Utility";
import { Signup } from "../Auth/Signup";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import { Login } from "../../Components/Popups";

export const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const loginParam = queryParams?.get("login");
  const [packagesList, setPackagesList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [tab, setTab] = useState(1);
  const [blogPage, setBlogPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewMoreLoad, setViewMoreLoad] = useState(false);
  const [searchValue, setSearchValue] = useState("Model");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);

    if (userID) {
      let body = {
        user_id: userID ? JSON?.parse(userID) : "",
      };
      let res = await dispatch(getUserDetail(body));

      if (res?.status == 200) {
        storeData(
          storageKey.USER_DATA,
          JSON.stringify(res?.results?.user_data)
        );
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
      }
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => {
      console.log(currentSlide, "lcurenttt");
      // Check if the last slide is reached
      if (currentSlide === 16) {
        // Navigate to another screen when the last slide is reached
        // Replace the following line with your navigation logic
        console.log("Last slide reached. Navigating to another screen.");
        navigate(routeName.SEARCH_TALENT);
      }
    },
  };
  var sculpReview = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  var jobList = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    setTab(1);
    getAllModelsList();
    getAllKidsList();
    getAllJobsList();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllPackages(1);
    handleBlogList();
    handleReviewsList();
  }, []);

  useEffect(() => {
    handleLoginModal();
  }, []);

  const handleLoginModal = async () => {
    const userId = await getData(storageKey?.USER_ID);
    if (loginParam && !userId) {
      setLoginModal(true);
    }
  };

  const getAllPackages = async (type) => {
    // setLoading(true);
    let res = await dispatch(getPackages());
    if (res?.status == 200) {
      if (type == 1) {
        let data = res?.results?.filter(
          (item) =>
            item?.wt_duration_type == "monthly" ||
            item?.wt_duration_type == "month"
        );
        setPackagesList(data);
        setLoading(false);
      } else if (type == 2) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "yearly"
        );
        setPackagesList(data);
        setLoading(false);
      }
      setTab(type);
    }
  };
  const handleBlogList = async () => {
    setLoading(true);
    var body = {
      post_type: "blogs",
      page_number: "1",
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(res?.results?.post);
    }
  };

  const getMoreBlogList = async () => {
    setViewMoreLoad(true);
    var body = {
      post_type: "blogs",
      page_number: blogPage + 1,
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(blogList?.concat(res?.results?.post));
      setBlogPage(blogPage + 1);
      setViewMoreLoad(false);
    }
  };

  const handleReviewsList = async () => {
    setLoading(true);
    var body = {
      post_type: "reviews",
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setReviewsList(res?.results?.post);
    }
  };

  const getAllModelsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getModelsList(body));
    if (res?.status == 200) {
      let arr = res?.results?.user_list?.filter(
        (item) => item?.post_meta_details?.subscription_pro_id != 107
      );
      setModelsList(arr);
      setLoading(false);
    }
  };
  const getAllKidsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getKidsList(body));
    if (res?.status == 200) {
      setKidsList(res?.results?.user_list);
      setLoading(false);
    }
  };

  const getAllJobsList = async () => {
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: "20",
    };
    let res = await dispatch(getJobsList(body));
    if (res?.status == 200) {
      setJobsList(res?.results?.list);
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Landing Page</title>
      </Helmet>
      <Header />
      <section>
        <div className="homeCarouselLanding">
          <img
            src="https://booksculp.com/LIVE/uploads/0r4a2308178.jpg"
            alt="Fashion model"
            className="topBannerLanding"
          />
          <div className="captionBar">
            <div className="logoLanding">
              <img src="https://booksculp.com//static/media/logo.99c08682a71a6b4d58a2.png" />
            </div>
            <h2>Looking to get noticed?</h2>
            <p>
              Be seen by casting companies, modeling agencies, photographers,
              clients and more.
            </p>
            <a onClick={() => navigate(routeName?.SEARCH_JOBS)} class="btn ">
              See jobs
            </a>
          </div>
        </div>
      </section>
      <section>
        <div className="PopularCasting">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="pCastingImage">
                  <img
                    src={"https://booksculp.com/LIVE/uploads/0-copy345616.jpg"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <ul>
                  {/* <li>✔️ Add a GIF of our images</li>
                  <li>✔️ A different bullet to replace the check marks</li> */}
                  <li>✔️ New jobs added constantly</li>
                  <li>✔️ For all experience levels</li>
                  <li>
                    ✔️ Apply for jobs across the nation and in your local area
                  </li>
                  {/* <li>
                    ✔️ Keep the "Sign Up Now" button with the same link to the
                    sign-up page
                  </li>
                  <li>
                    ✔️ Add some clients to the grey area, such as Dillards, Palm
                    Beach Tan, etc.
                  </li>
                  <li>✔️ Replace "Popular Castings" with "NEW Jobs"</li> */}
                </ul>
              </div>
              <div className="col-12 text-center">
                <a class="btn" onClick={() => setSignupModal(true)}>
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="partnerLogos">
          <div className="row">
            <div className="col-12">
              <div className="partLogos">
                <img src={Images.Bass_Pro_Shops_Logo} />
                <img src={Images.Boot_Barn_Logo} />
                <img src={Images.Dillard_Logo} />
                <img src={Images.Eileen_Fisher_Logo} />
                <img src={Images.Hilton_logo} />
                <img src={Images.Michael_Kors_brand_logo} />
                <img src={Images.Palm_Beach_Tan_Logo} />
                <img src={Images.Sam_Club_Logo} />
                <img src={Images.Sodexo_Logo} />
                <img src={Images.Sport_Clips_Logo} />
                <img src={Images.Walmart_Logo} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="popularCasting">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="wt-sectionhead wt-textcenter">
                <div class="wt-sectiontitle">
                  <h2>NEW Jobs</h2>
                </div>
                <div class="wt-description">
                  <p>
                    Currently listing jobs from clients and talent agencies
                    across the nation
                  </p>
                </div>
              </div>

              <Container>
                <div className="fullSlide">
                  <Slider {...jobList}>
                    {jobsList?.map((item) => {
                      return (
                        <>
                          <JobCard
                            cardData={item}
                            setLoginModalVisible={setLoginModalVisible}
                          />
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </section>

      <section className="howItWorks">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="wt-sectionhead wt-textcenter">
                <div class="wt-sectiontitle">
                  <h2>How does it works?</h2>
                </div>
                <div class="wt-description">
                  <p>
                    Your fast track into the modeling industry in 3 easy steps
                  </p>
                </div>
              </div>

              <Container>
                <div className="row">
                  <div className="col-md-4">
                    <div className="cardArea">
                      <img src={Images.create_profile} />
                      <h2>1. Create your profile</h2>
                      <p>
                        Add your best photos, details and instagram to showcase
                        yourself and get scouted by brands, photographer and
                        agencies
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="cardArea">
                      <img src={Images.apply_jobs} />
                      <h2>2. Apply to jobs</h2>
                      <p>
                        Add your best photos, details and instagram to showcase
                        yourself and get scouted by brands, photographer and
                        agencies
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="cardArea">
                      <img src={Images.connect_brand} />
                      <h2>3. Connect with brands</h2>
                      <p>
                        Add your best photos, details and instagram to showcase
                        yourself and get scouted by brands, photographer and
                        agencies
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </section>

      <section className="bookBanner newsletter PopularCasting">
        <div className="bannerCaption container">
          <div className="subForm">
            <h2>Real Talent, Real Client, Real Jobs</h2>
            <a class="btn" onClick={() => setSignupModal(true)}>
              Sign Up Now
            </a>
          </div>
        </div>
      </section>

      <section className="sculpReviw">
        <div className="wt-sectionhead wt-textcenter">
          <div className="wt-sectiontitle">
            <h2>What our users say</h2>
          </div>
          {/* <div className="wt-description">
            <p>Find the Job for your needs</p>
          </div> */}
        </div>
        <Container>
          <div className="fullSlide">
            <Slider {...sculpReview}>
              {reviewsList?.map((item) => {
                return (
                  <>
                    <ReviewCard cardData={item} />
                  </>
                );
              })}
            </Slider>
          </div>
        </Container>
      </section>

      <div className="PopularCasting clientsSays">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h2>What Model Say? </h2>
              <p>
                Aspiring and professional models share their experiences on why
                they trust our platform and how it has played a vital role in
                launching, expanding, and shaping their modeling careers. From
                securing their first gigs to building strong industry
                connections, our platform provides the opportunities, exposure,
                and support they need to succeed in the competitive world of
                modeling.
              </p>
              <a class="btn" onClick={() => setSignupModal(true)}>
                Sign Up Now
              </a>
            </div>
            <div className="col-md-5">
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/nvOHwYCE93g"
                title="BookSculp"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {loginModalVisible && (
        <LoginAlert
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
      <Footer />
      <Modal
        show={signupModal}
        onHide={() => setSignupModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Be our new member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup hideLayouts={true} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSignupModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const JobCardLanding = (props) => {
  const { cardData, setLoginModalVisible } = props;
  const navigate = useNavigate();
  const { partnerPermissions, loggedInUser } = useSelector(
    (state) => state.authReducer
  );
  return (
    <>
      <div>
        <div className="wt_project_list">
          <h3>
            <a>{cardData?.profile?.post_title}</a>
          </h3>
          <img
            src={
              cardData?.post_meta_details?.post_job_banner_image ||
              Images?.jobHiring
            }
          />
          <a
            onClick={() => {
              if (
                partnerPermissions?.user_id ||
                loggedInUser?.user_data?.user_id
              ) {
                const queryParams = new URLSearchParams({
                  id: cardData?.profile?.ID,
                });
                navigate(`${routeName.JOB_DETAILS}?${queryParams.toString()}`);
              } else {
                setLoginModalVisible(true);
              }
            }}
            className="btn"
          >
            Apply Now
          </a>
        </div>
      </div>
    </>
  );
};
