import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export const Alert = (props) => {
  const { title, text, buttonText, onPress, onClose } = props;

  return (
    <>
      <Modal show={true} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose()}>
            Close
          </Button>
          <Button variant="primary" onClick={onPress}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
