import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css"; // Optional: for styling
import { Button, Modal } from "react-bootstrap";
import {CookiePreferencesModal} from "../Popups"

export const CookieConsent = (props) => {
  const { show, setShow } = props;
  const [showPreferences, setShowPreferences] = useState(false); // For preferences modal

  const handleAccept = () => {
    setCookie("modalShown", "accepted",365 ); // 1 year expiry
    setShow(false);
  };

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? JSON.parse(match[2]) : null;
  };
  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${JSON.stringify(value)};expires=${expires.toUTCString()};path=/;SameSite=Lax`;
  };
  const handleDeny = () => {
    setCookie("modalShown", "denied", { path: "/", maxAge: 3600 * 24 * 365 });
    setShow(false);
  };

  const handlePreferencesSave = (preference) => {
    setCookie("modalShown", preference, {
      path: "/",
      maxAge: 3600 * 24 * 365,
    });
    setShowPreferences(false);
    setShow(false);
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#343a40",
            color: "#fff",
            padding: "1rem",
            textAlign: "center",
            zIndex: 1000,
          }}
        >
          {/* Cross Button */}
          <button
            onClick={handleAccept}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              color: "#fff",
              fontSize: "20px",
              cursor: "pointer",
            }}
            aria-label="Close"
          >
            &times;
          </button>

          <p>
          This website automatically stores cookies on your device when you visit, but you can adjust your cookie by clicking on Cookies preferances button bellow.
          </p>
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => setShowPreferences(true)}
            >
              Change Preferences
            </button>
          </div>
        </div>
      )}

      {/* Preferences Modal */}
      <CookiePreferencesModal
        showPreferences={showPreferences}
        setShowPreferences={setShowPreferences}
        handlePreferencesSave={handlePreferencesSave}
      />
    </>
  );
};
